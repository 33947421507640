import tc from './tc'
import { imgs } from '../assets/imgs/index'
const sc: typeof tc = {
  lang: 'sc',
  活动结束: '活动已经完结，多谢支持！',
  活动未开始: '活动即将开始，敬请期待！',
  我知道了: '我知道了',
  Header: {
    首页: '首页',
    's/ash，以CMHK中國移動': 's/ash，以CMHK中国移动香港的网络，为学生、香港年青人、大众提供优惠电话plan。',
    公告: '由于即时CHAT正进行系统提升，服务可能会受到影响，请阁下稍后再试或可致电12580与客服联络跟进。不便之处，敬请原谅。',
    '5G月费计划': '5G月费计划',
    手游必備礼遇: '手游必备礼遇',
    '认识s/ash': '认识s/ash',
    '咩系s/ash': '咩系s/ash',
    品牌介绍: '品牌介绍',
    媒体报导: '媒体报导',
    网络实测: '网络实测',
    最新着数: '最新活动',
    渣打信用卡客户专享礼遇: '渣打信用卡客户尊享礼遇 ',
    免费拎100GB: '免费拎100GB',
    上台Tips: '上台Tips',
    转台教学: '转台教学',
    帮助中心: '帮助中心',
    繁: '繁',
    简: '简',
    登记: '登记',
    's/asher大使': 's/asher大使',
    joox_jp: 's/ash x JOOX 乐迷专享优惠'
  },
  Footer: {
    chat: imgs['footer/chat-sc.png'],
    即時chat: '实时chat带你了解更多s/ash电话plan、SIM卡、5G计划优惠',
    '认识s/ash': '认识s/ash',
    媒体报导: '媒体报导',
    订单查询: '订单查询',
    帮助中心: '帮助中心',
    商业合作: '商业合作',
    条款及细则: '条款及细则',
    版权: '© 2023 s/ash版权所有',
    联络我们: '联络我们'
  },
  Index: {
    // banner
    banner备注:
      '*当每月使用的本地数据用量超过30GB后，本地数据服务仍可继续, 而传输速度最高达1Mbps及不适用于网络分享(tethering)及点对点(P2P)功能(包括Bit-Torrent等)。 • 上述服务及优惠均受条款及细则约束。',
    '全民免月费最多5个月！': '青春应援优惠 月费低至$98!',
    马上登记: '马上登记',
    '12个月短约': '个月合约期灵活选',
    学生: '学生',
    免最多: '及18-29岁',
    年青人: '年青人',
    个月月费: '限定',
    免: '携号转台免',
    行政费: '行政费',
    '/ 属于年青人的': '/ 属于年青人的',
    '5G通讯服务': '5G通讯服务',
    全方位应援你日常通讯与生活: '全方位应援你日常通讯与生活',
    立即登记: '立即登记',
    // 优势
    '无限数据放心用(1)': '<h3><span class="blue">无限流量</span>放心用</h3>',
    生活可能有好多限制: '生活可能有好多限制\n但上网无需再有限制',
    旅行漫游想走就走: '<h3><span class="blue">旅行 漫游 想走就走</span></h3>',
    灵活加购漫游数据: '灵活加购漫游数据\n一张卡陪你周游列国',
    年青人出機必備: '<h3><span class="blue">年青人</span>出机必备</h3>',
    享5G手机奖赏热门品牌5G手机出机无忧: '享5G手机奖赏\n热门品牌5G手机出机无忧',
    // 三个计划开始
    '还在选择学生Plan或5G高价Plan?': '还在用高价5G Plan? 年青人有自己的5G世一Plan！',
    's/ash为你提供简单3个计划':
      's/ash为你提供<span class="blue">3款香港本地计划及1款一卡两地计划</span><br/>用量高、还划算！新上台更享系列精选权益，应援你青春！ <br/> 选一款最适合你的上台 Plan',
    全城最划算: '全城最划算',
    起: '',
    '起/月': '起/月',
    '/月': '/月',
    原价: '原价:',
    其后1Mbps高速任用: '+其后本地1Mbps高速任用',
    '送2GB中国内地、澳门漫游数据(+)': '送3GB中国内地及澳门数据',
    '18-29岁免5个月月费': '送15个常用App本地数据任用权益（价值$38/月）',
    '(折后$98)': '(折后$98)',
    '攜號轉台免$18/月行政費': '携号转台免$18/月行政费',
    '(折后$126)': '(折后$126)',
    个月合约期: '个月合约期',
    了解更多: '了解更多',
    CP值最高: 'CP值最高',
    其后5Mbps高速任用: '+其後本地5Mbps高速任用',
    '送4GB中国内地、澳门漫游数据(+)': ' 送4GB中国内地及澳门数据',
    '(折后$145)': '(折后$145)',
    '(折后$186)': '(折后$186)',
    高用量之选: '高用量之选',
    '送6GB中国内地、澳门漫游数据(+)': '送6GB中国内地及澳门数据',
    ' / 送15个常用App数据任用 (价值$38/月)': ' 送15个常用App数据任用 (价值$38/月)',
    '(折后$174)': '(折后$174)',
    '(折后$224)': '(折后$224)',
    '享$1,200 5G手機獎賞（24個月合約': '享$1,200 5G手机奖赏（24个月合约）',
    '本地服務計劃 （學生及18-29歲年青人限定）': '本地服务计划\n（学生及18-29岁年青人限定）',
    '一咭兩地服務計劃（18-29歲年青人限定）': '一卡两地服务计划\n（18-29岁年青人限定）',
    北上必選: '北上必选',
    '中國内地及香港共用數據 + 其後本地高達1Mbps': '中国内地及香港共用数据',
    '+ 其後本地高達1Mbps': '+ 其后本地高达1Mbps',
    含200分鐘中國内地通話分鐘: '含200分钟中国内地通话分钟',
    '$9/月加購15個常用App本地數據任用權益（價值$38/月': ' $9/月加购15个常用App本地数据任用权益（价值$38/月）',
    '送「中國內地號碼服務」或「關聯家鄉號」（二選一優惠）': '送「中国内地号码服务」或「关联家乡号」（二选一优惠）',
    // 全方位考虑你的需要
    '／全心考虑你的需要': '<h2><span class="blue">／</span>全心考虑<span class="blue">你的需要</span></h2>',
    优惠月费: '优惠月费',
    极速网络: '极速网络',
    贴心服务: '贴心服务',
    '／考虑你的预算': '／考虑你的预算',
    '／考虑你的通讯需要': '／考虑你的通讯需要',
    '／考虑你的心情': '／考虑你的心情',
    为你提供比其他网络商: '为你提供比其他网络商',
    更抵的价钱: '<span class="red">更抵</span>的价钱',
    更快的网络: '<span class="red">更快</span>的网络',
    更多的数据: '<span class="red">更多</span>的数据',
    '5G月费计划低至': '5G月费计划低至',
    明白你不想再Load了个寂寞或者最怕月尾用光数据: '明白你不想再Load了个寂寞或者最怕月尾用光数据 ',
    只为你提供: '只为你提供',
    '【最优秀的5G网络】': '【最优秀的5G网络】',
    为你提供: '为你提供',
    '【24小时线上客服支撑】': '【<span class="blue size20">24小时</span>线上客服支撑】',
    用心关注你每一个问题: '用心关注你每一个问题',
    尽快解答你的疑难: '<span class="red">尽快解答</span>你的疑难',
    备注: '备注',
    备注内容: `
    上述服务计划及优惠均受条款及细则约束，详情请参考<a href='/pdf/slash 5G本地服務計劃及5G一咭兩地服務計劃之條款及細則.pdf' >s/ash 5G服务计划及5G 一咭两地服务计划之条款与细则</a>。
    `
  },
  '100GB_offer': {
    立即领券: '立即领券',
    '【限时】上台额外送': '【限时】上台额外送',
    本地数据: '本地流量',
    '成功上台s/ash，额外送多100GB本地数据': '成功上台s/ash，额外送多100GB本地流量',
    '为什么上台s/ash?': '<h2><span class="blue">为什么</span>选择s/ash?</h2>',
    '扫QR Code即刻抢': '扫瞄二维码立即抢',
    条款及细则: '条款及细则',
    's/ash为你提供3个计划': '<h2>s/ash为你提供<span class="blue">3</span>个计划</h2>',
    用量多: '用量多，价格值！新上台限时免你3-5个月费*！\n挑选一款最合适你的Plan',
    活动条款详情: `
      有关「送100GB本地数据券」活动，条款及细则请参阅<a href='/pdf/「100GB本地數據券」優惠活動條款及細則.pdf' >「100GB本地數據券」優惠活動條款及細則</a>
      <br/><sup>*</sup>有关全民免月费活动的条款, 请参阅 <a href='/pdf/全民免月費 活動細則.pdf' >全民免月费活动细则</a>
      <br/><sup>+</sup>送中国内地及澳门漫游数据优惠只适用于选用指定s/ash服务计划并签约12个月或以上之指定客户 (「合资格客户」)。合资格客户可于合约期内享每月2GB/4GB/6GB中国内地/澳门漫游数据。受条款及细则约束
    `
  },
  '5Gservice_plan': {
    '5G世一Plan': '5G世一Plan ',
    全民: '全民',
    免月费: '免月费',
    最多: '最多',
    个月: '个月',
    个月短约: '个月短约',
    免: '免',
    $18: '$18',
    行政费: '行政费',
    无限: '无限',
    数据: '数据',
    服务包括: '服务包括',
    无限本地通话: '无限本地通话',
    '500个网内短讯': '500个网内短讯',
    '10个国际短讯': '10个国际短讯',
    留言信箱: '留言信箱',
    来电转驳: '来电转驳',
    来电接待: '来电接待',
    电话会议: '电话会议',
    '于海外拒接来电/防止打出': '于海外拒接来电/防止打出',
    '还在选择学生Plan或5G高价Plan?': '还在选择学生套餐或高价5G套餐? ',
    提供你简单3个计划: 's/ash为你提供3个简单手机套餐\n价低量多！新上台限时免你3-5个月月费*！\n挑选一款最适合你的套餐',
    '3分钟搞掂': '3分钟搞掂',
    轻松上台: '轻松上台',
    完旧约180日前起: '完旧约180日前起',
    就可登记转台: '就可登记转台',
    专享打机礼遇: '专享打机礼遇',
    CMHK优质网络: 'CMHK优质网络',
    '扫QR Code': '扫QR Code',
    立即登记: '立即登记',
    备注内容: `
      <sup>*</sup>有关全民免月费活动的条款, 请参阅<a href="/pdf/全民免月費 活動細則.pdf" download >全民免月费活动细则</a>
      <br/><sup>+</sup>送中国内地及澳门漫游数据优惠只适用于选用指定s/ash服务计划并签约12个月或以上之指定客户 (「合资格客户」)。合资格客户可于合约期内享每月2GB/4GB/6GB 中国内地/澳门漫游数据。受条款及细则约束
    `
  },
  Gaming_Essentials: {
    '/打机必备s/ash做你最强后盾': '/打机必备 s/ash做你最强后盾',
    '*人气手游': '人气手游',
    免数据任玩: '免数据任玩 + 氪金享10%回赠<sup>*</sup>\n5G网速Chill住玩',
    立即启动流动电讯商账单付款服务账单付款服务: '立即启动流动电讯商账单付款服务',
    tips: '*只适用指定手机游戏, 其中课金享10%回赠只适用于Google Play手机平台并用流动电讯商账单付款服务，优惠受条款及细则约束',
    '/多款大热手游都适用': '/多款大热手游都适用 ',
    '随时更新 有冇一款你玩紧?': '随时更新 有冇一款你玩紧?',
    下载: '下载',
    展开: '展开',
    收起: '收起',
    更多: '更多',
    游戏列表: [
      {
        name: '蛋仔派对',
        privilege: [1, 2],
        key: 'dzpd',
        download: '50万+次下载',
        link_Ios: 'https://apps.apple.com/hk/app/%E8%9B%8B%E4%BB%94%E6%B4%BE%E5%B0%8D/id1673675970',
        link_Android: 'https://play.google.com/store/apps/details?id=com.netease.eggypartyhmt',
        img: 'Gaming_Essentials/dzpd.png',
        tag: ['#欢乐派对', '#休闲竞技', '#多人合作'],
        detail: `-好友随手抓 一起来开趴-
        派对时间到！在这里，你可以找到许多可爱Q萌的蛋仔，一同登上巨型摩天轮俯瞰蛋仔岛、搭乘喷射小飞机空中遨游，更有弹力蹦床、钢琴地毯等超多游乐设施，一起来开趴吧！

        -滚进圆宇宙 潮游新节奏-
        欢迎来到蛋仔圆宇宙！这是一个潮玩背后的世界，你可以邂逅风格各异的潮蛋伙伴，动物风、奇幻风、甜美风……海量造型随心搭配，开启潮游新节奏！

        -蛋仔岛遨游 寻找好碰友-
        和好碰友们一起参加多人派对，碰撞弹跳，欢乐贴贴！在蛋仔岛遨游要当心各种赛道机关，也许也要当心其他蛋仔！这个派对有点上头，你可以嗨上一整天！

        -DIY地图 流行我作主-
        玩不够吗？来蛋仔乐园发现新世界——这里有海量玩家自制的UGC地图！你也可以DIY设计属于你的专属地图，向所有蛋仔秀出自己天马行空的创意！`
      },
      {
        name: '明日方舟',
        privilege: [2],
        key: 'mrfz',
        download: '50万+次下载',
        link_Ios: 'https://apps.apple.com/hk/app/%E6%98%8E%E6%97%A5%E6%96%B9%E8%88%9F/id1490985322',
        link_Android: 'https://play.google.com/store/apps/details?id=tw.txwy.and.arknights',
        img: 'Gaming_Essentials/mrfz.png',
        tag: ['#新型态战术攻防RPG', '#丰富策略搭配', '#精细人设', '#休闲疗愈'],
        detail: `大地遭受起因不明的天灾肆虐，经由天灾席卷过的土地上出现了大量的神秘矿物——「源石」。随着科技不断进步，源石之内所蕴含的能量在投入工业后使得文明顺利迈入现代，与此同时，源石本身也催生出「感染者」的存在。 「感染者」是身俱力量与不幸的存在，如今他们之中有一部分的人，妄图与源石整合为一，为大地带来新的秩序。这场战火阴谋将是我们对抗天灾的全新阻碍。你将作为罗德岛的一员，与罗德岛公开领导人阿米娅一同，雇佣人员频繁进入遭受天灾影响后的危险地区，救助受难人群，处理矿石争端，以及对抗整合运动——「罗德岛」的战术头脑，你准备好了吗？`
      },
      {
        name: '明日之后',
        privilege: [1],
        key: 'mrzh',
        download: '1000万+次下载',
        link_Ios:
          'https://apps.apple.com/hk/app/%E6%98%8E%E6%97%A5%E4%B9%8B%E5%BE%8C-%E6%9C%AB%E4%B8%96%E7%94%9F%E5%AD%98%E6%89%8B%E9%81%8A/id1441752845',
        link_Android: 'https://play.google.com/store/apps/details?id=com.netease.mrzhna',
        img: 'Gaming_Essentials/mrzh.png',
        tag: ['#开放世界', '#丧尸射击', '#2亿玩家集结'],
        detail: `成为末日的勇士，与同伴一起讨伐野兽巨人和盔甲巨人吧！
        - 广阔开放世界疆界拓展 -
        末日世界边界再次拓展，幸存者们驾船出航，探索变异海洋。结晶、迷雾、污秽、烈火、漩涡……神秘危险的五大海域等你征服。
        从雪山到海滩、从森林到荒漠、从沼泽到城市……广阔的末日大世界，危机遍布，但也蕴藏无尽可能。在这里，你需要收集资源、构筑基建，抵御丧尸侵袭，建造属于自己的避难所。

        - 保持希望和求生的意志 -
        末世降临，丧尸横行。原本的社会秩序骤然崩塌，熟悉的世界变得陌生。无处不在的丧尸觊觎着人类的驻地，恶劣气候与物资匮乏令人寸步难行。末日海洋中更潜伏着危险的新型感染体和巨型感染生物，随时能将孤舟倾覆……
        危险无处不在，请务必保持冷静，用尽一切办法活下去！

        - 寻找同伴携手末日求生 -
        在末日世界探索时，你会遇到更多幸存者。
        一个人的旅途听够了丧尸嘶吼、夜风呼啸。或许可以尝试打开心扉，与伙伴分享食物，篝火夜话，一砖一瓦，共同打造安宁的栖息之地。

        - 体验半尸视角末日生存 -
        在神秘组织「破晓」的说明下，被丧尸咬伤的人类仍有生机——化为半人半丧尸的「混尸人」活下去，抛弃人类的身份、容貌、能力，从此彻底改变。

        这听上去很冒险，但倘若真的陷入生死边缘，你将如何抉择？`
      },
      {
        name: '哈利波特：魔法觉醒',
        privilege: [1, 2],
        key: 'hlbt',
        download: '50万+次下载',
        link_Ios: 'https://apps.apple.com/hk/app/%E5%93%88%E5%88%A9%E6%B3%A2%E7%89%B9-%E9%AD%94%E6%B3%95%E8%A6%BA%E9%86%92/id1484303602',
        link_Android: 'https://play.google.com/store/apps/details?id=com.netease.harrypotter.tw',
        img: 'Gaming_Essentials/hlbt.png',
        tag: ['#哈利波特', '#角色扮演'],
        detail: `《哈利波特》官方授权手游《哈利波特：魔法觉醒》，邀你重返霍格华兹，书写属于你的魔法世界新篇章！你将成为一名霍格华兹新生，实现期待已久的魔法梦想。在奇妙的魔法校园里，结识性格各异的巫师伙伴，学习咒语和魔法知识，体验异彩纷呈的巫师生活。还能与原著角色携手，在全新的冒险与奇遇中不断成长，开启属于你的巫师生涯！`
      },
      {
        name: '崩坏：星穹铁道',
        privilege: [2],
        key: 'bh',
        download: '1000万+次下载',
        link_Ios: 'https://apps.apple.com/hk/app/%E5%B4%A9%E5%A3%9E-%E6%98%9F%E7%A9%B9%E9%90%B5%E9%81%93/id1599719154',
        link_Android: 'https://play.google.com/store/apps/details?id=com.HoYoverse.hkrpgoversea',
        img: 'Gaming_Essentials/bh.png',
        tag: ['#回合制角色扮演', '#休闲冒险', '#精美动漫'],
        detail: `这片银河中有名为「星神」的存在，祂们造就现实，抹消星辰，在无数「世界」中留下祂们的痕迹。你——一名特殊的旅客，将偕同继承「开拓」意志的同伴们，乘坐星穹列车穿越银河，沿着某位「星神」曾经所行之途前进。你将由此探索新的文明、结识新的伙伴、在无数光怪陆离的「世界」之间展开新的冒险。所有你想知道的，都将在群星中找到解答。那么，准备好开始这段「开拓」之旅了吗？`
      },
      {
        name: '第五人格',
        privilege: [1, 2],
        key: 'dwrg',
        download: '100万+次下载',
        link_Ios: 'https://apps.apple.com/hk/app/%E7%AC%AC%E4%BA%94%E4%BA%BA%E6%A0%BC/id1370284166',
        link_Android: 'https://play.google.com/store/apps/details?id=com.netease.idv_tw.googleplay',
        img: 'Gaming_Essentials/dwrg.png',
        tag: ['#动作冒险', '#求生恐怖游戏'],
        detail: `《第五人格》是网易首款非对称性对抗竞技手游。荒诞哥德画风，悬疑烧脑剧情，刺激的1V4非对称的对抗玩法，都将给玩家带来全新的游戏体验。玩家将成为侦探奥尔菲斯，在收到一封神秘的委托信后，进入恶名昭著的庄园调查一件失踪案。在进行证据调查过程中，奥尔菲斯将采用演绎法，对案情进行回顾。在案情回顾时，玩家可以选择扮演监管者或求生者，展开激烈的对抗。而在调查的过程，无限接近事实时，却发现越来越不可思议的真相。`
      },
      {
        name: '哒哒特攻',
        privilege: [1, 2],
        key: 'ddtg',
        download: '1000万+次下载',
        link_Ios: 'https://apps.apple.com/hk/app/%E5%99%A0%E5%99%A0%E7%89%B9%E6%94%BB/id1528941310',
        link_Android: 'https://play.google.com/store/apps/details?id=com.dxx.firenow',
        img: 'Gaming_Essentials/ddtg.png',
        tag: ['#Roguelike游戏', '#角色扮演'],
        detail: `危险的丧尸正在攻击整个城市，城市已经陷入危机！被梦境试炼唤起的你，已经成为身负重任的战士！作为一名极具潜力的人类战士，你将与其他幸存者一起拿起武器，战胜邪恶的危险丧尸！敌人的力量依旧强大，稍不留神就会让自己陷入险境！在这种艰难时刻，想尽办法，生存下去吧！`
      },
      {
        name: '秘境传说：神木遗迹',
        privilege: [1, 2],
        key: 'mjcs',
        download: '50万+次下载',
        link_Ios: 'https://apps.apple.com/hk/app/%E7%A7%98%E5%A2%83%E5%82%B3%E8%AA%AA-%E7%A5%9E%E6%9C%A8%E9%81%BA%E8%B7%A1/id1557440708',
        link_Android: 'https://play.google.com/store/apps/details?id=com.slsm.sy.and',
        img: 'Gaming_Essentials/mjcs.png',
        tag: ['#角色扮演'],
        detail: `一片隐藏的秘境，一颗古老的神树，一处蕴藏精灵力量的遗迹，一本昭示毁灭的预言之书，一名勇敢无畏的少年，一场惊心动魄的冒险，一个扑朔迷离的真相……你是一个充满好奇心的部落少年，偶然间发现了父亲留下的笔记和信件。为了解开父亲失踪之谜，解决大陆崩坏的危机，你跟随父亲的脚步，成为了一名猎人。探索神木遗迹，拯救这片大陆的责任已经悄然落在了你的肩上……神木秘境的传说物语，由此开启！`
      },
      {
        name: '大侠客',
        privilege: [1, 2],
        key: 'dxk',
        download: '50万+次下载',
        link_Ios: 'https://apps.apple.com/hk/app/%E5%A4%A7%E4%BF%A0%E5%AE%A2-%E6%8B%B3%E5%AE%9A%E5%A4%A9%E4%B8%8B/id1468451508',
        link_Android: 'https://play.google.com/store/apps/details?id=com.rxsj.ssjj',
        img: 'Gaming_Essentials/dxk.png',
        tag: ['#角色扮演', '#MMORPG', '#风格化'],
        detail: `《大侠客》X《轩辕剑参外传：天之痕》联动6月1日正式开启！于小雪无意中通过穿梭时空的神器昆仑镜进入了大侠客的世界，即将开启一场奇妙的江湖冒险之旅......`
      },
      {
        name: '叫我官老爷',
        privilege: [1, 2],
        key: 'jwgly',
        download: '100万+次下载',
        link_Ios: 'https://apps.apple.com/hk/app/%E5%8F%AB%E6%88%91%E5%AE%98%E8%80%81%E7%88%BA/id1241253595',
        link_Android: 'https://play.google.com/store/apps/details?id=com.szckhd.jwgly.azfanti',
        img: 'Gaming_Essentials/jwgly.png',
        tag: ['#模拟', '#生活', '#风格化'],
        detail: `《叫我官老爷》是一款原创经营养成当官手游，以官爷为第一视觉生动呈现，升官发财、成家立业、家族联盟PK、门客培养、多人社交等精彩玩法。
        【红颜失宠 冷宫休妻】
        即使是国色天香的美人，失宠后也可以被打入冷宫，送到浣衣房。大人你是否会怜香惜玉？
        【奉旨当官 前途无量】`
      },
      {
        name: '崩坏3rd',
        privilege: [2],
        key: 'bh3',
        download: '100万+次下载',
        link_Ios: 'https://apps.apple.com/hk/app/%E5%B4%A9%E5%A3%9E3rd/id1233055283',
        link_Android: 'https://play.google.com/store/apps/details?id=com.miHoYo.bh3tw',
        img: 'Gaming_Essentials/bh3.png',
        tag: ['#风格化', '#1营收最高的项目'],
        detail: `《崩坏3》点燃ACG动作之魂!次世代的动作游戏出击!
        在不远的未来，神秘的灾害『崩坏』侵蚀了世界。
        被崩坏感染的人类变成没有思维、只懂得破坏的『死士』；被感染的动物变成残暴、只懂得攻击的『崩坏兽』。
        唯一能够对抗崩坏的只有天生拥有抗体的少女，她们身上会出现被称作『圣痕』的印记。
        玩家将扮演休伯利安号的舰长，指挥带领一头白发欧洲血统的琪亚娜、洗手作羹汤贤慧的芽衣、沉默的佣兵少女布洛妮娅、身材傲人的姬子、自带萌萌狐耳的八重樱、世界第一可爱的德丽莎、枪斗术嫡传的卡莲、认真起来不输给任何人的符华、盛放在幽夜的丽塔、有着明星梦的双子姊妹、里人格觉醒的双生希儿、天命最强战力的幽兰黛尔。携手对抗崩坏，一同为世界上所有的美好而战！`
      }
    ],
    '点Chill法？': '点Chill法？',
    条款及细则: '条款及细则',
    課金回贈: '10%课金回赠',
    免數據Chill住玩: '免数据Chill住玩',
    活动条款内容: `
      1. 合資格用戶
      1.1. 「打機 Chill 住玩」只適用於 s/ash 之客戶 (「合資格客戶」)， 合資格客戶能：
      (a) 透過指定 Android 作業系統及使用 s/ash 賬單付款服務於指定手機遊戲課金可
      以得到 10%的回贈（「課金回贈」）；及
      (b) 任用指定手機遊戲數據（「任用指定遊戲數據」）。
      2. 課金回贈之條款及細則
      2.1 課金回贈之合資格客戶必須符合以下條件：
      (a) 使用 Android 2.2 或以上作業系統並已安裝 Google Play 商店應用程式之智能手機，惟部分手機可能因手機品牌、型號、韌體版本不同而有所差異。有關手機內置設定的疑問，請聯絡有關手機製造商；及
      (b) 於選用 s/ash 計劃的客戶且使用 s/ash 賬單付款服務
      2.2 課金回贈不適用於 Apple 服務購買之應用程式、遊戲、書籍或電影等的賬項。
      2.3 課金回贈可供合資格客戶透過其 s/ash 賬戶支付於 Google Play 服務購買指定遊戲内消費。有關賬項將列入 s/ash 給予客戶的該月之月結單內，合資格客戶需要根據該月結單上所列出的金額付款；例如：客戶 3 月於遊戲内消費了 HKD 100，4 月收到 3 月之月結單的時候可以看到 Google Play 消費 HKD 100 以及 s/ash 所發放之 Google Play 消費回贈扣減 HKD10 兩個項目，合計支付 HKD 90。
      2.4 所有退款只可由應用程式開發商或 Google 審批及執行。s/ash 只提供本服務作一項付款方式。如客戶要求退款，須要直接聯絡有關開發商或 Google。所有退款要求均須按照Google 退 款 政 策 處 理<a href="https://support.google.com/googleplay/answer/2479637?hl=zh-HK#policy">https://support.google.com/googleplay/answer/2479637?hl=zh-HK#policy</a> 。 例如︰客戶退款後，該月的月結單上會將 Google Play Store 購買後產生的回贈金額用Google Play Store 回贈條目補回相應金額。例如：客戶 3 月於遊戲内消費了 HKD 100，幾天後退款，4 月收到 3 月月結單的時候可以看到 Google Play Store 購買 HKD 100，Google Play Store 回贈扣減 HKD 10 以及之後的退款項目：Google Play Store 退款HKD 100 及 Google Play Store 回贈獲得 HKD10，合計消費 HKD 0。
      2.5 課金回贈僅適用於指定的遊戲，具體遊戲清單參考活動頁面遊戲列表，不定期更新。
      3. 任用指定遊戲數據之條款及細則
      3.1 免流量服務僅提供給指定遊戲，客戶在遊玩此遊戲的時候之數據流量將不會被計算在客戶之服務計劃的數據流量，具體遊戲清單參考活動頁面遊戲列表，不定期更新。
      3.2 指定遊戲數據免流量只適用於免本地用量。
      3.3 免流量數據不包括：（i）下載及更新該應用程式、軟體；（ii）使用該程式之定位及簽到服務功能；（iii）透過該程式流覽、連結或下載來自任何其他網頁或應用程式的任何內容及額外提示產生之數據用量；（iv）該應用程式播放之廣告。
      3.4 「任用指定遊戲數據」之最高數據下載速度將根據指定月費計劃而有所不同，當每月本地數據用量達到該指定服務計劃之「指定數據用量」上限，該指定服務計劃及「任用指定遊戲數據」 之本地數據服務仍可繼續，唯數據速度將不高於該指定服務計劃之其後指定速度，數據服務體驗或會受影響。
      4. 其他
      4.1 如有懷疑本計劃參加者（i）進行或與異常/可疑活動有關；或（ii）以非法、不誠實、詐騙、或提供虛假或不正確的資料；或（iii）曾經違反 s/ash 任何活動之條款及細則之行為、或其他任何適用法律或條文；（iv）以其他不良手法參與本計劃，s/ash 有酌情權及最終決定權，在不作任何通知及毋須對任何人士負上任何責任的情況下，取消任何人士參加本計劃的資格及/或所有獎賞，並對該人士保留追究權利，及追討活動期間的獎賞及所有相關損失。
      4.2 當客戶參與本計劃即代表客戶接受相關條款及接受是次活動之推廣訊息。客戶是次同意收取有關本計劃的推廣訊息並不會取代較早前選擇是否接收 s/ash 宣傳資料的指示。請參閱 s/ash 個人資料及私隱聲明。
      4.3 如因此推廣活動或者相關事項直接、間接地引致合資格客戶或任何人士蒙受或招致各種特別、直接、間接或繼發性的損失和損害 (包括但不只限於收入損失、數據遺失、商譽損失)，無論如何 s/ash 均不必承擔合約、侵權、成文法律和其他方面（包括但不只限於疏忽、違約、誹謗）的責任。
      4.4 如參加者有任何違反法律、本條款及細則或可能損害第三方權利的行為或不作為，參加者同意並契諾向 s/ash 為其行為或不作為引致或相關的任何糾紛及索賠作出彌償以及使 s/ash 免受損害。
      4.5 本條款及細則及一切與本條款及細則相關的事項均應受香港特別行政區法律專屬管轄及以其作解釋及執行。參加者同意，如有任何分歧、爭議、請求或索賠等，將不可撤回地及無條件地服從香港特別行政區法院的專屬管轄權。
      4.6 本條款及細則中的每一條條款應有獨立的執行力，其有效性不受其他條款之無效性影響。
      4.7 除合資格參加者和 s/ash 外，任何人均不享有香港法例《合約(第三者權利)條例》下的任何權利，以執行或享有本條款及細則任何條文的利益或權利。
      4.8 s/ash 保留隨時更改本活動內容、終止本活動、及/或修訂本條款及細則的權利，恕不另行通知。任何有關活動的臨時改動或取消，將以 s/ash 的公佈為確據。
      4.9 本活動不設上訴機制。如有任何爭議，s/ash 保留最終決定權。任何爭議將以 s/ash 之系統記錄作為確據。
      4.10 本條款及細則的中、英文版如有任何歧義，概以中文版為準。
    `
  },
  BillPaymentService: {
    有關條款請參閲安卓:
      '有关条款请参阅<a href="/pdf/Google Play流動電訊商賬單付款服務條款及細則.pdf">Google Play流动电讯商账单付款服务条款及细则</a>',
    有關條款請參閲苹果:
      '有關條款請參閲：<a href="/pdf/Apple服務流動電訊商賬單付款服務條款及細則.pdf">Apple服务流动电讯商账单付款服务条款及细则</a>',
    立即啟動流動電訊商賬單付款服務: '立即启动流动电讯商账单付款服务',
    啟動服務: '启动服务',
    '*請留意：首次啟用 「流動電訊商賬單付款服務」時，手機必須和s/ash網絡連接':
      '*请留意：首次启用 「流动电讯商账单付款服务」时，手机必须和s/ash或CMHK网络连接',
    服務介紹: '服务介绍',
    以GooglePlay流動電訊商賬單付款服務支援: '以Google Play/Apple服务流动电讯商账单付款服务支援',
    直接: '直接',
    一次過月結單繳費: '一次过月结单缴费',
    方便: '方便',
    省卻每次輸入資料: '省却每次输入资料',
    安全: '安全',
    不須用信用卡繳費: '不须用信用卡缴费',
    啟用教學: '启用教学',
    '只需進行簡單設定，即可啟用服務': '只需进行简单设定，即可启用服务',
    使用Android設備: '使用Android设备',
    使用iOS設備: '使用iOS设备',
    Android1: '1、登入Google账户，开启「Google Play」应用程式，并按右上方你的个人头像以进入账户管理页面，',
    Android2: '2、选取「付款和订阅」',
    Android3: '3、选取「付款方式」',
    Android4: '4、选取「新增中国移动香港China Mobile HK 账单」',
    Android5: '5、最后按下「启用」。便完成启用程序',
    ios1: '1. 打开「设置」，点选（或登入）你的Apple ID',
    ios2: '2. 点选「付款与配送」',
    ios3: '3. 点选「新增付款方式」',
    ios4: '4.点选「手提电话」及「使用此电话号码」（CMHK电话号码），然后按「完成」',
    更多游戏: '更多游戏',
    活动条款内容: `「打機 Chill 住玩」條款及細則（「本條款及細則」）
      1. 合資格用戶
      1.1. 「打機 Chill 住玩」只適用於 s/ash 之客戶 (「合資格客戶」)， 合資格客戶能：
      (a) 透過指定 Android 作業系統及使用 s/ash 賬單付款服務於指定手機遊戲課金可
      以得到 10%的回贈（「課金回贈」）；及
      (b) 任用指定手機遊戲數據（「任用指定遊戲數據」）。
      2. 課金回贈之條款及細則
      2.1 課金回贈之合資格客戶必須符合以下條件：
      (a) 使用 Android 2.2 或以上作業系統並已安裝 Google Play 商店應用程式之智
      能手機，惟部分手機可能因手機品牌、型號、韌體版本不同而有所差異。有關手
      機內置設定的疑問，請聯絡有關手機製造商；及
      (b) 於選用 s/ash 計劃的客戶且使用 s/ash 賬單付款服務
      2.2 課金回贈不適用於 Apple 服務購買之應用程式、遊戲、書籍或電影等的賬項。
      2.3 課金回贈可供合資格客戶透過其 s/ash 賬戶支付於 Google Play 服務購買指定遊戲内消
      費。有關賬項將列入 s/ash 給予客戶的該月之月結單內，合資格客戶需要根據該月結單
      上所列出的金額付款；例如：客戶 3 月於遊戲内消費了 HKD 100，4 月收到 3 月之月結
      單的時候可以看到 Google Play 消費 HKD 100 以及 s/ash 所發放之 Google Play 消費
      回贈扣減 HKD10 兩個項目，合計支付 HKD 90。
      2.4 所有退款只可由應用程式開發商或 Google 審批及執行。s/ash 只提供本服務作一項付
      款方式。如客戶要求退款，須要直接聯絡有關開發商或 Google。所有退款要求均須按照
      Google 退 款 政 策 處 理
      <a href="https://support.google.com/googleplay/answer/2479637?hl=zh-HK#policy">https://support.google.com/googleplay/answer/2479637?hl=zh-HK#policy</a> 。 例
      如︰客戶退款後，該月的月結單上會將 Google Play Store 購買後產生的回贈金額用
      Google Play Store 回贈條目補回相應金額。例如：客戶 3 月於遊戲内消費了 HKD 100，
      幾天後退款，4 月收到 3 月月結單的時候可以看到 Google Play Store 購買 HKD 100，
      Google Play Store 回贈扣減 HKD 10 以及之後的退款項目：Google Play Store 退款
      HKD 100 及 Google Play Store 回贈獲得 HKD10，合計消費 HKD 0。
      2.5 課金回贈僅適用於指定的遊戲，具體遊戲清單參考活動頁面遊戲列表，不定期更新。
      3. 任用指定遊戲數據之條款及細則
      3.1 免流量服務僅提供給指定遊戲，客戶在遊玩此遊戲的時候之數據流量將不會被計算在
      客戶之服務計劃的數據流量，具體遊戲清單參考活動頁面遊戲列表，不定期更新。
      3.2 指定遊戲數據免流量只適用於免本地用量。3.3 免流量數據不包括：（i）下載及更新該應用程式、軟體；（ii）使用該程式之定位及簽到
      服務功能；（iii）透過該程式流覽、連結或下載來自任何其他網頁或應用程式的任何內
      容及額外提示產生之數據用量；（iv）該應用程式播放之廣告。
      3.4 「任用指定遊戲數據」之最高數據下載速度將根據指定月費計劃而有所不同，當每月
      本地數據用量達到該指定服務計劃之「指定數據用量」上限，該指定服務計劃及「任用
      指定遊戲數據」 之本地數據服務仍可繼續，唯數據速度將不高於該指定服務計劃之其
      後指定速度，數據服務體驗或會受影響。
      4. 其他
      4.1 如有懷疑本計劃參加者（i）進行或與異常/可疑活動有關；或（ii）以非法、不誠實、詐
      騙、或提供虛假或不正確的資料；或（iii）曾經違反 s/ash 任何活動之條款及細則之行
      為、或其他任何適用法律或條文；（iv）以其他不良手法參與本計劃，s/ash 有酌情權及
      最終決定權，在不作任何通知及毋須對任何人士負上任何責任的情況下，取消任何人士
      參加本計劃的資格及/或所有獎賞，並對該人士保留追究權利，及追討活動期間的獎賞
      及所有相關損失。
      4.2 當客戶參與本計劃即代表客戶接受相關條款及接受是次活動之推廣訊息。客戶是次同意
      收取有關本計劃的推廣訊息並不會取代較早前選擇是否接收 s/ash 宣傳資料的指示。請
      參閱 s/ash 個人資料及私隱聲明。
      4.3 如因此推廣活動或者相關事項直接、間接地引致合資格客戶或任何人士蒙受或招致各種
      特別、直接、間接或繼發性的損失和損害 (包括但不只限於收入損失、數據遺失、商譽
      損失)，無論如何 s/ash 均不必承擔合約、侵權、成文法律和其他方面（包括但不只限於
      疏忽、違約、誹謗）的責任。
      4.4 如參加者有任何違反法律、本條款及細則或可能損害第三方權利的行為或不作為，參加
      者同意並契諾向 s/ash 為其行為或不作為引致或相關的任何糾紛及索賠作出彌償以及
      使 s/ash 免受損害。
      4.5 本條款及細則及一切與本條款及細則相關的事項均應受香港特別行政區法律專屬管轄
      及以其作解釋及執行。參加者同意，如有任何分歧、爭議、請求或索賠等，將不可撤回
      地及無條件地服從香港特別行政區法院的專屬管轄權。
      4.6 本條款及細則中的每一條條款應有獨立的執行力，其有效性不受其他條款之無效性影響。
      4.7 除合資格參加者和 s/ash 外，任何人均不享有香港法例《合約(第三者權利)條例》下的
      任何權利，以執行或享有本條款及細則任何條文的利益或權利。
      4.8 s/ash 保留隨時更改本活動內容、終止本活動、及/或修訂本條款及細則的權利，恕不另
      行通知。任何有關活動的臨時改動或取消，將以 s/ash 的公佈為確據。
      4.9 本活動不設上訴機制。如有任何爭議，s/ash 保留最終決定權。任何爭議將以 s/ash 之
      系統記錄作為確據。
      4.10 本條款及細則的中、英文版如有任何歧義，概以中文版為準。Terms and Conditions for " Chill Mobile Game" ("Terms and Conditions")
      `
  },
  myOrder: {
    常用功能: '常用功能',
    订单查询: '订单查询',
    查询您的订单: '查询您的订单',
    扫码查询订单: '扫码查询订单\n仅适用于经s/ash网店登记上台之客户'
  },
  porting_guide: {
    轉台小教學: '转台小教学',
    上台slash: '上台slash/携号转台/手机SIM卡转台很简单，以下是携号转台tips供参考。',
    覺得攜號轉台很麻煩:
      '觉得携号转台很麻烦? 其实5分钟就可以完成携号转台，只要准备香港身份证或往来港澳通行证、你正在用的SmarTone、csl、Birdie、3HK、HKBN、1010、中国联通的香港电话号码，轻松填写上台数据就可以！',
    舊合約未完約點算好:
      '旧合约未完约点算好？s/ash支持提前登记携号转台，如果你对现有计划合约期有疑问，可拨打以下提供的SmarTone、csl、Birdie、3HK、HKBN、1010、中国联通热线确认到期日或直接刑门市查询。',
    攜號轉台需時幾耐: '携号转台需时几耐？建议提前7个工作日登记携号转台，s/ash秉持高效率原则，最快3个工作日可以完成，同5G网速一样快。',
    從SmarTone: '从SmarTone、csl、Birdie、3HK、HKBN、1010、中国联通的电话plan、学生电话plan、上网plan携号转台成功tips。',
    攜號轉台還有: '携号转台还有什么需要注意? 登记前需要留意现有的携号转台号码是月费计划还是储值卡。',
    如果你是月費客戶:
      '如果你是月费客户(SmarTone、csl、Birdie、3HK、HKBN、1010、中国联通)，需要确保输入的姓名和香港身份证上姓名、现时使用的电讯商姓名一致、并且保证电话号码处于生效状态。',
    如果你是mysim:
      '如果你是mysim、sosim、clubsim等datasim/储值卡客户，要保证卡内余额不少于$1；输入SIM卡/电话卡背面完整的ICCID号码，包括英文字母（如有）；上传卡主证明书或SIM卡背面图片。CMHK储值卡例如mysim无法携号转台s/ash。',
    攜號轉台成功貼士:
      '携号转台成功贴士，如果失败，电话号码会留在SmarTone、csl、Birdie、3HK、HKBN、1010、中国联通等旧台，但s/ash会通知你协助你处理。',
    '即時開台s/ash，享受優惠學生電話plan': '实时开台s/ash，享受优惠学生电话plan、月费计划电话plan同MyLink优惠。',
    '即刻開台s/ash 更多優惠隨心享': '即刻开台s/ash 更多优惠随心享',
    立即開台: '立即转台'
  },
  slashintro: {
    '咩係s/ash?': '什么是s/ash?',
    's/ash香港5G SIM卡':
      's/ash香港5G SIM卡，也是旅行必备的电话卡，让你出国漫游时无需额外购买大陆电话卡和日本电话卡等旅游SIM卡。同时，依靠优质CMHK中国移动5G网络，提供高网速服务，同时打造最平电话卡。',
    '/5G通訊品牌': '/ 5G通讯品牌',
    '/ 社交娱乐KOL': '/ 社交娱乐KOL',
    '/ 旅遊出Trip達人': '/ 旅游Trip达人',
    Add我Instagram了解我更多: 'Add我Instagram了解我更多',
    's/ash使用中國移動CMHK':
      's/ash使用中国移动CMHK 5G网络提供优质上网服务，最抵SIM卡、学生电话plan优惠和MyLink积分，平价兑换机票兑换码、超市优惠券、电子产品、美妆产品等',
    '娛樂社交∞': '娱乐社交∞',
    '【無限任用】15個APPs 5G數據！': '【无限任用】15个APPs 5G数据！',
    'L Plan送你「任用社交及娛樂數據組合」(價值: $38/月)':
      '本地计划免费送，一卡两地计划可以$9/月加购「任用社交及娱乐数据组合」(价值: $38/月)',
    '‧ Youtube、Netflix、Instagram等15個APPs數據通通免費任用': 'Youtube、Netflix、Instagram等15个APPs数据通通免费任用',
    旅行出遊: '旅行出游',
    '【漫游】無需換卡到埗即用！': '【漫游】无需换卡落地即用！',
    '‧ 中國内地/澳門': '‧ 本地计划每月赠送中国内地及澳门数据',
    '3個Plan都送你中國内地同澳門漫游數據': '一咭两地计划更享中国内地及香港共用数据',
    '‧ 日、韓、泰、英、美等其他160+地區': '‧ 日、韩、泰、英、美等其他160+地区',
    '上MyLink APP買漫遊GO! Pass、短途旅行隨時啓程': '上MyLink APP买漫游GO! Pass、短途旅行随时启程',
    打機: '购机无忧',
    '【Chill住打機】想課金就課金': '【Chill松购机】想换新机无需多虑！',
    '指定熱門手機游戲：': '指定热门手机游戏：',
    '‧ 免數據任玩': '‧ 免数据任玩',
    '‧ 於Google Play課金可享10%回贈': '‧ 於以Google Play课金10%回赠',
    打機chill住玩: 'Chill畅玩游戏',
    '选用本地M及L Plan并签约24个月': '选用本地M及L Plan并签约24个月',
    '另享受$1, 200 5G手機獎賞，支援選購熱門品牌5G手機': '另享受$1, 200 5G手机奖赏，支援选购热门品牌5G手机',
    '/品牌介紹': '/品牌介绍',
    '「slash」全新態度': '「slash」全新态度',
    '現今我地愛以「s/asher」自居，以「/」表示自己喺社會上同一時間扮演住唔同角色，唔再用單一身分去定義自己，甚至喺社交平台上開設多個帳戶，去展現自己多元嘅生活面貌，朝早我係一位OL，夜晚我可以係一位瑜伽老師。我係一名學生嘅同時我亦可以係一位網店店主。':
      '现在我们爱以「s/asher」自居，以「/」表示自己在社会上同一时间扮演着不同角色，不再用单一身分去定义自己，甚至在社交平台上开设多个账户，去展现自己多元的生活面貌，早上我是一个OL，夜晚我可以是一个瑜伽老师。我是一名学生，同时我也可以是一个网店店主。',
    '「s/asher」這個全新生活方式，代表我地年青人渴求彰顯自己獨特個性同態度，期望以自己嘅喜好同潛能去做自己想做嘅事。「s/asher」不只是一個身份、更代表的背後的一份態度！':
      '「s/asher」这个全新生活方式，代表我们年青人渴求彰显自己的独特个性和态度，期望以自己的喜好和潜能去做自己想做的事。「s/asher」不只是一个身份、更代表背后的一份态度！',
    '「s/ash」速度 闊度 你想生活態度': '「s/ash」速度 阔度 你想生活态度',
    's/ash - 專為年青人而設嘅通訊生活品牌，將今日s/ash嘅態度融入品牌，以「/」呢一劃為你連結全新身份，回歸通訊服務的品牌，同時為你帶來生活品味嘅禮遇，我地：':
      's/ash - 专为年青人而设的通讯生活品牌，将今日s/ash的态度融入品牌，以「/」这一笔为你连结全新身份，回归通讯服务的品牌，同时为你带来生活品味的礼遇，我们：',
    '以最優質嘅5G通訊網絡 為你帶來速度與日常的便利 ': '以最优质的5G通讯网络 为你带来速度与日常的便利',
    '以最具品味嘅生活禮遇 為你拓展你嘅闊度與視野': '以最具品味的生活礼遇 为你拓展你的阔度与视野',
    '助你連結世界、分享日常、追求理想，切實體現s/ash精神': '助你连结世界、分享日常、追求理想，切实体现s/ash精神',
    服務計劃: '服务计划',
    '簡單3個Plan  12個月短約 線上全自助辦理  揀1個最啱你！': '简单4个Plan 12/24个月合约期灵活选 线上即可办理 选一款最适合你的！',
    '- 青春限定 - ': '- 青春限定 -',
    全民免3個月月費: '只为年青人而设的青春应援优惠价',
    '18-29歲客人再加免3個月! 總共免5個月! ': '仅<span style="color:#eb6ea5">学生 & 18-29岁</span> 客户可享',
    '- 加碼獎勵 - ': '- 加码奖励 -',
    送中國內地及澳門數據: '本地计划送中国内地及澳门数据',
    免$18行政費: '携号转台免$18行政费',
    '網絡實測 - 用s/ash上網有幾快?': '网络实测 - 用s/ash上网有多快?',
    网络实测: '网络实测',
    在香港不同的地方: '在香港不同的地方做5G网络测试，例如湾仔会展、The Wai大围商场、City U城市大学、浸会大学等。测试结果显示，网速优质。',
    速度: '速度',
    ' 港鐵荃灣綫由中環去到荃灣需要32分鐘；': '港铁荃湾线由中环去到荃湾需要<span class=" blue">32分钟</span>；',
    ' 肚餓沖杯面食需要等3分鐘；': '肚子饿冲杯泡面需要等<span class=" blue">3分钟</span>；',
    ' 而上台s/ash，使用由 CMHK支撐的5G網絡後， ': '而上台s/ash，使用由 <span class=" blue">CMHK支撑的5G网络后</span>，',
    ' 下載一集Netflix韓劇/美劇全高畫質的影片只需約30秒*！ ':
      '下载一集Netflix韩剧/美剧全高画质的影片只需约<span class=" blue">30秒</span>*！',
    ' 上Instagram、Facebook、Youtube觀看內容更只需1瞬間！':
      '上Instagram、Facebook、Youtube观看内容更只需<span class=" blue">1瞬间</span>！',
    ' s/ash應援好你日常每分每秒，以可以達到最快嘅速度為你填補你所需的每一秒':
      '<span class=" blue">s/ash应援好你日常每分每秒，以可以达到最快的速度为你填补你所需的每一秒</span>',
    覆蓋: '覆盖',
    '99%的網絡覆蓋列舉:': '99%的网络覆盖列举:',
    ' - 中西區、灣仔、銅鑼灣': '- 中西区、湾仔、铜锣湾',
    ' - 葵青區、荃灣區、沙田區 ': '- 葵青区、荃湾区、沙田区',
    ' - 觀塘區、油尖旺、元朗區 ': '- 观塘区、油尖旺、元朗区',
    's/ash時刻應援好你日常，讓你無論留守校園、遊走市區與郊外也不斷網': 's/ash时刻应援好你日常，让你无论留守校园、游走市区与郊外也不断网',
    '*實際網絡服務會受服務地點、網絡情況、網絡覆蓋範圍、硬件、軟件及其他因素而有所影響':
      '*实际网络服务会受服务地点、网络情况、网络覆盖范围、硬件、软件及其他因素而有所影响',
    媒體報導: '媒体报导',
    更多報導: '更多报导',
    '舊約完前180日就可以登記   立即登記s/ash享受更多專享禮遇': '旧约完前180日就可以登记 立即登记s/ash享受更多专享礼遇',
    上台或者攜號: '上台或者携号转台s/ash电话卡就可以获得5G携号转台优惠。',
    立即登記: '立即登记',
    測速實例: '网络实测 - 用s/ash上网有多快?',
    测速desc: '*实际网络服务会受服务地点、网络情况、网络覆盖范围、硬件、软件及其他因素而有所影响'
  },
  HelpCenter: {
    常见问题: '常见问题',
    您可以在下方查询常见问题的解决方案: '您可以在下方查询常见问题的解决方案。如仍未能解决您的问题，请通过即时CHAT联络我们。',
    输入框占位: '请输入问题的关键词，如“最新推广”',
    共找到: '共找到',
    个结果: '个结果',
    问题类型: '问题类型',
    不限: '不限',
    帮助中心: '幫助中心',
    搜索结果: '搜索结果',
    '抱歉，没有找到相关问题～': '抱歉，没有找到相关问题～',
    去试试: '去试试',
    即时Chat: '即时Chat',
    '吧！': ' 吧！'
  },
  TNC: {
    首页: '首页',
    条款及细则列表: '条款及细则',
    条款及细则: '条款及细则',
    于2023年09月15日更新: '于2024年08月08日更新',
    // 'Google Play流動電訊商賬單付款服務條款及細則': 'Google Play流動電訊商賬單付款服務條款及細則',
    // '「100GB本地數據券」優惠活動條款及細則': '「100GB本地數據券」優惠活動條款及細則',
    预览: '预览',
    下载: '下载',
    list: [
      {
        id: 1,
        title: 's/ash 5G服务计划及5G 一咭两地服务计划之条款与细则',
        content: `
        1.	月費優惠只適用於選用指定s/ash 5G本地服務計劃30GB/50GB/100GB並承諾最少使用服務期12個月或以上或5G一咭兩地服務計劃20GB並承諾最少使用服務期24個月之新客戶 。上述服務計劃基本服務月費爲5G本地30GB:HK$338; 5G本地50GB: $388; 5G本地100GB: $438；5G一咭兩地20GB: HK$349。客戶須另繳每月HK$18行政費。 
        2.	除中國移動香港有限公司（「中國移動香港」）網站特別注明外，中國内地及澳門的數據漫遊服務會以5G網絡提供。有關數據漫遊服務地區的資訊，請瀏覽<a href="https://bit.ly/39XqbWa">https://bit.ly/39XqbWa</a>. 
        3.	就s/ash 5G本地服務計劃30GB/50GB/100GB而言，當每月使用的數據用量超過該月服務計劃所包含的5G本地數據用量，香港本地數據服務仍可繼續, 而有最高傳輸速度限制（s/ash 5G本地服務計劃:30GB/50GB: 1Mbps; 100GB: 5Mbps）及不適用於網絡分享(tethering)及點對點(P2P)功能(包括Bit-Torrent等)。就5G一咭兩地服務計劃20GB而言，當每月使用的數據用量超過該月服務計劃所包含的數據用量，香港數據服務仍可繼續而傳輸速度限制最高為1Mbps 而中國內地漫遊數據將暫停提供及不適用於網絡分享(tethering)及點對點(P2P)功能(包括Bit-Torrent等)。
        4.	選用指定s/ash 5G本地服務計劃30GB/50GB/100GB並承諾最少使用服務期12個月或以上之新客戶可分別享額外每月3GB/4GB/6GB中國內地及澳門漫遊數據。本優惠有效期直至另行通知。受條款及細則約束。中國內地及澳門數據用量可於中國內地/澳門使用。漫遊數據用量只適用於已啓用漫遊服務的客戶。如客戶尚未啓用漫遊服務，須於到訪指定地區前撥出*109*01#啓用；或透過中國移動香港網頁/MyLink App設定，詳情請參閱中國移動香港網站。當客戶到訪中國內地/澳門，手機將會自動搜尋並鎖定中國移動香港於當地的網絡供應商合作夥伴，以供使用數據漫遊服務。有關網絡供應商合作夥伴將不時變更。客戶使用航空漫遊、海事漫遊及衛星漫遊服務或於非指定地區使用數據漫遊服務所產生之費用將按標準漫遊數據用量或數據漫遊自由圈(如適用)計算。漫遊數據用量不適用於網絡分享(tethering)及點對點(P2P)功能(包括Bit-Torrent等)。大灣區(中國內地及澳門)數據漫遊組合受相關的條款及細則約束，詳情請瀏覽： <a href="https://bit.ly/39XqbWa">https://bit.ly/39XqbWa</a>。
        5.	中國內地通話分鐘適用於(i)在中國內地致電所有中國內地及香港電話號碼；及(ii)在中國內地接聽所有中國內地及香港電話號碼。
        6.	新上台客戶選用指定s/ash 5G本地服務計劃30GB/50GB/100GB並承諾最少使用服務期12個月或以上，合約期內可享「任用社交及娛樂數據組合」(價值HK$38/月) （「贈送優惠」）。新上台客戶選用指定一咭兩地服務計劃20GB並承諾最少使用服務期24個月，可於合約期內以服務正價減去港幣29元的優惠月費訂購「任用社交及娛樂數據組合」增值服務（「減價優惠」）。優惠價HK$9是以「任用社交及娛樂數據組合」截至2024年7月26日的月費正價HK$38扣除減價優惠後月費為HK$9計算，計算公式HK$38- HK$29= HK$9。贈送優惠及減價優惠只適用於指定服務計劃，並僅於合約期内生效，如客戶中途轉至其他服務計劃或指定服務計劃合約期滿，贈送優惠/減價優惠將自動終止。有關「任用社交及娛樂數據組合」增值服務詳情，請參閲<a href="https://bit.ly/socialdataTC">https://bit.ly/socialdataTC</a>。除了本條款及細則，客戶亦同意遵守「任用社交及娛樂數據組合」增值服務相關的條款及細則。
        7.	 5G手機獎賞只適用於選用s/ash 5G本地服務計劃30GB/50GB/100GB並承諾最少使用服務期24個月的新客戶。客戶需於指定日期前成功登記指定服務計劃，並於2024年12月31日或之前使用此優惠到門市購買指定手機。適用5G手機獎賞的指定手機型號詳情請向門市同事查詢。使用優惠時，需預繳指定金額及繳付指定機價。預繳金額將於購買手機日的下個賬單月起計12個月內平均回贈至客戶的賬單內。每個服務計劃只可享此優惠一次。不可與其他新上台手機優惠同時享有。手機視乎供貨情況而定。5G手機獎賞不能兌換現金、退款或以任何形式找贖，亦不可轉讓或轉售。中移香港並不保證手機的供應/取貨日期，獲享5G手機獎賞不會視為預留任何手機。以上所有優惠及服務計劃受相關條款及細則約束。
        8.	客戶成功選用5G一咭兩地服務計劃20GB並簽訂24個月合約，可於合約期內豁免中國內地「一咭多號」增值服務（價值HK$18/月）的月費或「關聯家鄉號」增值服務（價值HK$18/月）的月費（二選其一）。二選一優惠一經選定，客戶中途不可更改有關選擇。客戶之中國內地電話號碼須完成辦理實名登記手續方可使用。「一咭多號」服務的中國內地及香港電話號碼均可接聽來電及接收短訊，惟客戶發出的短訊皆以其香港主號碼顯示。而中國內地電話號碼之短訊服務須按量額外收費以及話音服務仍須以每分鐘計算通話費用額外收費，詳情請參閱<a href="https://bit.ly/3WERg6c">https://bit.ly/3WERg6c</a> 。
        9.	有關中國內地「一咭多號」增值服務詳情，請參閱<a href="https://bit.ly/3WERg6c">https://bit.ly/3WERg6c</a>。除了本條款及細則，客戶亦同意遵守中國內地「一咭多號」增值服務相關的條款及細則。
        10.	有關「關聯家鄉號」增值服務詳情，請參閱<a href="https://bit.ly/cmnumberlink">https://bit.ly/cmnumberlink</a>。除了本條款及細則，客戶亦同意遵守「關聯家鄉號」增值服務相關的條款及細則。
        11.	攜號轉台並承諾最少使用服務期12個月之新客戶可於合約期内豁免$18行政費。攜號轉台客戶須於簽約後180日内成功攜號轉台。如果客戶於最少使用服務期內升級至其他服務計劃或更改電話號碼，本優惠會被取消。本優惠有效期直至另行通知。
        
        •	以上所有優惠及服務計劃受相關條款及細則約束。
        •	以上所有優惠所指的新客戶包括新上台客戶及攜號轉台之新客戶。 
        •	除非特別注明，以上優惠有效期為2024年8月14日起直至另行通知。
        •	如客戶更改選用其他服務計劃，或於合約期內提前終止服務計劃，上述優惠將自動取消並有權向客户收取已享用之優惠的總額而毋須事先通知。
        •	s/ash 5G本地服務計劃只適用於辦理開台當時為18-29歲之客戶或指定香港小學、中學、大專院校的學生客戶申請。學生登記本人須年滿18歲，並為指定院校機構之學生，同時須出示指定院校機構發出的有效學生證。
        •	5G一咭兩地服務計劃只適用於辦理開台當時為18-29歲之客戶。
        •	每個有效學生身份證明只可用作登記s/ash 5G本地服務計劃一次。如相同的學生身份證明被用作重複登記s/ash 5G本地服務計劃，相關重複申請將被取消。 
        •	中國移動香港保留要求客戶於有需要情況時出示有效身份證明文件/指定學生證以證明客戶是否當時年齡仍介乎18-29歲/仍擁有有效學生身份的權利。若在任何情況下發現身份證明文件/指定學生證無效或不合資格，或客戶拒絕提供有效身份證明文件/指定學生證，中國移動香港保留於任何時間拒絕提供、暫停或取消指定服務計劃及指定服務計劃下的優惠的權利，而恕不另行通知。
        •	如客戶於最少使用服務期內提前終止服務計劃，客戶須按合約上之約定賠償金額支付予中國移動香港。
        •	本地5G數據服務以中國移動香港5G網絡提供，如客戶在香港身處5G數據服務覆蓋以外地區，中國移動香港仍會透過4G/3G/2G網絡爲客戶提供服務。除於中國移動香港網站特別注明外，中國內地/澳門數據漫遊服務則會以5G網絡提供。如客戶身處5G數據服務覆蓋以外地區，中國內地/澳門數據漫遊服務仍會透過4G/3G/2G網絡爲客戶提供服務。使用5G數據服務須配合指定型號流動裝置使用。實際的網絡服務會受服務地點、網絡情况、網絡覆蓋範圍、硬件、軟件及其他因素而有所影響。
        •	客戶可於香港以外的指定地區使用5G網絡(如適用)。5G網絡須透過指定的網絡供應商提供。當客戶到訪香港以外的指定地區手機將會自動搜尋並鎖定中國移動香港於當地的網絡供應商合作夥伴，以供使用數據漫遊服務。客戶使用航空漫遊、海事漫遊及衛星漫遊服務或於非指定地區使用數據漫遊服務所產生之費用將按標準漫遊數據用量或數據漫遊自由圈(如適用)計算。 有關之指定國家/地區及網絡供應商將不時修訂或變更。有關5G數據漫遊服務地區、漫遊及IDD收費的資訊，請參閱中國移動香港之網站。
        •	於中國內地漫遊時數據用量只適用於中國移動2G/3G TD-SCDMA/4G TDD-LTE/5G網絡及不適用於FTP、P2P功能(包括BitTorrent、Peer Casting應用程序-如PP Stream、網絡電視等)。中國移動3G TD-SCDMA/4G TDD-LTE/5G網絡服務須配合適合裝置。
        •	中國移動香港有權隨時更改漫遊數據用量之收費，而恕不另行通知。中國移動香港保留隨時更改或終止以上優惠內容的權利，而恕不另行通知。
        •	如有任何爭議，中國移動香港保留一切最終决定權。
        •	受條款及細則約束。
        以上資料爲中國移動香港有限公司最新公布之服務內容及收費，並取代2024年8月14日之前上載的有關資料。  

        `,
        isAnnex: true,
        size: '378KB',
        link: '/pdf/slash 5G本地服務計劃及5G一咭兩地服務計劃之條款及細則.pdf'
      },
      {
        id: 2,
        title: '打機chill住玩條款及細則（活动已完结）',
        content: `
            1.	合資格用戶
            1.1.	「打機Chill住玩」只適用於s/ash之客戶 (「合資格客戶」)， 合資格客戶能：
            (a)	透過指定Android作業系統及使用s/ash 賬單付款服務於指定手機遊戲課金可以得到10%的回贈（「課金回贈」）；及
            (b)	任用指定手機遊戲數據（「任用指定遊戲數據」）。

            2.	課金回贈之條款及細則
            2.1	課金回贈之合資格客戶必須符合以下條件：
            (a)	使用Android 2.2 或以上作業系統並已安裝 Google Play 商店應用程式之智能手機，惟部分手機可能因手機品牌、型號、韌體版本不同而有所差異。有關手機內置設定的疑問，請聯絡有關手機製造商；及
            (b)	於選用s/ash計劃的客戶且使用s/ash 賬單付款服務
            2.2	課金回贈不適用於Apple 服務購買之應用程式、遊戲、書籍或電影等的賬項。
            2.3	課金回贈可供合資格客戶透過其s/ash賬戶支付於Google Play服務購買指定遊戲内消費。有關賬項將列入s/ash給予客戶的該月之月結單內，合資格客戶需要根據該月結單上所列出的金額付款；例如：客戶3月於遊戲内消費了HKD 100，4月收到3月之月結單的時候可以看到Google Play消費HKD 100以及s/ash所發放之Google Play消費回贈扣減HKD10兩個項目，合計支付HKD 90。
            2.4	所有退款只可由應用程式開發商或 Google 審批及執行。s/ash只提供本服務作一項付款方式。如客戶要求退款，須要直接聯絡有關開發商或 Google。所有退款要求均須按照 Google 退款政策處理<a href="https://support.google.com/googleplay/answer/2479637?hl=zh-HK#policy">https://support.google.com/googleplay/answer/2479637?hl=zh-HK#policy</a>。例如︰客戶退款後，該月的月結單上會將Google Play Store購買後產生的回贈金額用Google Play Store回贈條目補回相應金額。例如：客戶3月於遊戲内消費了HKD 100，幾天後退款，4月收到3月月結單的時候可以看到Google Play Store購買HKD 100，Google Play Store回贈扣減HKD 10以及之後的退款項目：Google Play Store退款HKD 100 及Google Play Store回贈獲得HKD10，合計消費HKD 0。
            2.5	課金回贈僅適用於指定的遊戲，具體遊戲清單參考活動頁面遊戲列表，不定期更新。

            3.	任用指定遊戲數據之條款及細則
            3.1	免流量服務僅提供給指定遊戲，客戶在遊玩此遊戲的時候之數據流量將不會被計算在客戶之服務計劃的數據流量，具體遊戲清單參考活動頁面遊戲列表，不定期更新。
            3.2	指定遊戲數據免流量只適用於免本地用量。
            3.3	免流量數據不包括：（i）下載及更新該應用程式、軟體；（ii）使用該程式之定位及簽到服務功能；（iii）透過該程式流覽、連結或下載來自任何其他網頁或應用程式的任何內容及額外提示產生之數據用量；（iv）該應用程式播放之廣告。
            3.4	「任用指定遊戲數據」之最高數據下載速度將根據指定月費計劃而有所不同，當每月本地數據用量達到該指定服務計劃之「指定數據用量」上限，該指定服務計劃及「任用指定遊戲數據」 之本地數據服務仍可繼續，唯數據速度將不高於該指定服務計劃之其後指定速度，數據服務體驗或會受影響。

            4.	其他
            4.1	如有懷疑本計劃參加者（i）進行或與異常/可疑活動有關；或（ii）以非法、不誠實、詐騙、或提供虛假或不正確的資料；或（iii）曾經違反s/ash任何活動之條款及細則之行為、或其他任何適用法律或條文；（iv）以其他不良手法參與本計劃，s/ash有酌情權及最終決定權，在不作任何通知及毋須對任何人士負上任何責任的情況下，取消任何人士參加本計劃的資格及/或所有獎賞，並對該人士保留追究權利，及追討活動期間的獎賞及所有相關損失。
            4.2	當客戶參與本計劃即代表客戶接受相關條款及接受是次活動之推廣訊息。客戶是次同意收取有關本計劃的推廣訊息並不會取代較早前選擇是否接收s/ash宣傳資料的指示。請參閱s/ash個人資料及私隱聲明。
            4.3	如因此推廣活動或者相關事項直接、間接地引致合資格客戶或任何人士蒙受或招致各種特別、直接、間接或繼發性的損失和損害 (包括但不只限於收入損失、數據遺失、商譽損失)，無論如何s/ash均不必承擔合約、侵權、成文法律和其他方面（包括但不只限於疏忽、違約、誹謗）的責任。
            4.4	如參加者有任何違反法律、本條款及細則或可能損害第三方權利的行為或不作為，參加者同意並契諾向s/ash為其行為或不作為引致或相關的任何糾紛及索賠作出彌償以及使s/ash免受損害。
            4.5	本條款及細則及一切與本條款及細則相關的事項均應受香港特別行政區法律專屬管轄及以其作解釋及執行。參加者同意，如有任何分歧、爭議、請求或索賠等，將不可撤回地及無條件地服從香港特別行政區法院的專屬管轄權。
            4.6	本條款及細則中的每一條條款應有獨立的執行力，其有效性不受其他條款之無效性影響。
            4.7	除合資格參加者和s/ash外，任何人均不享有香港法例《合約(第三者權利)條例》下的任何權利，以執行或享有本條款及細則任何條文的利益或權利。
            4.8	s/ash保留隨時更改本活動內容、終止本活動、及/或修訂本條款及細則的權利，恕不另行通知。任何有關活動的臨時改動或取消，將以s/ash的公佈為確據。
            4.9	本活動不設上訴機制。如有任何爭議，s/ash保留最終決定權。任何爭議將以s/ash之系統記錄作為確據。
            4.10	本條款及細則的中、英文版如有任何歧義，概以中文版為準。

        `,
        isAnnex: true,
        size: '344KB',
        link: '/pdf/打機chill住玩條款及細則.pdf'
      },
      {
        id: 3,
        title: '「100GB本地數據券」優惠活動條款及細則（活动已完结）',
        content: `
          1.	「100GB本地數據券」優惠活動(「本活動」)的推廣期直至另行通知。
          2.	本活動所有的優惠只適用於推廣期內透過s/ash計劃選購頁面選購的服務，並須受其相關條款及細則約束。
          3.	「100GB本地數據券」優惠活動*
          1.	「100GB本地數據券」(「本優惠」)只適用於全新s/ash用戶，透過s/ash計劃選購頁面選用s/ash S:30GB、s/ash M:50GB、s/ash L:100GB並承諾12個月或24個月合約期（「指定服務計劃」），可領取100GB本地數據券（「本地數據券」）。本地數據券可於服務計劃成功激活時發放至客戶通訊賬戶内。
          2.	*本活動所有的優惠，除特別註明外，不能與其他優惠同時使用。如客戶於合約期內提前終止服務計劃，優惠將自動取消並有權向客戶收取已享用之優惠的總額而毋須事先通知。
          4.	獲取本地數據券詳情：
          1.	擬選用指定服務計劃的客戶，可於s/ash活動頁面輸入手機號碼（需通過使用One Time Passcode驗證手機號碼為本人使用），即可獲得「100GB本地數據兌換碼」 （「兌換碼」），每個手機號限領1次兌換碼，此兌換碼30天内有效，只限使用一次，使用後或逾期作廢。
          2.	擬選用指定服務計劃的用戶，可於s/ash計劃選購頁面，輸入「兌換碼」。選購成功並於服務計劃生效後，即可獲得本地數據券。本地數據券會發放至用戶之通訊賬戶，可登錄MyLink 應用程式(「MyLink」)賬戶後，於左上角「頭像」>「我的電子券」查看並使用。
          3.	本地數據券有效期由發出日期開始計算，有效期為30天，逾期作廢。時間以s/ash伺服器時間為準。客戶需於此券有效期内自行激活，方可使用此券内包含的本地數據。此券一經激活，100GB本地數據於30天内有效。
          4.	兌換碼及本地數據券不可兌換現金或其他產品或服務，亦不能更換丶退款丶轉讓及轉售。
          5.	兌換碼及本地數據券只可使用一次，並不可重複使用。
          6.	本地數據券會於成功使用後轉移至 「所有無效券」頁面，具體位置如下：
          MyLink > 左上角「頭像」>「我的電子券」>「所有無效券」
          5.	本活動所有的優惠，除特別註明外，不能與其他優惠同時使用。
          6.	s/ash之員工（包括全職及兼職員工或以合約形式聘用的人士）不可以任何方式參與本活動，以示公允。
          7.	s/ash將客戶的個人資料於所有時候保密處理，並根據其内部政策，銷毀持有的個人資料。除非為遵守法定或合約義務或者為調查、偵測罪案、檢控疑犯等的合理原因外，s/ash只會限於其需要有關資料的時間内保留個人資料。如欲查閱個人資料及更正個人資料或詢問個人資料有關聲明，請致函: 中國移動香港有限公司，地址：香港新界葵涌葵昌路51號九龍貿易中心1座20樓，收信人：資料保護主任；或致電中國移動香港拒收商業電子訊息登記熱線6226 4926。
          8.	s/ash保留隨時更改或終止本活動、修訂相關條款及細則的權利，恕不另行通知。
          9.	如客戶於推廣期間終止本活動相關服務計劃或轉用其他非本活動指定的服務計劃，所享的優惠亦會同時終止，s/ash毋須為此承擔任何責任及/或賠償。
          10.	任何因電腦、網路等技術問題而引致客戶所遞交的資料有遲延、遺失、錯誤、無法辨識等情況，s/ash概不負責。
          11.	每位合資格客戶同意為其違反任何條款及細則、法律或第三方權利而引致的任何索賠或要求向s/ash作出彌償及使s/ash免受損害。
          12.	凡參加本活動者，即表示已細閱並同意此條款及細則。如s/ash懷疑合資格客戶有 (i)進行或與異常/可疑活動有關、（ii）使用不當的方法或欺詐行為而使本活動任何部份運作中斷或受到干擾，導致技術問題，或檢測到（iii）任何不誠實的行為、提供虛假或不正確的資料或（iv）任何違反本條款及細則或其他任何適用法律或條文；s/ash有權在不作任何通知及毋須對任何人士負上任何責任的情況下自行決定暫停/取消其領取優惠/禮品的資格，並對該人士保留追究權利。
          13.	如有任何爭議，s/ash將保留最終決定權。
          14.	本活動條款及細則之中英文版本如有歧義，以中文版本為準。
          15.	s/ash 是中國移動香港有限公司 (簡稱「中國移動香港」) (牌照號碼: 002) 之電訊服務品牌。

        `,
        isAnnex: true,
        size: '297KB',
        link: '/pdf/「100GB本地數據券」優惠活動條款及細則.pdf'
      },
      {
        id: 4,
        title: 'Google Play流動電訊商賬單付款服務條款及細則',
        content: `
          1.	Google Play流動電訊商賬單付款服務 (「本服務」)可供s/ash 客戶於支付 Google Play 商店內購買之應用程式、遊戲、書籍或電影的賬項。有關賬項將列入s/ash客戶的月結單內。客戶需要根據該月結單上所列出的金額一併付款。
          2.	本服務只適用於指定的s/ash之客戶。
          3.	此服務適用於Android 2.2 或以上作業系統並已安裝 Google Play 商店應用程式之智能手機，惟部分手機可能因手機品牌、型號、韌體版本不同而有所差異。有關手機內置設定的疑問，請聯絡你的手機製造商。
          4.	s/ash客戶必須年滿18嵗，否則本服務將不予提供。
          5.	新上台之s/ash客戶，最快於月費計劃服務生效翌日深夜2時正後可啟動本服務；攜號轉台客戶最快於成功攜號轉台後2小時可啟動本服務。
          6.	以下兩種情況的客戶無法使用賬單支付功能：1）非使用信用卡自動繳費，且新上台1個月或離合約期結束還剩1個月以内；2）客戶之賬單中有大於或等於兩期賬單未繳清。
          7.	客戶可以前往MyLink個人資料（點擊左上角頭像）-> 賬單付款等級中查看閣下的信用評級。
          8.	本服務對於每個評分等級都設置了一個月度消費限額，s/ash賬單付款服務的消費額上限如下:
          <table border="1" cellspacing="0" cellpadding="0">
          <tbody>
            <tr>
              <td valign="top" width="264">
                <p><span>信用評級</span></p>
              </td>
              <td valign="top" width="265">
                <p><span>每月消費限額（</span><span>$HK</span><span>）</span></p>
              </td>
            </tr>
            <tr>
              <td valign="top" width="264">
                <p><span>AAAAA</span></p>
              </td>
              <td valign="top" width="265">
                <p><span>3,000</span></p>
              </td>
            </tr>
            <tr>
              <td valign="top" width="264">
                <p><span>AAAA</span></p>
              </td>
              <td valign="top" width="265">
                <p><span>2,000</span></p>
              </td>
            </tr>
            <tr>
              <td valign="top" width="264">
                <p><span>AAA</span></p>
              </td>
              <td valign="top" width="265">
                <p><span>1,500</span></p>
              </td>
            </tr>
            <tr>
              <td valign="top" width="264">
                <p><span>AA</span></p>
              </td>
              <td valign="top" width="265">
                <p><span>500</span></p>
              </td>
            </tr>
            <tr>
              <td valign="top" width="264">
                <p><span>A</span></p>
              </td>
              <td valign="top" width="265">
                <p><span>300</span></p>
              </td>
            </tr>
          </tbody>
        </table>
          以上評級和每月消費限制的審查取決於s/ash的決定。s/ash對評級和每月消費限制的決定是最終決定，不得異議。
          9.	s/ash保留隨時更改和更新評級和每月消費限額的權利，而無需事先通知客戶。
          10.	本服務設有每月的消費上限，每月指從客戶的帳單月開始日至結束日為止。如果客戶的帳單月開始日是每個公曆月的第6天，結束日便是下個公曆月的第5天。如客戶的消費額超出每月上限，將不能繼續使用本服務支賬。
          11.	客戶下載任何 Google Play 商店內容及使用其應用程式將產生數據用量。本地數據用量將按照情況從客戶現行使用的服務計劃扣除或按標準數據收取額外費用；於漫遊時使用本服務則須收取標準漫遊數據費用。
          12.	所有購買項目均由 Google 提供，並適用於現有 Google Play 商店條款及細則。
          13.	客戶使用本服務即同意受到 Google 服務條款之約束，該協議可於<a href="https://play.google.com/intl/zh-HK_hk/about/play-terms.html">https://play.google.com/intl/zh-HK_hk/about/play-terms.html</a>瀏覽。
          14.	s/ash有權隨時暫停或終止本服務而不作事先通知。
          15.	所有退款只可由應用程式開發商或 Google 審批及執行。s/ash只提供本服務作一項付款方式。如客戶要求退款，須要直接聯絡有關開發商或 Google。所有退款要求均須按照 Google 退款政策處理<a href="https://support.google.com/googleplay/answer/2479637?hl=zh-HK#policy">https://support.google.com/googleplay/answer/2479637?hl=zh-HK#policy</a>。例如︰客戶退款後，該月的月結單上會將Google Play Store購買後產生的回贈金額用Google Play Store回贈條目補回相應金額。例如：客戶3月於遊戲内消費了HKD 100，幾天後退款，4月收到3月月結單的時候可以看到Google Play Store購買HKD 100，Google Play Store回贈扣減HKD 10以及之後的退款項目：Google Play Store退款 HKD 100 及Google Play Store回贈獲得HKD10，合計消費HKD 0。
          16.	Google Play 商店之所有內容均由 Google 提供，s/ash不會就任何內容之質量、性質、準確性和實用性而承擔任何責任。
          17.	s/ash有權隨時終止或更改本服務或本服務條款而不作另行通知。如有任何爭議，s/ash保留最終決定權。
          18.	Google Play 和 Google Play 標誌均為 Google 的商標。
          19.	本條款及細則的中、英文版如有任何歧義，概以中文版為準。

        `,
        isAnnex: true,
        size: '355KB',
        link: '/pdf/Google Play流動電訊商賬單付款服務條款及細則.pdf'
      },
      {
        id: 5,
        title: 'Apple服务流动电讯商账单付款服务条款及细则',
        content: `
          1. Apple 服務流動電訊商賬單付款服務 (「本服務」) 可供 s/ash 客戶支付於 Apple 服務購買之應用程式、遊戲、書籍或電影等的賬項。有關賬項將列入 s/ash 客戶的月結單內。客戶需要根據該月結單上所列出的金額付款。
          2. 本服務只適用於指定的 s/ash 之客戶。
          3. Apple 服務包括但不限於 Apple Store, Apple Music, Apple TV+ 及 iCloud 等。有關香港 Apple 服務詳情請瀏覽: <a href="https://www.apple.com/hk/">https://www.apple.com/hk/</a>
          4. s/ash 客戶必須年滿 18 嵗，否則本服務將不予提供。
          5. 新上台之 s/ash 客戶，最快於月費計劃服務生效翌日深夜 2 時正後可啟動本服務；攜號轉台客戶最快於成功攜號轉台後 2 小時可啟動本服務。
          6. 以下兩種情況的客戶無法使用賬單支付功能：1）非使用信用卡自動繳費，且新上台 1 個月或離合約期結束還剩 1 個月以内；2）客戶之賬單中有大於或等於兩期賬單未繳清。
          7. 客戶可以前往 MyLink 個人資料（點擊左上角頭像）-> 賬單付款等級中查看閣下的信用評級。
          8. 本服務對於每個評分等級都設置了一個月度消費限額，s/ash 賬單付款服務的消費額上限如下:
          <table border="1" cellspacing="0" cellpadding="0">
          <tbody>
            <tr>
              <td valign="top" width="264">
                <p><span>信用評級</span></p>
              </td>
              <td valign="top" width="265">
                <p><span>每月消費限額（</span><span>$HK</span><span>）</span></p>
              </td>
            </tr>
            <tr>
              <td valign="top" width="264">
                <p><span>AAAAA</span></p>
              </td>
              <td valign="top" width="265">
                <p><span>3,000</span></p>
              </td>
            </tr>
            <tr>
              <td valign="top" width="264">
                <p><span>AAAA</span></p>
              </td>
              <td valign="top" width="265">
                <p><span>2,000</span></p>
              </td>
            </tr>
            <tr>
              <td valign="top" width="264">
                <p><span>AAA</span></p>
              </td>
              <td valign="top" width="265">
                <p><span>1,500</span></p>
              </td>
            </tr>
            <tr>
              <td valign="top" width="264">
                <p><span>AA</span></p>
              </td>
              <td valign="top" width="265">
                <p><span>500</span></p>
              </td>
            </tr>
            <tr>
              <td valign="top" width="264">
                <p><span>A</span></p>
              </td>
              <td valign="top" width="265">
                <p><span>300</span></p>
              </td>
            </tr>
          </tbody>
        </table>
          以上評級和每月消費限制的審查取決於 s/ash 的決定。s/ash 對評級和每月消費限制的決定是最終決定，不得異議。
          9. s/ash 保留隨時更改和更新評級和每月消費限額的權利，而無需事先通知客戶。
          10. 本服務設有每月的消費上限，每月指從客戶的帳單月開始日至結束日為止。如果客戶的帳單月開始日是每個公曆月的第 6 天，結束日便是下個公曆月的第 5 天。如客戶的消費額超出每月上限，將不能繼續使用本服務支賬。
          11. 客戶下載任何 Apple 服務內容及使用其應用程式將產生數據用量。本地數據用量將按照情況從客戶現行使用的服務計劃扣除或按標準數據收取額外費用；於漫遊時使用本服務則須收取標準漫遊數據費用。
          12. 如客戶不是 s/ash 流動賬戶持有人，客戶必須事先取得持有人的許可，方可於其 s/ash賬戶內支付購物費用。
          13. 購物服務是由 Apple 提供，而有關 Apple 服務條款，包括退款政策，適用於有關購物服務。詳情請參考：：<a href="https://www.apple.com/hk/en/legal/internetservices/itunes/hk/terms.html">https://www.apple.com/hk/en/legal/internetservices/itunes/hk/terms.html</a>.瀏覽。
          14. 客戶需先用 iPhone 登記 Apple ID 帳戶，才能使用本服務。登記後，可以用同㇐Apple ID 帳戶登入 iPhone, iPad, Apple TV, Mac, 或 PC 裝置並使用本服務於 Apple服務購物。將來使用此帳戶購買的所有項目，都會計入客戶的流動電訊商賬單。
          15. s/ash 只提供本服務作付款方式，所有退款只可由應用程式開發商或 Apple 執行，客戶須要直接聯絡有關開發商或 Apple。所有退款要求均須按照 Apple 退款政策處理。詳情請參閱由 Apple 發出的電郵收據。
          16. 如對 Apple 服務購買的產品有任何查詢及投訴，請依照 Apple 發送的電郵收據內的支援指示處理。
          17. Apple 服務之所有內容均由 Apple 提供，s/ash 不會就任何內容之質量、性質、準確性和實用性而承擔任何責任。
          18. s/ash 有權隨時暫停、更改或終止本服務或更改本服務條款而不作另行通知。
          19. 如有任何爭議，s/ash 保留最終決定權。
          20. 本服務條款備有中、英文兩個版本。兩者如有歧異，概以英文版本為準。
        `,
        isAnnex: true,
        size: '790KB',
        link: '/pdf/Apple服務流動電訊商賬單付款服務條款及細則.pdf'
      },
      {
        id: 6,
        title: 'slash x 渣打信用卡推廣計劃條款及細則（活动已完结）',
        content: `
          1. 此推廣計劃之推廣期由 2023 年 12 月 1 日至 2024 年 3 月 31 日 (包括首尾兩日)(「推廣期」) 。
          2. 於優惠期內，使用指定渣打信用卡自動轉賬繳付月費及以指定優惠碼登記選用指定服務計劃並承諾最少使用服務期 12 個月之新客戶 (「合資格客戶」)可享一個月月費電子繳費券 (「繳費券」)。
          3. 「繳費券」將於合資格客戶成功啟用服務後的 180 個工作天內發放到客戶的 MyLink 賬戶，繳費券需透過 MyLink 手機應用程式繳費方可使用。
          4. 攜號轉台客戶須於簽約後 180 日內成功攜號轉台。如客戶攜號轉台失敗，或更改選用其他服務計劃，或於合約期內提前終止服務計劃，優惠將自動取消並有權向客戶收取已享用之優惠的總額而毋須事先通知。
          5. 上述 s/ash S/M/L 服務計劃的「繳費券」面值分別為$168/$248/$298。「繳費券」只可用於繳付服務計劃月費，不能兌換現金或兌換其他產品或服務或轉讓予其他人或實體。
            • s/ash (「s/ash」) 是中國移動香港有限公司 (「中國移動香港」) (牌照號碼: 002) 之電訊服務品牌。
            • s/ash 隨時更改或終止以上優惠內容的權利，而恕不另行通知。
            • 如有任何爭議，s/ash 保留一切最終決定權。
          借定唔借，還得到先好借!
          `,
        isAnnex: true,
        size: '224KB',
        link: '/pdf/slash x 渣打信用卡推廣計劃條款及細則.pdf'
      },
      {
        id: 7,
        title: 'slasher大使活動條款及細則（活动已完结）',
        content: `
        1. 活動由即日起至 2024 年 7 月 31 日結束，時間以主辦方（定義詳見下文）伺服器為準（「活動期」）。
        2. 參加者需分別符合下列條件方為活動的合資格參加者（「合資格參加者」）：
          <table border="1" cellspacing="0" cellpadding="0">
            <tbody>
              <tr>
                <th>
                  <p><span>合資格參加者</span></p>
                </th>
                <th >
                  <p><span>條件</span></p>
                </th>
              </tr>
              <tr>
                <td>
                  <p><span>(1)合資格s/asher 大使申請人</span></p>
                </td>
                <td>
                  <p><span>(a)可成功下載並註冊MyLink的人士；及
                  (b)非根據本條款及細則第15條被列入活動黑名單的人士。</span></p>
                </td>
              </tr>
              <tr>
                <td>
                  <p><span>(2)合資格被推薦人</span></p>
                </td>
                <td>
                  <p><span>(a)於s/ash網店成功上台s/ash並輸入s/asher大使優惠碼；
                  (b)可成功下載並註冊MyLink的人士；
                  (c)在參與活動當日用以參與活動的流動電話號碼並未有訂購任何s/ash服務計劃（「指定服務計劃」）；及
                  (d)非根據本條款及細則第15條被列入活動黑名單的人士。
                  </span></p>
                </td>
              </tr>
            </tbody>
          </table>
        *s/asher 大使及合資格被推薦人不能為同一人。
        若未能符合上述資格，相關活動參加及獲取資格將會被取消。

        3.	s/asher大使活動由中國移動香港有限公司(「主辦方」)主辦。
        4.	合資格s/asher 大使申請人透過填寫指定活動頁的報名表格後點擊提交，即可申請成為s/asher大使（「s/asher大使」），提交報名表格後，主辦方將審核有關申請並在不多於10個工作天內以Whatsapp或電郵的形式通知s/asher 大使申請人有關報名結果，審核通過的s/asher 大使申請人即可成為s/asher大使。
        5.	並成功推薦新上台客戶上台指定服務計劃可以獲取相應MyLink積分。
        6.	MyLink積分由MyLink應用程式（「MyLink」）運營及管理。s/asher大使需以活動頁登記表單填寫的電話號碼註冊並登入MyLink以獲取MyLink積分。任務詳情如下： 
        7.	每位合資格s/asher大使成功登記後會獲派專屬優惠碼，優惠碼以whatsapp或電郵派發。於活動期內，任何憑專屬優惠碼於s/ash網店成功上台指定s/ash S/M/L 服務計劃並承諾最少使用服務期12個月之新上台客戶(「被推薦人」)可享一個月月費電子繳費券 (「繳費券」)，繳費券需於合約期內使用。
        8.	繳費券將於被推薦人成功啟用服務後的180個工作天內發放到該被推薦人的MyLink賬戶，被推薦人需透過服務計劃的登記電話號碼登入MyLink手機應用程式繳費以使用該繳費劵。
        9.	被推薦人完成被推薦步驟後可獲得的繳費券詳情如下：
        <table border="1" cellspacing="0" cellpadding="0">
            <tbody>
              <tr>
                <td >
                  <p><span>被推薦人選用的指定服務計劃</span></p>
                </td>
                <td >
                  <p><span>S/30GB</span></p>
                </td>
                 <td >
                  <p><span>M/50GB</span></p>
                </td>
                 <td >
                  <p><span>L/100GB</span></p>
                </td>
              </tr>
              <tr>
                <td>
                  <p><span>可獲繳費券面值</span></p>
                </td>
                <td >
                  <p><span>$168</span></p>
                </td>
                 <td >
                  <p><span>$248</span></p>
                </td>
                 <td >
                  <p><span>$298</span></p>
                </td>
              </tr>
            </tbody>
          </table>
        10.	繳費券只可用於繳付服務計劃月費，不能兌換現金或兌換其他產品或服務或轉讓予其他人。
        11.	s/asher大使每成功推薦一位被推薦人（根據條款5成功上台並連續使用服務計劃120日）後，s/asher大使可根據被推薦人選用的服務計劃，獲贈相應的MyLink積分，詳見下表：
        <table border="1" cellspacing="0" cellpadding="0">
          <tbody>
            <tr>
              <td >
                <p><span>被推薦人選用的服務計劃</span></p>
              </td>
              <td >
                <p><span>S/30GB</span></p>
              </td>
              <td >
                <p><span>M/50GB</span></p>
              </td>
              <td >
                <p><span>L/100GB</span></p>
              </td>
            </tr>
            <tr>
              <td>
                <p><span>被推薦人申請指定服務計劃當天年齡為18-29歲</span></p>
              </td>
              <td >
                <p><span>21,000 MyLink 積分（相等於$ 210價值）</span></p>
              </td>
              <td >
                <p><span>31,000 MyLink 積分（相等於$ 310價值）</span></p>
              </td>
              <td >
                <p><span>37,000 MyLink 積分（相等於$ 370價值）</span></p>
              </td>
            </tr>
            <tr>
              <td >
                <p><span>被推薦人申請指定服務計劃當天年齡為30歲或以上客戶</span></p>
              </td>
              <td >
                <p><span>28,000 MyLink 積分（相等於$ 280價值）</span></p>
              </td>
              <td >
                <p><span>41,000 MyLink 積分（相等於$ 410價值）</span></p>
              </td>
              <td >
                <p><span>50,000 MyLink 積分（相等於$ 500價值） </span></p>
              </td>
            </tr>
          </tbody>
        </table>
        12.	指定服務計劃受相關條款及細則約束，詳情請瀏覽<a href="https://bit.ly/3tEuvDC">https://bit.ly/3tEuvDC</a>。
        13.	MyLink積分將於被推薦人所訂購的指定服務計劃生效後的180個工作天內派發至s/asher大使的MyLink賬戶內。
        14.	獲贈的MyLink積分及繳費券統稱為「本活動禮品」。
        15.	若被推薦人為攜號轉台客戶，其須於簽約後180日內成功攜號轉台。如其攜號轉台失敗，或更改選用其他服務計劃，或於合約期內提前終止指定服務計劃，本活動禮品將自動取消而主辦方有權向該被推薦人及推薦其訂購指定服務的s/asher 大使收取已享用之優惠總額而毋須事先通知。
        16.	參加者獲得MyLink積分及/或繳費券後，不可退換或兌換現金，亦不能更換、退款、出售、分拆、轉贈或轉讓他人，不同MyLink賬戶的MyLink積分及/或繳費券亦不可合併使用。當s/asher大使終止使用其MyLink賬戶時，其MyLink賬戶內的MyLink積分及/或繳費券將會自動註銷，主辦方亦不會作出任何補償。
        
        <b>活動免責條款</b>
        17.	如主辦方懷疑任何合資格參加者有（i）進行或與異常/可疑活動有關；或（ii）使用不當的方法或欺詐行為而使活動之任何部份運作中斷或受到干擾，或導致技術問題；或檢測或發現到（iii）任何不誠實的行為、提供虛假、錯誤或不正確的資料或（iv）任何違反本條款及細則或其他任何適用法律或法例條文或任何監管規定；或（v）合資格參加者有任何非善意或惡意使用任何相關服務之情況；主辦方擁有酌情權及最終決定權在不作任何通知及毋須對任何人士負上任何責任的情況下，自行決定暫停、取消其參與活動或獲得本活動禮品及/或參加日後推廣活動的資格，並對該人士保留追究權利，並列入活動黑名單。
        18.	如因電力或網絡故障、或其他主辦方不能控制的情況下任何服務失效、中斷、延誤或暫時中止所引致之損失及損害（不論直接或間接），例如因任何技術故障、困難或錯誤、網絡故障、中斷或擠塞、手機程式故障、任何第三方引致之原因、軟體的不相容性問題或其他任何原因（包括但不限於任何終端設備或互聯網網絡問題）而產生或引致任何遲延、遺失、錯誤或無法辨識的回應等情況、或未能參與活動或使用本活動禮品，主辦方概不負責及不會作出任何補發或補償。
        19.	如任何合資格參加者有任何違反法律、本條款及細則或可能損害第三方權利的行為或不作為，該合資格參加者同意並契諾向主辦方為其行為或不作為引致或相關的任何糾紛及索賠作出全面彌償以及使主辦方免受損害。
        20.	合資格參加者參與活動及/或領取本活動禮品純屬自願性質及需自行承擔參與活動及/或領取本活動禮品之任何風險及全部責任。任何合資格參加者因參與本活動及/或領取本活動禮品而引致之任何損失、傷害、費用、糾紛、索賠、訴訟（包括但不限於直接、間接及後果性損失），主辦方概不負上任何法律責任或作出賠償。
        21.	凡由於主辦方無法合理控制或並非其過失或疏忽所致的原因，包括但不限於電力故障、戰爭、面臨戰爭威脅、騷亂或其他公民抗命行為、暴動、天災、由政府或任何其他國家法律機關所實施的限制或任何其他工業或行業糾紛、火災、爆炸、風暴、水災、雷電、地震及其他自然災害，以致延遲或未能履行本條款及細則的全部或部分條款而造成任何損失或損害，主辦方均無須負責。
        22.	活動所獲贈、購買和兌換的所有商品、服務、禮品或優惠均受到相關商品或優惠條款所限。

        <b>MyLink 積分使用及注意事項</b>
        23.	活動期内，合資格參加者所獲得MyLink積分會存入其MyLink賬戶。MyLink積分餘額顯示在「頭像 – 我的積分」頁面。
        24.	所有MyLink 積分均有使用有效期（「積分有效期」）。積分有效期取決於積分被存入用戶的MyLink賬戶之時間。如活動領取的MyLink積分與任何其他MyLink積分領取活動的條款細則存有差異，一律以MyLink賬戶中所顯示的有效積分為準。
        25.	合資格參加者應於積分有效期滿前使用積分作網上繳費、儲值卡增值及或兌換獎賞或購買指定產品/服務，逾期作廢，並將不獲補發或延長有效期，兌換MyLink積分須參閲並同意有關MyLink積分計劃的條款與細則，詳情請瀏覽: <a href="https://bit.ly/3SOvkUm">https://bit.ly/3SOvkUm</a>。
        26.	主辦方保留全部的酌情權及決定權處理合資格參加者的MyLink積分。在下列情況下MyLink可暫停發放MyLink積分或隨時從任何合資格參加者的MyLink賬戶中扣除MyLink積分而不作另行通知（包括但不限於）：(a)在合資格參加者欺詐或涉嫌欺詐的情況下；(b) 合資格參加者以不公平手段（包括但不限於不誠實使用、欺詐、錯用或濫用）而獲得MyLink積分；(c)用戶取消、撤銷任何符合領取MyLink積分的合資格服務或流動電話號碼服務；(d)主辦方或任何服務提供者錯誤計算合資格參加者應獲取的MyLink積分；及/或(e)任何主辦方合理地確保合資格參加者正當地積分的情況下。

        <b>繳費券使用及注意事項</b>
        27.	活動期内，合資格參加者所獲得繳費券會存入其MyLink賬戶。繳費券顯示在「頭像 – 我的電子券」頁面。
        28.	所有繳費券均有使用有效期（「繳費券有效期」）。繳費券有效期取決於繳費券被存入用戶的MyLink賬戶之時間。如活動領取的繳費券與任何其他繳費券領取活動的條款細則存有差異，一律以MyLink賬戶中所顯示的有效繳費券為準。
        29.	合資格參加者應於繳費券有效期滿前使用繳費券繳付服務計劃月費，逾期作廢，並將不獲補發或延長有效期。
        30.	主辦方保留全部的酌情權及決定權處理合資格參加者的繳費券。在下列情況下主辦方可暫停發放繳費券或隨時從任何合資格參加者的MyLink賬戶中扣除繳費券而不作另行通知（包括但不限於）：(a)在合資格參加者欺詐或涉嫌欺詐的情況下；(b) 合資格參加者以不公平手段（包括但不限於不誠實使用、欺詐、錯用或濫用）而獲得繳費券；(c)用戶取消、撤銷任何符合領取繳費券的合資格服務或流動電話號碼服務；(d)主辦方或任何服務提供者錯誤計算合資格參加者應獲取的繳費券；及/或(e)任何主辦方合理地確保合資格參加者正當地使用繳費券的情況下。

        <b>個人資料收集聲明</b>
        31.	合資格參加者參與本活動即同意及接受主辦方收集、使用和披露其個人資料用於活動之行政及與活動一切相關用途，以及其他在一般個人資料收集聲明和私隱政策聲明中列出的推廣和宣傳用途。合資格參加者已細閱、明白及同意主辦方之一般個人資料收集聲明及私隱政策聲明所載之內容，並同意接受其約束。上述聲明分別載於︰ <a href="https://bit.ly/3S4Ba39">https://bit.ly/3S4Ba39</a>  及 <a href="https://bit.ly/4aIIhWo">https://bit.ly/4aIIhWo</a> 。
        32.	參加者可能會被要求向主辦方提供個人識別資料。為讓參加者可以參與活動及/或領取本活動禮品，該些資料是必要的。倘若參加者未能提供所需資料，參加者將不能參與活動及/或領取本活動禮品。
        33.	上述聲明乃遵照《個人資料(私隱)條例》(第486章)的要求而發出，通知參加者收集資料的目的，及參加者同意主辦方使用這些資料及其權利。參加者有權根據《個人資料（私隱）條例》的規定，要求查閱或更正參加者之個人資料。如有任何查閱或更正資料的要求，請參閱主辦方的私隱政策聲明之詳情。
        34.	參加者如欲查閱個人資料及更正個人資料或詢問個人資料有關聲明，請致函：中國移動香港有限公司，地址：香港新界葵涌葵昌路51 號九龍貿易中心1 座20 樓，收信人：資料保護主任；或致電主辦方拒收商業電子訊息登記熱線6226 4926 或客戶服務熱線12580。

        <b>其他注意事項</b>
        35.	每個香港流動電話號碼只限參加活動一次。如發現用戶以外掛程式或其他非正式途徑，包括但不限於空號或假賬戶或其他影響公平性的方式參與本活動，主辦方將取消該合資格參加者的參與資格而不作另行通知。
        36.	如任何人士曾經在主辦方的MyLink及主辦方網上商店的活動、或任何使用MyLink及主辦方網上商店的過程中作出不誠實及欺詐行為、或任何非善意或非正常之使用、或曾經違反主辦方任何活動之條款及細則之行為，將自動被列入活動黑名單，喪失參與的資格。
        37.	如合資格參加者因任何主辦方未能控制之因素以致所遞交之個人資料有錯漏、不實或不正確，或導致主辦方未能聯絡或核實合資格參加者身份，即視為放棄參與活動及獲得本活動禮品資格。合資格參加者不得要求補發或任何形式的補償，主辦方亦毋須為此承擔任何責任。
        38.	主辦方之員工（包括全職及兼職員工或以合約形式聘用的人士）不得參與活動，以示公允。
        39.	本條款及細則，包括成立、有效性、解釋、履行、簽署、修訂、終止、解決爭議、以及一切與本條款及細則相關的事項均應受香港法律專屬管轄並執行。所有參加者不可撤回並毫無保留地同意任何因本條款及細則所引起的任何分歧、糾紛、訴訟或索賠受香港法院的專屬司法管轄權管轄。
        40.	本條款及細則中的每一條條款應有獨立的執行力，其有效性不受其他條款之無效性影響。
        41.	除合資格參加者和主辦方外，任何人均不享有香港法例《合約(第三者權利)條例》下的任何權利，以執行或享有本條款及細則任何條文的利益或權利。
        42.	主辦方保留隨時更改活動內容、終止活動、及/或修訂本條款及細則的權利，而毋須事先通知。任何有關活動的臨時改動或取消，將以主辦方的公佈為確據。
        43.	活動不設上訴機制。如有任何爭議，主辦方保留最終決定權。任何爭議將以主辦方之系統記錄作為確據。
        44.	s/ash (「s/ash」) 是主辦方 (綜合傳送者牌照號碼: 002) 之電訊服務品牌。
        45.	本條款及細則的中英文本如有任何歧異，概以中文本為準。
        `,
        isAnnex: true,
        size: '489KB',
        link: '/pdf/slasher大使活動條款及細則.pdf'
      },
      {
        id: 8,
        title: '全民免月費 活動細則（活动已完结）',
        content: `
        全民免月費 - 免5優惠 (只適用於18-29歲客戶)
        指定客戶免費用5個月優惠(「免5優惠」)  只適用於選用指定s/ash 服務計劃並簽約12個月或以上之新客戶（「合資格客戶」）。優惠只適用於辦理開台當時為18-29歲之客戶。合資格客戶12個月服務承諾期中的5個月之月費可獲豁免。月費豁免將於第2、4、6、8及10期賬單體現。如客戶更改選用其他服務計劃，或於合約期內提前終止服務計劃，優惠將自動取消。s/ash有權向客戶收取已享用之優惠的總額而毋須事先通知。本優惠期有效期直至另行通知。 受條款及細則約束。

        全民免月費 - 免3優惠 (適用於30歲或以上客戶)
        指定客戶免費用3個月優惠(「優惠」)  只適用於選用指定s/ash 服務計劃並簽約至少12個月之新客戶（「合資格客戶」）。合資格客戶12個月服務承諾期中的3個月之月費可獲豁免。月費豁免將於第4、6、10期賬單體現。如客戶更改選用其他服務計劃，或於合約期內提前終止服務計劃，優惠將自動取消。 s/ash有權向客戶收取已享用之優惠的總額而毋須事先通知。本優惠期有效期直至另行通知。受條款及細則約束。
        `,
        isAnnex: true,
        size: '508KB',
        link: '/pdf/全民免月費 活動細則.pdf'
      }
      // {
      //   id: 8,
      //   title: 'slash x JOOX 推廣計劃之條款及細則',
      //   content: `
      //   1. 此推廣計劃之推廣期由即日起直至另行通知 (「推廣期」) 。
      //   2. 於推廣期內，選用指定s/ash S/M/L 服務計劃並承諾最少使用服務期12個月之新上台客戶(「合資格客戶」)可享「JOOX音樂VIP」增值服務月費豁免。選用指定s/ash S服務計劃並簽約12個月之新客戶，合約期內可享5個月免費「JOOX音樂VIP」增值服務; 選用指定s/ash M服務計劃並簽約12個月之新客戶，合約期內可享8個月免費「JOOX音樂VIP」增值服務; 選用指定s/ash L服務計劃並簽約12個月之新客戶，合約期內可享10個月免費「JOOX音樂VIP」增值服務。「JOOX音樂VIP」增值服務將於第1期賬單開始贈送，當有效期結束後將自動取消。 受條款及細則約束，詳情請瀏覽：<a href="https://bit.ly/3xOtmvi">https://bit.ly/3xOtmvi</a>。
      //   3. 於推廣期內，選用指定s/ash S/M/L服務計劃並承諾最少使用服務期12個月之新上台客戶(「合資格客戶」)可享合約期內「視頻接駁鈴聲」增值服務月費豁免。免費「視頻接駁鈴聲」增值服務將於第1期賬單開始贈送，有效期為12個月，當有效期結束後將自動取消。用戶知悉並同意視頻接駁鈴聲服務同時受中國移動香港視頻接駁鈴聲業務服務協定、自動續費服務協議及中國移動視頻彩鈴業務服務協議約束，詳情請瀏覽：<a href="https://bit.ly/3LoqOHb">https://bit.ly/3LoqOHb</a>,<a href="https://bit.ly/3XXyBTT">https://bit.ly/3XXyBTT</a>及<a href="https://bit.ly/4cUr8ZY">https://bit.ly/4cUr8ZY</a>
      //   4. 攜號轉台客戶須於簽約後180日內成功攜號轉台。如客戶攜號轉台失敗，或更改選用其他服務計劃，或於合約期內提前終止服務計劃，優惠將自動取消並有權向客戶收取已享用之優惠的總額而毋須事先通知。
      //   • s/ash (「s/ash」) 是中國移動香港有限公司 (「中國移動香港」) (牌照號碼: 002) 之電訊服務品牌。
      //   • s/ash隨時更改或終止以上優惠內容的權利，而恕不另行通知。
      //   • 如有任何爭議，s/ash保留一切最終決定權。
      //   `,
      //   isAnnex: true,
      //   size: '233KB',
      //   link: '/pdf/slash x JOOX 推廣計劃之條款及細則.pdf'
      // }
    ]
  },
  sc_jp: {
    seoH1: '渣打银行信用卡客户尊享5G月费计划上台优惠',
    seoH2: '"渣打信用卡客户有福啦! 额外免1个月月费 + Smart卡兼享高达5%现金回赠"',
    seoH3: '12个月短约 / 免$18行政费',
    渣打信用卡客户专享: '渣打信用卡客户尊享',
    额外免1个月月费: '额外免1个月月费 + Smart卡',
    兼享高达: '兼享高达5%',
    '5%现金回赠': '现金回赠',
    个月短约: '个月短约',
    免: '免',
    行政费: '行政费',
    渣打信用卡客户有福啦: '渣打信用卡客户有福啦!',
    '输入优惠码、并用渣打信用卡缴纳月费和设立自动转账，以下优惠即到手': '输入优惠码、并用渣打信用卡缴纳月费，以下优惠即到手！',
    复制: '复制',
    优惠: '优惠',
    渣打信用卡客户上台专享额外免1个月月费: '渣打信用卡客户上台尊享额外免1个月月费!',
    '「Smart卡5%丰厚现金回赠」': '「Smart卡高达5%现金回赠」',
    '有关渣打Smart卡详情，请': '有关渣打Smart卡详情，请',
    按此: '按此',
    活动介绍详情:
      '以渣打Smart卡缴交s/ash月费即享高达5%现金回赠！毋须登记，不设最低消费额，无指定日子。特约日常消费商户，尽享丰厚现金回赠。',
    即刻扫码登记: '即刻扫码登记',
    条款及细则: '条款及细则',
    渣打银行卡推广计划之条款及细则: '渣打信用卡推广计划之条款及细则',
    '借定唔借？还得到先好借！': '借定唔借？还得到先好借！',
    立即开台: '立即以优惠码开台',
    复制成功: '复制成功',
    复制失败: '复制失败',
    套餐: imgs['sc_jp/plan_sc.png'],
    code: imgs['sc_jp/code_sc.png']
  },
  ambassador_recruitment: {
    seoH1: 's/asher大使招募进行中！',
    seoH3: '邀请朋友上台每次获取最多价值$500积分/ 专属礼品/ 第一时间内部优惠',
    item1: imgs['ambassador_recruitment/sc/item1.png'],
    item2_1: imgs['ambassador_recruitment/sc/item2_1.png'],
    item2_2: imgs['ambassador_recruitment/sc/item2_2.png'],
    item2_3: imgs['ambassador_recruitment/sc/item2_3.png'],
    item2_4: imgs['ambassador_recruitment/sc/item2_4.png'],
    item3: imgs['ambassador_recruitment/sc/item3.png'],
    item1_phone: imgs['ambassador_recruitment/sc/item1_phone.png'],
    item2_1_phone: imgs['ambassador_recruitment/sc/item2_1_phone.png'],
    item2_2_phone: imgs['ambassador_recruitment/sc/item2_2_phone.png'],
    item2_3_phone: imgs['ambassador_recruitment/sc/item2_3_phone.png'],
    item2_4_phone: imgs['ambassador_recruitment/sc/item2_4_phone.png'],
    item3_phone: imgs['ambassador_recruitment/sc/item3_phone.png'],
    主办方有针对福利发放的最终决定权: '*主办方有针对福利发放的最终决定权',
    '立即成为s/asher大使': '立即成为s/asher大使',
    '赚积分* 享福利 换礼品': '赚积分* 享福利 换礼品',
    立即报名: '立即报名',
    什麼是slasher大使:
      '什么是slasher大使？slasher是由s/ash 5G电讯品牌举办的香港年青人、大学生的KOL活动，提供一系列专属福利与精彩活动，支持年青人。',
    邀请朋友上台:
      '邀请朋友上台，或由SmarTone、csl、3HK等电讯公司携号转台slash电话卡可获得MyLink积分，积分可以兑换机票兑换码、超市优惠券、电子产品、美妆产品等。',
    slasher大使:
      'slasher大使邀请好友获得相应的MyLink积分，被推荐人可以获得一个月电话plan月费优惠。slasher大使与好友都可以在MyLink中，使用积分可交手机月费、兑换机票兑换码、超市优惠券、电子产品、美妆产品等。',
    就算你是: '就算你是SmarTone、csl、Birdie、CMHK、3HK、HKBN、1010、中国联通的客户，你也可以成为slasher大使。',
    如果你报名成功: '如果你报名成功，我们会在五个工作日内通过电邮或者Whatsapp向你发送专属优惠码',
    姓名: '姓名',
    出生年月: '出生年月',
    联络电话: '联络电话',
    邮箱: '邮箱',
    在哪里认识我们: '在哪里认识我们',
    '同意/不同意接受我们的资讯': `本人同意s/ash透过电邮、手机短信或其他即时通讯软件向本人发送有关s/ash的推广讯息。本人提供以上个人资料亦知悉并同意中国移动香港之<a target="_blank" href="https://www.hk.chinamobile.com/sc/pics.html">一般个人资料收集声明</a>及<a target="_blank" href="https://www.hk.chinamobile.com/sc/privacy.html">私隐政策</a>声明所载之内容，并接受其约束`,
    搜索引擎: '搜索引擎',
    社交媒体: '社交媒体',
    网络广告: '网络广告',
    亲友推荐: '亲友推荐',
    其他: '其他',
    请输入姓名: '请输入姓名',
    姓名请输入正确格式: '请输入正确格式，不允许输入特殊字符，最多可输入20个文本字符',
    '如2000/01': '如2000/01',
    请选择出生年月: '请选择出生年月',
    请输入正确格式: '请输入正确格式',
    请输入正确手机号码: '请输入正确手机号码',
    请输入正确电子邮箱: "请输入正确电子邮箱，如用户名{'@'}域名",
    如亲友推荐: '如亲友推荐',
    请选择推荐渠道: '请选择推荐渠道',
    请勾选所需资料: '请勾选所需资料。',
    提交: '提交',
    未开始: '活动未开始',
    已结束: '活动已结束',
    提交成功: '提交成功，我哋会喺7个工作天内以电邮或Whatsapp联络你与你跟进。',
    提交失败: '提交失败，请稍后再试',
    条款及细则: '条款及细则',
    活动条款内容: `
      有关s/asher大使的条款及细则, 请参阅<a target="_blank" href='/pdf/slasher大使活動條款及細則.pdf'>slasher大使活动条款及细则</a>
    `
  },
  business_collaboration: {
    商业合作: '商业合作',
    填写简单表单:
      '我地欢迎任何形式慨合作，\n共同提供更多服务与礼遇予年青人，\n请填写以下表格联络我地，\n我地会尽快回覆你。我们尽快回复您。',
    slash介绍: 's/ash是一个应援年青人的5G通讯生活品牌。如果你有兴趣与我们一起举办联乘活动、推出独家优惠与礼遇等，',
    '欢迎即刻通过下列方式联络我们！': '欢迎即刻通过下列方式联络我们！',
    成为我们的合作伙伴: '成为我们的合作伙伴',
    联络人姓名: '联络人姓名',
    电邮: '电邮',
    电话: '电话',
    '公司/机构名称': '公司/机构名称',
    讯息: '讯息',
    提交申请: '提交申请',
    或者透过email联系我们: '或者透过email联系我们',
    点击联系: '点击联系',
    请输入联络人姓名: '请输入联络人姓名',
    请输入邮箱地址: '请输入邮箱地址',
    请输入正确电子邮箱: `请输入正确电子邮箱，如用户名{'@'}域名`,
    请输入联络电话: '请输入联络电话',
    请输入正确手机号码: '请输入正确手机号码',
    请输入公司名称: '请输入公司/机构名称',
    请输入讯息: '请输入讯息',
    请输入正确的格式: '请输入正确格式，',
    最多可输入100个字符: '最多可输入100个字符',
    最多可输入50个字符: '最多可输入50个字符',
    提交成功: '提交成功，我们会尽快回复你。',
    提交失败: '提交失败，请稍后再试'
  },
  joox_jp: {
    alt: '全新5G电讯生活品牌slash联合JOOX推出乐迷优惠。现在上5G月费计划即送长达10个月JOOX音乐VIP服务及12个月免费视频接驳铃声服务。',
    h2Seo: '上台即送长达10个月JOOX VIP（价值$68/月）+ 12个月免费视频接驳铃声服务（价值$15/月）',
    h3Seo: '12个月短约 / 免$18行政费',
    banner_mobile: imgs['joox_jp/sc/banner_mobile.png'],
    song: imgs['joox_jp/sc/song.png'],
    table_mobile: imgs['joox_jp/sc/table-mobile.png'],
    table_pc: imgs['joox_jp/sc/table-pc.png'],
    title02: imgs['joox_jp/sc/title02.png'],
    's/ash X JOOX 乐迷专享优惠': 's/ash X JOOX 乐迷专享优惠',
    上台即送: '上台即送:',
    长达10个月: '长达10个月',
    'JOOX VIP': ' JOOX VIP （价值$68/月）',
    '12个月免费视频接驳铃声服务': '+ 12个月免费视频接驳铃声服务（价值$15/月）',
    个月短合约: '个月短合约',
    免: '免',
    行政费: '行政费',
    '透过本页面登记s/ash服务计划，': '透过本页面登记s/ash服务计划，',
    '透过本页面登记s/ash服务计划后': '',
    JOOX乐迷专属丰厚优惠: 'JOOX乐迷专属丰厚优惠',
    配合极速5G网络: ' 配合极速5G网络，随时随地享受无拘束极致音乐体验',
    送最多10个月: '送最多10个月',
    权益: '权益!',
    '9,000万首歌库': '9,000万首歌库',
    超过九千万歌库新歌经典一应俱全: '超过九千万歌库新歌经典一应俱全，每日更新',
    离线听歌: '离线听歌',
    随意下载并离线收听珍藏喜欢的歌曲: '随意下载并离线收听珍藏喜欢的歌曲，随时欣赏',
    'Hi-Fi无损音质': 'Hi-Fi无损音质',
    '畅享Hi-Fi无损音质歌曲，纯净通透': '畅享Hi-Fi无损音质歌曲，纯净通透',
    听歌无阻: '听歌无阻',
    随心点播自由切歌: '随心点播，自由切歌, 无广告纯粹、无打扰的音乐体验',
    轻松激活: '轻松激活',
    权益四步曲: '权益四步曲',
    JOOX音乐VIP服务生效后: 'JOOX音乐VIP服务生效后,客户将收到确认短讯,客户需复制短讯中兑换码以启动服务✉️',
    下载并开启: '下载并开启JOOX应用程式📱',
    '注册/登入JOOX账户': '注册/登入JOOX账户🎶',
    进入应用程式后: '进入应用程式后,点击账户>会员中心>兑换码>输入兑换码>立即兑换,确认完成后,该账户即成为JOOX VIP🎉',
    送12个月免费视频接驳铃声服务: '送12个月免费视频接驳铃声服务',
    用户开通视频接驳铃声服务后:
      '用户开通视频接驳铃声服务后，当有人致电用户本人时，对方可于等待接听期间，观看到用户设置的个性化趣味短视频，不需要藉助任何APP，不消耗双方数据流量！了解有关视频接驳铃声更多详情，👉',
    请按此: '请按此',
    条款及细则: '条款及细则',
    's/ash x JOOX 推广计划之条款及细则': '《s/ash x JOOX 推广计划之条款及细则》',
    即扫登记: '即扫登记',
    立即开台: '立即开台'
  },
  error: {
    呢個頁面目前有啲問題: '哎呀/// \n呢个页面目前有啲问题...',
    '你可以：': '你可以：',
    返首页: '返首页',
    認識下我地: '认识下我地',
    Whatsapp我哋銷售專員: 'Whatsapp我哋销售专员'
  }
}
export default sc
