import { App } from 'vue'

const source = import.meta.glob('./**/*',{eager: true})
const imgs: Record<string, any> = {}
Object.entries(source).forEach(([key, module]) => {
  imgs[key.replace('./', '')] = module.default
})
export { imgs }

export function install(app: App) {
  app.config.globalProperties.$imgs = imgs
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $imgs: typeof imgs
  }
}
