import tc from './tc'
import { imgs } from '../assets/imgs/index'
const en: typeof tc = {
  lang: 'en',
  活动结束: 'The campaign has ended, thank you for your support!',
  活动未开始: 'The program is about to start，stay tuned!',
  我知道了: 'Get it',
  Header: {
    首页: 'Home',
    's/ash，以CMHK中國移動':
      "Supported by China Mobile CMHK's 5G network, s/ash offers discounted service plan for students, young generation and the public in Hong Kong.",
    公告: 'Since LiveCHAT system is under maintenance, the service maybe interrupted. Please try again later, or contact customer service hotline at 12580. We apologize for any inconvenience caused.',
    '5G月费计划': '5G Monthly Plan',
    手游必備礼遇: 'Gaming Essentials',
    '认识s/ash': 'About s/ash',
    '咩系s/ash': "what's s/ash",
    品牌介绍: 'Brand',
    媒体报导: 'Media Coverage',
    网络实测: 'Network Testing',
    最新着数: 'Latest Offer',
    渣打信用卡客户专享礼遇: 'Exclusive Offers for Standard Chartered Credit Cardholders',
    免费拎100GB: 'Get 100GB for free',
    上台Tips: 'Tips',
    转台教学: 'Porting Guide',
    帮助中心: 'Help Center',
    繁: 'TC',
    简: 'SC',
    登记: 'Subscribe',
    's/asher大使': 's/asher ambassador',
    joox_jp: 's/ash x JOOX Special Offer for Music Fans'
  },
  Footer: {
    chat: imgs['footer/chat-en.png'],
    即時chat: 'Feel free to engage in live chat for detailed information on s/ash service plan, SIM cards and 5G plan offers.',
    '认识s/ash': 'About s/ash',
    媒体报导: 'Media Coverage',
    订单查询: 'Order Enquiry',
    帮助中心: 'Help Center',
    商业合作: 'Business Opportunities',
    条款及细则: 'Terms & Conditions',
    版权: '© 2023 s/ash. All rights reserved.',
    联络我们: 'Contact Us'
  },
  Index: {
    // banner
    banner备注:
      '*After the local data usage exceeds 30GB per month, the local data service can still continue, but the transmission speed will be up to 1Mbps and is not applicable for tethering and peer-to-peer (P2P) functions (including Bit-Torrent, etc.).  • The above services and offers are subject to terms and conditions.',
    '全民免月费最多5个月！': 'Youth support special price Monthly fee as low as $98!',
    马上登记: 'Register Now',
    '12个月短约': ' month flexible contract period',
    学生: 'Exclusive for students',
    免最多: ' and ',
    年青人: 'young people',
    个月月费: ' aged 18-29',
    免: 'Waive ',
    行政费: '/month admin fee for port-in numbers',
    '/ 属于年青人的': '/ The 5G Telecommunication Service',
    '5G通讯服务': ' Dedicated to the Youth',
    全方位应援你日常通讯与生活: 'Comprehensive Support for Your Everyday Communication and Lifestyle',
    立即登记: 'Register Now',
    // 优势
    '无限数据放心用(1)': '<h3><span class="blue">Unlimited data</span> supporting your carefree usage</h3>',
    生活可能有好多限制: `Life is already filled with countless constraints\nDon't let Internet access be another limitation`,
    旅行漫游想走就走: '<h3><span class="blue">Roaming service supporting your spontaneous trip</span></h3>',
    灵活加购漫游数据: 'We offer you roaming data as a bonus\nA single SIM card is all you need to explore the world',
    年青人出機必備: '<h3><span class="blue">Great deal for Handset Upgrade</span></h3>',
    享5G手机奖赏热门品牌5G手机出机无忧: 'Enjoy 5G Handset Rewards\nWorry-free purchase of popular brand 5G Handsets',
    // 三个计划开始
    '还在选择学生Plan或5G高价Plan?': 'Still using an expensive 5G Plan? Young people have their own 5G Top-Notch Plan!',
    's/ash为你提供简单3个计划':
      'We provide you with<span class="blue">  3 Local Service Plans and 1 Mainland China and Hong Kong Service Plan </span><br/>Abundant data, budget-friendly price! Newcomers can enjoy a series of selected benefits supporting young people!<br/>Choose a plan that suits you best',
    全城最划算: 'Best Price',
    起: 'From ',
    '起/月': '/month',
    '/月': '/month',
    原价: 'Original Price: ',
    其后1Mbps高速任用: '+ 1Mbps Unlimited thereafter local data ',
    '送2GB中国内地、澳门漫游数据(+)': 'Free 3GB/month Mainland China and Macau data',
    '18-29岁免5个月月费': 'Unlimited data usage for 15 frequnetly used Apps (Value: $38/month)',
    '(折后$98)': '(avg: $98)',
    '攜號轉台免$18/月行政費': 'Waive $18/month admin fee for port-in numbers',
    '(折后$126)': ' (avg: $126)',
    个月合约期: ' month contract period',
    了解更多: 'Learn more',
    CP值最高: 'Highest CP value',
    其后5Mbps高速任用: '+ 5Mbps Unlimited thereafter local data',
    '送4GB中国内地、澳门漫游数据(+)': 'Free 4GB/month Mainland China and Macau data ',
    '(折后$145)': '(avg: $145)',
    '(折后$186)': ' (avg: $186)',
    高用量之选: 'Pick of High Usage',
    '送6GB中国内地、澳门漫游数据(+)': 'Free 6GB/month Mainland China and Macau data ',
    ' / 送15个常用App数据任用 (价值$38/月)': 'Unlimited data usage for 15 frequnetly used Apps (Value: $38/month)',
    '(折后$174)': '(avg: $174)',
    '(折后$224)': '(avg: $224)',
    '享$1,200 5G手機獎賞（24個月合約': '$1,200 5G Handset Rewards (24 month contract required)',
    '本地服務計劃 （學生及18-29歲年青人限定）': 'Local Service Plan\n(Exclusive for students and 18-29 years old young people)',
    '一咭兩地服務計劃（18-29歲年青人限定）': 'Mainland China and Hong Kong Service Plan\n(Exclusive for 18-29 years old young people)',
    北上必選: 'Must-have for frequent travellers to Mainland China',
    '中國内地及香港共用數據 + 其後本地高達1Mbps': ' Mainland China and Hong Kong data',
    '+ 其後本地高達1Mbps': '+  1Mbps Unlimited thereafter local data',
    含200分鐘中國内地通話分鐘: '200 roaming call minutes in Mainland China',
    '$9/月加購15個常用App本地數據任用權益（價值$38/月':
      'Subscribe at $9/month for Unlimited data usage for 15 frequnetly used Apps (Value: $38/month)',
    '送「中國內地號碼服務」或「關聯家鄉號」（二選一優惠）':
      'Free Mainland China “1-Card-Multi-Number” or " CM Numbers Link"  (choose one of the two offers)',
    // 全方位考虑你的需要
    '／全心考虑你的需要': '<h2><span class="blue">／</span>Catering<span class="blue"> to Your Needs</span></h2>',
    优惠月费: 'Discounted Monthly Fees',
    极速网络: 'Lightning-Fast Network Speeds',
    贴心服务: 'Attentive Customer Care',
    '／考虑你的预算': '/ Care for your Budget',
    '／考虑你的通讯需要': '/ Care for your Communication Needs',
    '／考虑你的心情': '/ Care for your Mood',
    为你提供比其他网络商: 'Experience a superior offering compared to other operators: ',
    更抵的价钱: '<span class="red">Better </span>prices',
    更快的网络: '<span class="red">faster </span>network',
    更多的数据: '<span class="red">and more </span>data',
    '5G月费计划低至': '5G Monthly Plan start at ',
    明白你不想再Load了个寂寞或者最怕月尾用光数据: `We understand your frustration with the slow loading speed and the fear of running out of data before the month ends. `,
    只为你提供: `That's why we provide you with`,
    '【最优秀的5G网络】': 'the most exceptional 5G network',
    为你提供: '',
    '【24小时线上客服支撑】': '【<span class="blue size20">24/7</span> Online Customer Support at Your Fingertips',
    用心关注你每一个问题: 'We genuinely care about every question you have and strive to provide prompt answers to your inquiries.',
    尽快解答你的疑难: '',
    备注: 'Remarks',
    备注内容: `
    The above service plans and offers are subject to terms and conditions. For details, please refer to <a href='/pdf/slash 5G本地服務計劃及5G一咭兩地服務計劃之條款及細則.pdf' >s/ash 5G Local Service Plan and 5G Mainland China and Hong Kong Service Plan Terms and Conditions</a>
    `
  },
  '100GB_offer': {
    立即领券: 'Collect Now',
    '【限时】上台额外送': '【Limited Offer】Additional ',
    本地数据: ' local data rewards',
    '成功上台s/ash，额外送多100GB本地数据': 'Successful registration with s/ash comes with an additional 100GB local data rewards',
    '为什么上台s/ash?': '<h2>Why choose <span class="blue">s/ash?</span></h2>',
    '扫QR Code即刻抢': 'Scan the QR Code to seize the rewards',
    条款及细则: 'Terms and Conditions',
    's/ash为你提供3个计划': '<h2>We provide you with <span class="blue">3</span> simple plans</h2>',
    用量多:
      'Abundant data, budget-friendly price! For a limited time, new customers can enjoy 3-5 months service fee wavier *!\nChoose a plan that suits you best',
    活动条款详情: `
      For the terms and condition of "100GB Local Data eCoupon Campaign", please refer to <a href='/pdf/「100GB本地數據券」優惠活動條款及細則.pdf' >100GB Local Data eCoupon Campaign Terms and Conditions</a>
      <br/><sup>*</sup>For the terms and conditions for the "Fee-Free for all" offer, please refer to Fee Free for all Terms and Conditions<a href='/pdf/全民免月費 活動細則.pdf' > Fee Free for all Terms and Conditions</a>
      <br/><sup>+</sup>Free Mainland China and Macau roaming data offer ("Offer") is only applicable to designated customers who have subscribed to the designated s/ash service plan and committed to a minimum service commitment period of 12 months ("Eligible Customers"). Eligible Customers can enjoy 2GB/4GB/6GB of roaming data per month during the contract period.
    `
  },
  '5Gservice_plan': {
    '5G世一Plan': '5G Top-Notch Plan ',
    全民: '',
    免月费: 'Free ',
    最多: ' monthly fee for all  Up to ',
    个月: ' months',
    个月短约: '-month contract',
    免: 'Admin',
    $18: ' Fee ',
    行政费: 'Waiver',
    无限: 'Unlimited ',
    数据: 'data',
    服务包括: 'Service included:',
    无限本地通话: 'Unlimited Local Voice',
    '500个网内短讯': '500 intra SMS',
    '10个国际短讯': '10 international SMS',
    留言信箱: 'Voicemail',
    来电转驳: 'Call forward',
    来电接待: 'Call waiting',
    电话会议: 'Conference call',
    '于海外拒接来电/防止打出': 'In/out coming call barring (when overseas)',
    '还在选择学生Plan或5G高价Plan?': 'Still choosing a student plan or a 5G expensive plan?',
    提供你简单3个计划:
      'We provide you with 3 simple plans\nAbundant data, buget-friendly price! For a limited time, new customers can enjoy 3-5 months of monthly fee wavied *!\nChoose a plan that suits you best',
    '3分钟搞掂': 'Complete in 3 minutes',
    轻松上台: 'Easy and fast',
    完旧约180日前起: 'Port in at 180 days',
    就可登记转台: 'before the expiration of the existing contract',
    专享打机礼遇: 'Exclusive benefits for playing Mobile Games',
    CMHK优质网络: "Enjoy CMHK's high-quality network.",
    '扫QR Code': 'Scan the QR Code',
    立即登记: ' to register immediately',
    备注内容: `
      <sup>*</sup>For the terms and conditions for the "Fee-Free for all" offer, please refer to <a href="/pdf/全民免月費 活動細則.pdf"> Fee Free for all Terms and Conditions</a>
      <br/><sup>+</sup>Free Mainland China and Macau roaming data offer ("Offer") is only applicable to designated customers who have subscribed to the designated s/ash service plan and committed to a minimum service commitment period of 12 months ("Eligible Customers"). Eligible Customers can enjoy 2GB/4GB/6GB of roaming data per month during the contract period.
    `
  },
  Gaming_Essentials: {
    '/打机必备s/ash做你最强后盾': '/Player essentials, s/ash is your strongest support',
    '*人气手游': 'Play Popular mobile games',
    免数据任玩: ' with free-data + 10% rebate for topping up<sup>*</sup>\nEnjoy 5G network speed Chill',
    立即启动流动电讯商账单付款服务账单付款服务: 'Start Direct carrier billing payment service immediately',
    tips: 'Only applicable to designated mobile games, the 10% rebate only applicable to Google Play mobile platform and settle payment with Direct carrier billing payment service, the offer is subject to terms and conditions',
    '/多款大热手游都适用': '/Applicable to many popular mobile games',
    '随时更新 有冇一款你玩紧?': 'update at any time Is there the game you’re currently playing?',
    下载: 'Downloads',
    展开: 'Expand',
    收起: 'Collapse',
    更多: 'More',
    游戏列表: [
      {
        name: 'Eggy Party',
        privilege: [1, 2],
        key: 'dzpd',
        download: '500K+ Downloads',
        link_Ios: 'https://apps.apple.com/hk/app/%E8%9B%8B%E4%BB%94%E6%B4%BE%E5%B0%8D/id1673675970',
        link_Android: 'https://play.google.com/store/apps/details?id=com.netease.eggypartyhmt',
        img: 'Gaming_Essentials/dzpd.png',
        tag: ['#Joyful party', '#Casual competition', '#Multiplayer co-op'],
        detail: `-Dive into the EGGYVERSE!-
        Become a cute egg and join the lively EGGYVERSE! Here, you can ride the giant Ferris wheel, fun trampolines, floor-to-ceiling pianos, football stadiums, and much more await you!

        -Collaborate with other cute Eggies!-
        Team up with cute Eggies of different styles; Fluffy, fantastical, sweet... Choose the style that suits you! Overcome obstacles with your Eggy friends, now it's time to have fun!

        -A world full of parties and games!-
        Join different parties, bump and hug with friends in the game! Watch out for obstacles and avoid other eggs! Party with your friends and play all night!

        - Create your own maps and enjoy with friends!-
        Want to try a unique map? Come and discover something new at Eggy Workshop. You can create your own map here and let your imagination run wild!`
      },
      {
        name: 'Arknights',
        privilege: [2],
        key: 'mrfz',
        download: '500K+ Downloads',
        link_Ios: 'https://apps.apple.com/hk/app/%E6%98%8E%E6%97%A5%E6%96%B9%E8%88%9F/id1490985322',
        link_Android: 'https://play.google.com/store/apps/details?id=tw.txwy.and.arknights',
        img: 'Gaming_Essentials/mrfz.png',
        tag: ['#Tactical attack and defense RPG', '#Strategy collocation', '#Meticulous character design', '#Leisure and Healing'],
        detail: `The earth was ravaged by a natural disaster of unknown origin, and a large number of mysterious minerals - ""Original Stone"" appeared on the land swept by the natural disaster.
        With the continuous advancement of science and technology, the energy contained in Originium has brought civilization into modern times after being put into industry. At the same time, Originium itself has also spawned the existence of ""infected people"".
        The ""infected"" are beings with strength and misfortune. Now some of them are trying to integrate with the Originium and bring a new order to the earth.
        This conspiracy of war will be a new obstacle to our fight against natural disasters.
        As a member of Rhode Island, together with Amiya, the open leader of Rhode Island, you will hire people to frequently enter the dangerous areas affected by natural disasters, rescue the victims, deal with ore disputes, and fight against the integration movement——
        The tactical mind of ""Rhode Island"", are you ready?`
      },
      {
        name: 'LifeAfter',
        privilege: [1],
        key: 'mrzh',
        download: '10M+ Downloads',
        link_Ios:
          'https://apps.apple.com/hk/app/%E6%98%8E%E6%97%A5%E4%B9%8B%E5%BE%8C-%E6%9C%AB%E4%B8%96%E7%94%9F%E5%AD%98%E6%89%8B%E9%81%8A/id1441752845',
        link_Android: 'https://play.google.com/store/apps/details?id=com.netease.mrzhna',
        img: 'Gaming_Essentials/mrzh.png',
        tag: ['#Open world', '#Zombieshooting', '#200M players unite'],
        detail: `The mysterious wanted posters attract survivors to join the game of life and death as hunters. The train speeds across the ice field, heading to ferocious and brutal mutated, menacing infected tides and threats from other hunter corps. 
        Are you ready to enjoy the everything-or-nothing safari?
        -The Vast Open World Expanded-
        The frontiers of the Doomsday World expand again. Survivors set sail to explore five mutated seas, each of which is characterized by its main feature—crystal, fog, filth, fire, and vortex... These mysterious and dangerous seas are waiting to be conquered.
        From snow mountain to beach, from forest to desert, from swamp to city... The vast Doomsday World is full of crises, yet offers endless possibilities. Here, you need to scavenge resources, build infrastructure, fend off zombie invasions, and build your own shelter.
        -Keep Hope Alive-
        When the doomsday came, zombies took over the world, collapsing social order and turning the familiar world unrecognizable. With the zombies craving human settlements, the harsh climate and scant resources, it’s hard to get by. In the doomsday seas, there resides even more dangerous new Infected and colossal mutant creatures that can sink boats effortlessly…...
        Danger is all around. You must keep calm and live on by any means necessary!
        -Make Survival Friends-
        You’ll encounter other Survivors during your doomsday exploration.
        Maybe you’re tired of all the zombie crying and night wind howling when you’re traveling alone. Try to open up, break bread with friends, talk all night long, and piece by piece create a peaceful shelter together.
        -Experience Half-Zombie Survival-
        The organization Dawn Break claims that human still has a chance after being bitten by zombie—to live as a ""Revenant"", abandon the human identity, appearance and abilities, and change forever.
        It sounds risky, but what would you choose if it’s matter of life and death?`
      },
      {
        name: 'Harry Potter: Magic Awakened',
        privilege: [1, 2],
        key: 'hlbt',
        download: '500K+ Downloads',
        link_Ios: 'https://apps.apple.com/hk/app/%E5%93%88%E5%88%A9%E6%B3%A2%E7%89%B9-%E9%AD%94%E6%B3%95%E8%A6%BA%E9%86%92/id1484303602',
        link_Android: 'https://play.google.com/store/apps/details?id=com.netease.harrypotter.tw',
        img: 'Gaming_Essentials/hlbt.png',
        tag: ['#Harry Porter', '#Role Playing'],
        detail: `""Harry Potter"" officially authorized mobile game ""Harry Potter: Magic Awakened"", invites you to return to Hogwarts and write a new chapter in your magical world!
        You will become a Hogwarts freshman and realize the long-awaited magic dream. In the wonderful magic campus, get to know wizard partners with different personalities, learn spells and magic knowledge, and experience the splendid wizard life. You can also join hands with the original characters, grow up in new adventures and adventures, and start your wizarding career!
        The admission letter has been sent, welcome to Hogwarts School of Witchcraft and Wizardry!`
      },
      {
        name: 'Honkai: Star Rail',
        privilege: [2],
        key: 'bh',
        download: '10M+ Downloads',
        link_Ios: 'https://apps.apple.com/hk/app/%E5%B4%A9%E5%A3%9E-%E6%98%9F%E7%A9%B9%E9%90%B5%E9%81%93/id1599719154',
        link_Android: 'https://play.google.com/store/apps/details?id=com.HoYoverse.hkrpgoversea',
        img: 'Gaming_Essentials/bh.png',
        tag: ['#Turn-based RPG', '#Casual adventure', '#Exquisite animation'],
        detail: `Honkai: Star Rail is a new HoYoverse space fantasy RPG.
        Hop aboard the Astral Express and experience the galaxy's infinite wonders filled with adventure and thrills.
        Players will meet new companions across various worlds and maybe even run into some familiar faces. Overcome the struggles together caused by Stellaron and unravel the hidden truths behind it! May this journey lead us starward!`
      },
      {
        name: 'Identity V',
        privilege: [1, 2],
        key: 'dwrg',
        download: '1M+ Downloads',
        link_Ios: 'https://apps.apple.com/hk/app/%E7%AC%AC%E4%BA%94%E4%BA%BA%E6%A0%BC/id1370284166',
        link_Android: 'https://play.google.com/store/apps/details?id=com.netease.idv_tw.googleplay',
        img: 'Gaming_Essentials/dwrg.png',
        tag: ['#Action adventure', '#Survival Horror Game'],
        detail: `Identity V" is NetEase's first asymmetric competition against competitive sports. The absurd Gothic style, the suspenseful brainstorming, and the stimulating 1V4 asymmetric confrontation gameplay will bring a new gaming experience to the players. The player will become detective Orpheus, and after receiving a mysterious letter of entrustment, he will enter a notorious estate to investigate a disappearance. In the course of conducting the evidence investigation, Orpheus will use the deductive method to review the case. At the time of the case review, the player can choose to act as a regulator or a survivor to launch a fierce confrontation. In the process of investigation, when it is infinitely close to the facts, it finds more and more incredible truth.`
      },
      {
        name: 'Survivor.io',
        privilege: [1, 2],
        key: 'ddtg',
        download: '10M+ Downloads',
        link_Ios: 'https://apps.apple.com/hk/app/%E5%99%A0%E5%99%A0%E7%89%B9%E6%94%BB/id1528941310',
        link_Android: 'https://play.google.com/store/apps/details?id=com.dxx.firenow',
        img: 'Gaming_Essentials/ddtg.png',
        tag: ['#Roguelike', '#Role Playing'],
        detail: `Dangerous zombies are attacking the entire city! The city is in peril!
        Awakened by the trial of dreams, you've no choice but to take on the heroic mantle of saving the city!
        As a human warrior with unlimited potential, you and other survivors will have to pick up your weapons and battle these evil and dangerous zombies!
        The horde far outnumbers you - any slip-up and you'll get caught in dire straits!
        Faced with crisis, you must find a way to survive!`
      },
      {
        name: 'Legend of the Secret Realm: Sacred Tree Ruins',
        privilege: [1, 2],
        key: 'mjcs',
        download: '500K+ downloads',
        link_Ios: 'https://apps.apple.com/hk/app/%E7%A7%98%E5%A2%83%E5%82%B3%E8%AA%AA-%E7%A5%9E%E6%9C%A8%E9%81%BA%E8%B7%A1/id1557440708',
        link_Android: 'https://play.google.com/store/apps/details?id=com.slsm.sy.and',
        img: 'Gaming_Essentials/mjcs.png',
        tag: ['#Role Playing'],
        detail: `A hidden secret realm, an ancient sacred tree, a relic containing the power of elves, a book of prophecy that foreshadows destruction, a brave and fearless young man, a thrilling adventure, a confusing truth...
        You are a curious tribal boy who stumbles upon the notes and letters left by your father. In order to solve the mystery of your father's disappearance and solve the crisis of the collapse of the continent, you follow in your father's footsteps and become a hunter. Explore the ruins of Shenmu, and the responsibility of saving this continent has quietly fallen on your shoulders...
        The legendary story of Shenmu Mystery begins here!`
      },
      {
        name: 'Hero',
        privilege: [1, 2],
        key: 'dxk',
        download: '500K+ Downloads',
        link_Ios: 'https://apps.apple.com/hk/app/%E5%A4%A7%E4%BF%A0%E5%AE%A2-%E6%8B%B3%E5%AE%9A%E5%A4%A9%E4%B8%8B/id1468451508',
        link_Android: 'https://play.google.com/store/apps/details?id=com.rxsj.ssjj',
        img: 'Gaming_Essentials/dxk.png',
        tag: ['#Role Playing', '#MMORPG', '#Stylized'],
        detail: `""Hero"" X ""Xuanyuan Swordsman Gaiden: Scars of the Sky"" linkage will officially start on June 1!
        Yu Xiaoxue accidentally entered the world of heroes through the Kunlun mirror, an artifact that travels through time and space, and is about to start a wonderful adventure in the world...`
      },
      {
        name: 'Be The King: Judge Destiny',
        privilege: [1, 2],
        key: 'jwgly',
        download: '1M+ Downloads',
        link_Ios: 'https://apps.apple.com/hk/app/%E5%8F%AB%E6%88%91%E5%AE%98%E8%80%81%E7%88%BA/id1241253595',
        link_Android: 'https://play.google.com/store/apps/details?id=com.szckhd.jwgly.azfanti',
        img: 'Gaming_Essentials/jwgly.png',
        tag: ['#Stmulation', '#Life', '#Stylized'],
        detail: `Call me an official" is an original mobile game for managing and developing an official. It uses the official as the first visual presentation to vividly present exciting gameplay such as promotion and fortune, family alliance and PK, doorman training, and multi-player social interaction.`
      },
      {
        name: 'Honkai Impact 3rd',
        privilege: [2],
        key: 'bh3',
        download: '1M+ Downloads',
        link_Ios: 'https://apps.apple.com/hk/app/%E5%B4%A9%E5%A3%9E3rd/id1233055283',
        link_Android: 'https://play.google.com/store/apps/details?id=com.miHoYo.bh3tw',
        img: 'Gaming_Essentials/bh3.png',
        tag: ['#Stylized', '#1 top grossing action'],
        detail: `""Honkai Impact 3rd"" ignites the soul of ACG action! The next generation of action game attack!
        In the not-too-distant future, the mysterious disaster ""Honkai"" has ravaged the world.
        Humans infected by Honkai become ""dead warriors"" who have no thinking and only know how to destroy; infected animals become brutal ""Honkai beasts"" who only know how to attack.
        The only ones who can resist Honkai are girls who are born with antibodies, and they will have marks called ""stigmata"" on their bodies.
        Players will play the role of the captain of the Huberian, commanding and leading the white-haired European-born Kiana, the virtuous Mei, the silent mercenary girl Bronya, the proud Jizi, and her own cuteness. Yae Sakura with fox ears, Teresa who is the cutest in the world, Karen who is a direct descendant of gun fighting skills, Fu Hua who can't lose to anyone when she is serious, Rita who blooms in the dark night, twin sisters with star dreams , the twin Xier with awakened personality, and Youlandelle with the strongest combat power of Destiny. Join hands to fight against Honkai, and fight for all the beauty in the world together!`
      }
    ],
    '点Chill法？': 'How to chill?',
    条款及细则: 'Terms & Conditions',
    課金回贈: '10% Rebate for Topping Up',
    免數據Chill住玩: 'Play with Free-Data ',
    活动条款内容: `
      1. Eligible Users
      1.1. " Chill Mobile Game" is only applicable to customers of s/ash ("Eligible Customers"), who
      are eligible to:
      i. receive a 10% rebate on in-game purchases made through designated Android
      operating systems and using s/ash billing service ("Purchase Rebate"); and
      ii. free data usage for designated game ("Free Data Usage for Designated Game").
      2. Terms and Conditions for the Purchase Rebate
      2.1 Eligible Customers for the Purchase Rebate must meet the following conditions:
      i. use an Android 2.2 or higher operating system and have installed the Google Play
      Store application on their smartphone. However, there may be differences in
      smartphone brand, model, and firmware version. For questions regarding the
      smartphone's built-in settings, please contact the smartphone manufacturer;
      ii. be customers of s/ash plans and use s/ash billing service.
      2.2 The Purchase Rebate does not apply to accounts for the purchase of Apple services such
      as applications, games, books, or movies.
      2.3 The Purchase Rebate is available to Eligible Customers who use their s/ash account to
      pay for in-game purchases through Google Play services. The account will be included in the
      monthly billing statement provided by s/ash to the customer. Eligible Customers need to pay
      according to the amount listed on the monthly billing statement. For example: If a customer
      spends HKD 100 in the game in March, when he/she receive the March monthly billing
      statement in April, he/she will see two items listed: "Google Play Store purchase HKD 100"
      and "Google Play Store Purchase Rebate by s/ash HKD 10". The total amount payable is HKD 90.
      2.4 All refunds can only be approved and executed by the application developer or Google.
      s/ash only provides this service as a payment method. If a customer requests a refund,
      he/she must contact the developer or Google directly. All refund requests must be processed
      in accordance with Google's refund policy
      <a href="https://support.google.com/googleplay/answer/2479637?hl=en">https://support.google.com/googleplay/answer/2479637?hl=en</a>. For example: If a customer requests a refund, the rebate amount generated by the Google Play Store purchase will be
      credited back to the Google Play Store rebate item on the monthly billing statement. For
      example, if a customer spends HKD 100 in the game in March, requests a refund a few days
      later, when he/she receive the March monthly billing statement in April, he/she will see
      three items listed: "Google Play Store purchase HKD 100," "Google Play Store Purchase
      Rebate by s/ash HKD 10," and "Google Play Store refund HKD 100." The total amount
      payable is HKD 0.
      2.5 The Purchase Rebate only applies to designated games, and the specific game list can be
      found on the activity page game list and is updated periodically.
      3. Terms and Conditions for the free data usage of Designated Game
      3.1 The free data usage is only provided for designated games. When playing these games,
      the data usage will not be counted towards the customer's data usage under the service
      plan. The specific game list can be found on the activity page game list and is updated
      periodically.
      3.2 The designated game data exempt from data usage only applies to local data usage
      exemption.
      3.3 Data exempt from usage does not include: (i) downloading and updating the application
      or software; (ii) using location and check-in services of the application; (iii) data usage
      generated from browsing, linking, or downloading any content from any other webpage or
      application, and additional prompts; (iv) advertisements played by the application.
      3.4 The maximum data download speed for "using designated game data" will vary according
      to the designated monthly plan. When the local data usage reaches the upper limit of the
      "designated data usage" of the designated service plan, the designated service plan and local
      data service for "using designated game data" can still continue, but the data speed will not
      exceed the designated speed of the designated service plan thereafter. The data service
      experience may be affected.
      4. Others
      4.1 If there is any suspicion that a participant in this program (i) is involved in or related to
      abnormal/suspicious activities; or (ii) provides illegal, dishonest, fraudulent, or false or
      incorrect information; or (iii) has violated the terms and conditions of any activity of s/ash or
      any other applicable law or regulation; or (iv) participates in this program by any other improper means, s/ash has the discretion and final decision-making power to cancel the
      eligibility and/or all rewards of any person participating in this program without any notice
      and without any liability to any person, and reserves the right to pursue the rewards and all
      related losses during the activity from that person.
      4.2 When customers participate in this program, it represents that customers accept the
      relevant terms and accept the promotional messages of this activity. The customer's
      agreement to receive promotional messages related to this program shall not replace any
      previous instructions on whether to receive s/ash promotional materials. Please refer to the
      s/ash Personal Data and Privacy Statement.
      4.3 If this promotional activity or related matters directly or indirectly cause eligible
      customers or any person to suffer or incur various special, direct, indirect, or consequential
      losses and damages (including but not limited to loss of income, data loss, loss of
      reputation), s/ash shall not be liable for any contract, tort, statutory, or other liability
      (including but not limited to negligence, breach of contract, defamation).
      4.4 If participants engage in any unlawful behavior, breach of these terms and conditions, or
      actions or inactions that may harm the rights of third parties, participants agree and
      undertake to indemnify s/ash for any disputes or claims arising from or related to their
      actions or inactions and to keep s/ash harmless from any damage.
      4.5 These terms and conditions and all matters related to these terms and conditions shall
      be subject to the exclusive jurisdiction of the laws of the Hong Kong Special Administrative
      Region and shall be interpreted and enforced accordingly. Participants agree that in case of
      any disputes, claims, or requests, they shall irrevocably and unconditionally submit to the
      exclusive jurisdiction of the Hong Kong Special Administrative Region courts.
      4.6 Each clause of these terms and conditions shall have independent enforceability, and its
      validity shall not be affected by the invalidity of other clauses.
      4.7 Except for eligible participants and s/ash, no person shall have any rights under the
      Contracts (Rights of Third Parties) Ordinance of Hong Kong to enforce or enjoy any benefits
      or rights under any provisions of these terms and conditions.
      4.8 s/ash reserves the right to change the content of this activity, terminate this activity,
      and/or revise these terms and conditions at any time without further notice. Any temporary
      changes or cancellations related to the activity will be based on the announcement of s/ash.4.9 There is no appeal mechanism for this activity. In case of any dispute, s/ash reserves the
      right of final decision-making. Any dispute shall be based on the system records of s/ash.
      4.10 In case of any discrepancy between the Chinese and English versions of these terms and
      conditions, the Chinese version shall prevail.
      Google Play Mobile Telecom Bill Payment Service Terms and Conditions ("these Terms and
      Conditions")
    `
  },
  BillPaymentService: {
    有關條款請參閲安卓:
      'For the related terms and conditions, please refer to: <a href="/pdf/Google Play流動電訊商賬單付款服務條款及細則.pdf"> Google Play Direct Carrier Billing Service Terms and Conditions</a>',
    有關條款請參閲苹果:
      'For the related terms and conditions, please refer to: <a href="/pdf/Apple服務流動電訊商賬單付款服務條款及細則.pdf"> Apple Services Direct Carrier Billing Service Terms and Conditions</a>',
    立即啟動流動電訊商賬單付款服務: 'Start Direct Carrier Billing immediately',
    啟動服務: 'Activate Now',
    '*請留意：首次啟用 「流動電訊商賬單付款服務」時，手機必須和s/ash網絡連接': `*Reminder: Device has to connected to s/ash or CMHK network for first time enablement to "Direct Carrier Billing Service"`,
    服務介紹: 'Service Introduction',
    以GooglePlay流動電訊商賬單付款服務支援: 'Supported by Google Play/Apple Services direct carrier billing service',
    直接: 'Direct',
    一次過月結單繳費: 'One-time bill payment per month',
    方便: 'Convenient',
    省卻每次輸入資料: 'No need to enter information every time',
    安全: 'Safe',
    不須用信用卡繳費: 'No need to pay by credit card',
    啟用教學: 'Start using tutorial',
    '只需進行簡單設定，即可啟用服務': 'Just make simple settings to activate the service',
    使用Android設備: 'For Android device',
    使用iOS設備: 'For ios device',
    Android1:
      '1. Log in to your Google account, open "Google Play" , and click your profile picture on the top right to enter the account management page',
    Android2: '2. Select "Payment and Subscriptions"',
    Android3: '3. Select "Payment Methods"',
    Android4: '4. Select "Add China Mobile HK Bill"',
    Android5: '5. Finally, press "Enable" to complete the setting',
    ios1: '1. Open「Settings」，tap（or log in) your Apple ID',
    ios2: '2. Tap 「Payment & Shipping」',
    ios3: '3. Tap 「Add Payment Method」',
    ios4: '4. Tap 「Mobile Phone」and 「Use This Mobile Number」(CMHK NUmber). Tap 「Done」when finishing input.',
    更多游戏: 'More Games',
    活动条款内容: `
      Terms and Conditions for " Chill Mobile Game" ("Terms and Conditions")
        1. Eligible Users
        1.1. " Chill Mobile Game" is only applicable to customers of s/ash ("Eligible Customers"), who
        are eligible to:
        i. receive a 10% rebate on in-game purchases made through designated Android
        operating systems and using s/ash billing service ("Purchase Rebate"); and
        ii. free data usage for designated game ("Free Data Usage for Designated Game").
        2. Terms and Conditions for the Purchase Rebate
        2.1 Eligible Customers for the Purchase Rebate must meet the following conditions:
        i. use an Android 2.2 or higher operating system and have installed the Google Play
        Store application on their smartphone. However, there may be differences in
        smartphone brand, model, and firmware version. For questions regarding the
        smartphone's built-in settings, please contact the smartphone manufacturer;
        ii. be customers of s/ash plans and use s/ash billing service.
        2.2 The Purchase Rebate does not apply to accounts for the purchase of Apple services such
        as applications, games, books, or movies.
        2.3 The Purchase Rebate is available to Eligible Customers who use their s/ash account to
        pay for in-game purchases through Google Play services. The account will be included in the
        monthly billing statement provided by s/ash to the customer. Eligible Customers need to pay
        according to the amount listed on the monthly billing statement. For example: If a customer
        spends HKD 100 in the game in March, when he/she receive the March monthly billing
        statement in April, he/she will see two items listed: "Google Play Store purchase HKD 100"
        and "Google Play Store Purchase Rebate by s/ash HKD 10". The total amount payable is HKD
        90.
        2.4 All refunds can only be approved and executed by the application developer or Google.
        s/ash only provides this service as a payment method. If a customer requests a refund,
        he/she must contact the developer or Google directly. All refund requests must be processed
        in accordance with Google's refund policy
        <a href="https://support.google.com/googleplay/answer/2479637?hl=en">https://support.google.com/googleplay/answer/2479637?hl=en</a>. For example: If a customer requests a refund, the rebate amount generated by the Google Play Store purchase will be
        credited back to the Google Play Store rebate item on the monthly billing statement. For
        example, if a customer spends HKD 100 in the game in March, requests a refund a few days
        later, when he/she receive the March monthly billing statement in April, he/she will see
        three items listed: "Google Play Store purchase HKD 100," "Google Play Store Purchase
        Rebate by s/ash HKD 10," and "Google Play Store refund HKD 100." The total amount
        payable is HKD 0.
        2.5 The Purchase Rebate only applies to designated games, and the specific game list can be
        found on the activity page game list and is updated periodically.
        3. Terms and Conditions for the free data usage of Designated Game
        3.1 The free data usage is only provided for designated games. When playing these games,
        the data usage will not be counted towards the customer's data usage under the service
        plan. The specific game list can be found on the activity page game list and is updated
        periodically.
        3.2 The designated game data exempt from data usage only applies to local data usage
        exemption.
        3.3 Data exempt from usage does not include: (i) downloading and updating the application
        or software; (ii) using location and check-in services of the application; (iii) data usage
        generated from browsing, linking, or downloading any content from any other webpage or
        application, and additional prompts; (iv) advertisements played by the application.
        3.4 The maximum data download speed for "using designated game data" will vary according
        to the designated monthly plan. When the local data usage reaches the upper limit of the
        "designated data usage" of the designated service plan, the designated service plan and local
        data service for "using designated game data" can still continue, but the data speed will not
        exceed the designated speed of the designated service plan thereafter. The data service
        experience may be affected.
        4. Others
        4.1 If there is any suspicion that a participant in this program (i) is involved in or related to
        abnormal/suspicious activities; or (ii) provides illegal, dishonest, fraudulent, or false or
        incorrect information; or (iii) has violated the terms and conditions of any activity of s/ash or
        any other applicable law or regulation; or (iv) participates in this program by any other improper means, s/ash has the discretion and final decision-making power to cancel the
        eligibility and/or all rewards of any person participating in this program without any notice
        and without any liability to any person, and reserves the right to pursue the rewards and all
        related losses during the activity from that person.
        4.2 When customers participate in this program, it represents that customers accept the
        relevant terms and accept the promotional messages of this activity. The customer's
        agreement to receive promotional messages related to this program shall not replace any
        previous instructions on whether to receive s/ash promotional materials. Please refer to the
        s/ash Personal Data and Privacy Statement.
        4.3 If this promotional activity or related matters directly or indirectly cause eligible
        customers or any person to suffer or incur various special, direct, indirect, or consequential
        losses and damages (including but not limited to loss of income, data loss, loss of
        reputation), s/ash shall not be liable for any contract, tort, statutory, or other liability
        (including but not limited to negligence, breach of contract, defamation).
        4.4 If participants engage in any unlawful behavior, breach of these terms and conditions, or
        actions or inactions that may harm the rights of third parties, participants agree and
        undertake to indemnify s/ash for any disputes or claims arising from or related to their
        actions or inactions and to keep s/ash harmless from any damage.
        4.5 These terms and conditions and all matters related to these terms and conditions shall
        be subject to the exclusive jurisdiction of the laws of the Hong Kong Special Administrative
        Region and shall be interpreted and enforced accordingly. Participants agree that in case of
        any disputes, claims, or requests, they shall irrevocably and unconditionally submit to the
        exclusive jurisdiction of the Hong Kong Special Administrative Region courts.
        4.6 Each clause of these terms and conditions shall have independent enforceability, and its
        validity shall not be affected by the invalidity of other clauses.
        4.7 Except for eligible participants and s/ash, no person shall have any rights under the
        Contracts (Rights of Third Parties) Ordinance of Hong Kong to enforce or enjoy any benefits
        or rights under any provisions of these terms and conditions.
        4.8 s/ash reserves the right to change the content of this activity, terminate this activity,
        and/or revise these terms and conditions at any time without further notice. Any temporary
        changes or cancellations related to the activity will be based on the announcement of s/ash.4.9 There is no appeal mechanism for this activity. In case of any dispute, s/ash reserves the
        right of final decision-making. Any dispute shall be based on the system records of s/ash.
        4.10 In case of any discrepancy between the Chinese and English versions of these terms and
        conditions, the Chinese version shall prevail.
        Google Play Mobile Telecom Bill Payment Service Terms and Conditions ("these Terms and
        Conditions")
    `
  },
  myOrder: {
    常用功能: 'Frequent visit',
    订单查询: 'Order Enquiry',
    查询您的订单: 'Enquire your order',
    扫码查询订单: 'Scan the code to enquire your order\nOnly applicable to customers who register\nthrough the s/ash eShop'
  },
  porting_guide: {
    轉台小教學: 'Tips for Number Port-in',
    上台slash: 'Register with s/ash or port in to s/ash is simple. Here are the port-in tips.',
    覺得攜號轉台很麻煩:
      'Porting in is so troublesome? It takes 5 minutes to port in to s/ash. You need to prepare Hong Kong Identity Card or Exit-entry Permit for Hong Kong and Macau and a value existing number from SmarTone、csl、Birdie、3HK、HKBN、1010 and China Unicom.',
    舊合約未完約點算好:
      "How to port in if the existing contract has not expired?s/ash supports pre-registration. If you don't know the end date of the existing contract, it is recommended that you confirm with your current operator:  SmarTone、csl、Birdie、3HK、HKBN、1010 and China Unicom by contacting their hotlines or visiting their shops.",
    攜號轉台需時幾耐:
      'How long it takes for porting in? To ensure the success of the porting in process, we recommend that you register 7 days in advance. With the principle of high efficiency, the port-in process can be completed within a maximum of 3 working days, as fast as 5G network speed.',
    從SmarTone:
      'Here are some tips for successfully porting your number from SmarTone, csl, Birdie, 3HK, HKBN, 1010, China Unicom, or other telecom providers for service plans and student service plans.',
    攜號轉台還有:
      'What else is worthy of attention?Please confirm whether your current number for porting is under a monthly plan or a prepaid plan.',
    如果你是月費客戶:
      'For monthly plan customers(SmarTone、csl、Birdie、3HK、HKBN、1010、China Unicom):The registered name and ID number you provided must match the ID card. The registered name must match the one currently registered with your existing telecom operator. The number needs to remain active during the porting process.',
    如果你是mysim:
      'For prepaid plan customers: Your prepaid card must be active with a balance of at least HK$1. Enter the complete ICCID number, including any English letters (if applicable). The complete ICCID number is printed on the back of the SIM card or on the cardholder certificate. You must upload a picture of the cardholder certificate or the back of the SIM card. Please note that if you are currently using a CMHK prepaid card, you cannot port to s/ash at the moment.',
    攜號轉台成功貼士:
      "Tips: In case the porting process is unsuccessful, your phone number will continue to be retained by your current operators:  SmarTone, csl, Birdie, 3HK, HKBN, 1010, China Unicom, and so on, andd won't be deactivated. We'll also do our best to assist you proactively with any issues related to the porting failure, so rest assured! ",
    '即時開台s/ash，享受優惠學生電話plan':
      'Register with s/ash to enjoy 5G service plan, especially exclusive plan for students，and MyLink benefits.',
    '即刻開台s/ash 更多優惠隨心享': `Register with s/ash now
    Enjoy more benefits`,
    立即開台: 'Port in Now'
  },
  slashintro: {
    '咩係s/ash?': "What's s/ash?",
    's/ash香港5G SIM卡':
      "s/ash, Hong Kong 5G SIM card, also an essential travel SIM card, allows you roam abroad without switching Mainland China and Japan SIM card or other SIM cards. Supported by China Mobile CMHK's high-quality network, s/ash provides high-speed services while offering the most affordable service plan.",
    '/5G通訊品牌': '/ 5G Telecommunication Brand',
    '/ 社交娱乐KOL': '/ Social Media KOL',
    '/ 旅遊出Trip達人': '/ Travel Expert',
    Add我Instagram了解我更多: 'Follow my Instagram to know more about me',
    's/ash使用中國移動CMHK':
      "s/ash utilizes the high-quality internet service provided by China Mobile CMHK'S 5G network. s/ash offers the most affordable SIM cards、best deals for students service plan and MyLink Points. Customers can redeem MyLink points to discounted flights ticket offers, supermarket coupons, electronic products, beauty products, and more.",
    '娛樂社交∞': 'Entertainment & Sociality ∞',
    '【無限任用】15個APPs 5G數據！': '【Unlimited Data Usage】5G data for 15 APPs ! ',
    'L Plan送你「任用社交及娛樂數據組合」(價值: $38/月)':
      '"Unlimited Entertainment & Social Data Package" (value: $38/month) allows unlimited data ',
    '‧ Youtube、Netflix、Instagram等15個APPs數據通通免費任用': 'usage on 15 APPs including Youtube, Netflix, and Instagram.',
    旅行出遊: 'Traveling Out',
    '【漫游】無需換卡到埗即用！': '【Roaming Service】No need to siwtch SIM cards. Ready to use immediately when landing!',
    '‧ 中國内地/澳門': '‧  Local Service Plan includes monthly data for Mainland China and Macau. Mainland China and Hong Kong',
    '3個Plan都送你中國内地同澳門漫游數據': 'Service Plan offers shared data between Mainland China and Hong Kong.',
    '‧ 日、韓、泰、英、美等其他160+地區': '‧  160+ regions including Japan, South Korea, Thailand, the United Kingdom, the United States',
    '上MyLink APP買漫遊GO! Pass、短途旅行隨時啓程': 'Purchase GO！Pass roaming data on the MyLink APP! Start your trip any time you want',
    打機: 'Worry-free purchase of 5G Handsets',
    '【Chill住打機】想課金就課金': '【Chill Handset Upgrade 】No need to worry about getting a new handset!',
    '指定熱門手機游戲：': 'Designated popular mobile games:',
    '‧ 免數據任玩': '‧ ‧ Unlimited data usage when playing',
    '‧ 於Google Play課金可享10%回贈': '‧ 10% Google Play Rebate on in-game top-ups',
    打機chill住玩: 'Enjoy your chill gameplay',
    '选用本地M及L Plan并签约24个月': 'Choose the local M and L Service Plans with a 24-month',
    '另享受$1, 200 5G手機獎賞，支援選購熱門品牌5G手機':
      'contract to enjoy $1,200 5G Handset Rewards, supporting the purchase of popular brand 5G handsets.',
    '/品牌介紹': '/Brand',
    '「slash」全新態度': 'slash" The new attitude ',
    '現今我地愛以「s/asher」自居，以「/」表示自己喺社會上同一時間扮演住唔同角色，唔再用單一身分去定義自己，甚至喺社交平台上開設多個帳戶，去展現自己多元嘅生活面貌，朝早我係一位OL，夜晚我可以係一位瑜伽老師。我係一名學生嘅同時我亦可以係一位網店店主。': `Nowadays, we embrace the identity of "slashers," using the "/" to represent the different roles we play simultaneously in society. We no longer define ourselves by a single identity. We even create multiple accounts on social platforms to showcase the diverse facets of our lives. In the morning, I'm an office lady, and at night, I can be a yoga instructor. I'm a student, and at the same time, I can also be an online store owner.`,
    '「s/asher」這個全新生活方式，代表我地年青人渴求彰顯自己獨特個性同態度，期望以自己嘅喜好同潛能去做自己想做嘅事。「s/asher」不只是一個身份、更代表的背後的一份態度！':
      'The new lifestyle of "s/asher" represents the desire of us young people to showcase our unique personalities and attitudes. We aspire to pursue what we want to do based on our interests and potentials. "s/asher" is not just an identity, but it also embodies an attitude that goes beyond just a label!',
    '「s/ash」速度 闊度 你想生活態度': '"s/ash" Speedy. Boundless. Live life your way',
    's/ash - 專為年青人而設嘅通訊生活品牌，將今日s/ash嘅態度融入品牌，以「/」呢一劃為你連結全新身份，回歸通訊服務的品牌，同時為你帶來生活品味嘅禮遇，我地：': `"s/ash" - a telecommunication and lifestyle brand tailored for the youth, infuses today's s/ash attitudes into brand essence. Using the "/" symbol, it connects you to a fresh identity, redefining communication services as a brand and bringing you refined lifestyle privileges. We:`,
    '以最優質嘅5G通訊網絡 為你帶來速度與日常的便利 ':
      'Delivering speed and everyday convenience through the highest-quality 5G communication network',
    '以最具品味嘅生活禮遇 為你拓展你嘅闊度與視野':
      'Offering the most tasteful lifestyle privileges to expand your horizons and perspectives',
    '助你連結世界、分享日常、追求理想，切實體現s/ash精神':
      'Help you connect with the world, share daily life, pursue ideals, and truly embody the spirit of s/ash',
    服務計劃: 'Service plan',
    '簡單3個Plan  12個月短約 線上全自助辦理  揀1個最啱你！': `4 Simple Plans with flexible 12/24-month contract options. Apply online and choose the one that suits you best!`,
    '- 青春限定 - ': '- Youth Exclusive -',
    全民免3個月月費: 'Youth support special price exclusively for young people',
    '18-29歲客人再加免3個月! 總共免5個月! ': 'Only available to <span style="color:#eb6ea5">students and 18-29 years old</span> customers',
    '- 加碼獎勵 - ': '- Boosted Rewards ',
    送中國內地及澳門數據: 'Free Mainland China and Macau data for Local Service Plan',
    免$18行政費: 'Waive $18/month admin fee for port-in numbers',
    '網絡實測 - 用s/ash上網有幾快?': 'Network Testing - How fast is internet browsing with s/ash?',
    网络实测: 'Network Testing',
    在香港不同的地方:
      "s/ash's 5G network were conducted in differents locations in Hong Kong, such as Hong Kong Convention and Exhibition Centre, The Wai Shopping Mall, City U and Baptist U HKBU. The test results indicated that s/ash provides fast speed network.",
    速度: 'Speed',
    ' 港鐵荃灣綫由中環去到荃灣需要32分鐘；':
      'It takes <span class=" blue">32 minutes</span> for the Tsuen Wan Line MTR to travel from Central to Tsuen Wan; ',
    ' 肚餓沖杯面食需要等3分鐘；': `It takes <span class=" blue">3 minutes</span> to make a cup of instant noodles when you're hungry; `,
    ' 而上台s/ash，使用由 CMHK支撐的5G網絡後， ': 'But using s/ash, <span class=" blue">with the 5G network powered by CMHK</span>',
    ' 下載一集Netflix韓劇/美劇全高畫質的影片只需約30秒*！ ':
      'Downloading an episode of Netflix Korean/American drama in full HD only takes about <span class=" blue">30 seconds</span>*! ',
    ' 上Instagram、Facebook、Youtube觀看內容更只需1瞬間！':
      'Viewing content on Instagram, Facebook, and YouTube only takes <span class=" blue">a moment</span>!',
    ' s/ash應援好你日常每分每秒，以可以達到最快嘅速度為你填補你所需的每一秒':
      '<span class=" blue">s/ash supports your every daily moment, filling each second you need with the fastest speed possible.</span>',
    覆蓋: 'Coverage',
    '99%的網絡覆蓋列舉:': 'Districts in the 99% network coverage is listed:',
    ' - 中西區、灣仔、銅鑼灣': '- Central and Western District, Wan Chai, Causeway Bay',
    ' - 葵青區、荃灣區、沙田區 ': '- Kwai Tsing District, Tsuen Wan District, Sha Tin District',
    ' - 觀塘區、油尖旺、元朗區 ': '- Kwun Tong District, Yau Tsim Mong District, Yuen Long District',
    's/ash時刻應援好你日常，讓你無論留守校園、遊走市區與郊外也不斷網': `s/ash provides strong support wherever you are, ensuring you stay connected whether you're on campus, in the city, or out in the suburb.`,
    '*實際網絡服務會受服務地點、網絡情況、網絡覆蓋範圍、硬件、軟件及其他因素而有所影響':
      '*Actual network service will be subject to and affected by the service locations, network circumstance, network coverage, hardware, software and other factors.',
    媒體報導: 'Media coverage',
    更多報導: 'More coverage',
    '舊約完前180日就可以登記   立即登記s/ash享受更多專享禮遇': `You can register 180 days before the end of your previous contract Register now for more exclusive benefits`,
    上台或者攜號: 'Customers enjoy 5G number portability benefits when registering with s/ash or porting in to s/ash.',
    立即登記: 'Register now',
    測速實例: 'Network Testing - How fast is internet browsing with s/ash?',
    测速desc:
      '*Actual network service will be subject to and affected by the service locations, network circumstance, network coverage, hardware, software and other factors.'
  },
  HelpCenter: {
    常见问题: 'Frequently Asked Questions',
    您可以在下方查询常见问题的解决方案:
      'You can find solutions to common questions below. If your issue remains unresolved, please contact us through Live CHAT.',
    输入框占位: 'Please enter the keywords of the question, such as "latest promotion"',
    共找到: '',
    个结果: ' results found  in total.',
    问题类型: 'Type of question',
    不限: 'Unlimited',
    帮助中心: 'Help Center',
    搜索结果: 'Search Results',
    '抱歉，没有找到相关问题～': 'Sorry, no relevant questions found~',
    去试试: 'Why not give ',
    即时Chat: 'Instant Chat',
    '吧！': ' a try!'
  },
  TNC: {
    首页: 'Home ',
    条款及细则列表: 'Terms and Conditions',
    条款及细则: 'Terms and Conditions',
    于2023年09月15日更新: 'Updated at 8 August, 2024',
    // '全民免月費活動細則': 'Fee Free for All Terms and Conditions',
    // '打機chill住玩條款及細則': 'chill mobile games terms and conditions',
    // 'Google Play流動電訊商賬單付款服務條款及細則': 'Google Play Direct Carrier Billing Service Terms and Conditions',
    // '「100GB本地數據券」優惠活動條款及細則': '100GB Local Data eCoupon Campaign Terms and Conditions',
    预览: 'Preview',
    下载: 'Download',
    list: [
      {
        id: 1,
        title: 's/ash 5G Local Service Plan and 5G Mainland China and Hong Kong Service Plan Terms and Conditions',
        content: `
        1.	The monthly fee discount is only applicable to new customers who subscribe to the designated s/ash 5G Local Service Plan 30GB/50GB/100GB with a minimum commitment period of 12 months or more or the 5G Mainland China and Hong Kong Service Plan 20GB with a minimum commitment period of 24 months. The basic service monthly fee for the aforementioned plans is as follows: 5G Local 30GB: HK$338; 5G Local 50GB: HK$388; 5G Local 100GB: HK$438; 5G One SIM Two Numbers 20GB: HK$349. Customers are required to pay an additional HK$18 per month for administration fees.
        2.	Unless otherwise specified on the website of China Mobile Hong Kong Company Limited ("China Mobile Hong Kong"), data roaming services in Mainland China and Macau will be provided using the 5G network. For information on data roaming service areas, please visit <a href="https://bit.ly/39XqbWa">https://bit.ly/39XqbWa</a>.
        3.	For s/ash 5G Local Service Plan 30GB/50GB/100GB, if the monthly data usage exceeds the included 5G local data of the service plan, local data service in Hong Kong will continue with a maximum transmission speed limit (s/ash 5G Local Service Plan: 30GB/50GB: 1Mbps; 100GB: 5Mbps) and will not be applicable to network sharing (tethering) and point-to-point (P2P) functions (including Bit-Torrent, etc.). For the 5G Mainland China and Hong Kong Service Plan 20GB, if the monthly data usage exceeds the included data usage of the service plan, the Hong Kong data service will continue with a maximum transmission speed limit of 1Mbps, and Mainland China roaming data will be temporarily suspended. Network sharing (tethering) and point-to-point (P2P) functions (including Bit-Torrent, etc.) will not be applicable.
        4.	New customers who subscribe to the designated s/ash 5G Local Service Plan 30GB/50GB/100GB with a minimum commitment period of 12 months or more can enjoy an additional 3GB/4GB/6GB Mainland China and Macau roaming data per month. This offer is valid until further notice and is subject to terms and conditions. Mainland China and Macau data usage can be used in Mainland China/Macau. Roaming data usage is only applicable to customers who have activated the roaming service. If the customer has not activated the roaming service, they must dial *109*01# to activate it before visiting the designated area; or set it up via the China Mobile Hong Kong website/MyLink App. For details, please refer to the China Mobile Hong Kong website. When customers visit Mainland China/Macau, the mobile phone will automatically search for and lock onto China Mobile Hong Kong's local network provider partners for data roaming services. The network provider partners may change from time to time. Charges incurred by using aviation roaming, maritime roaming, and satellite roaming services or data roaming services in non-designated areas will be calculated according to the standard roaming data usage or Data Roaming Freedom Circle (if applicable). Roaming data usage is not applicable to network sharing (tethering) and point-to-point (P2P) functions (including Bit-Torrent, etc.). The Greater Bay Area (Mainland China and Macau) data roaming package is subject to the relevant terms and conditions. For details, please visit: <a href="https://bit.ly/39XqbWa">https://bit.ly/39XqbWa</a>.
        5.	Mainland China call minutes are applicable to (i) calls from Mainland China to all Mainland China and Hong Kong phone numbers; and (ii) receiving calls in Mainland China from all Mainland China and Hong Kong phone numbers.
        6.	New customers who subscribe to the designated s/ash 5G Local Service Plan 30GB/50GB/100GB with a minimum commitment period of 12 months or more can enjoy the "Unlimited Social and Entertainment Data Package" (valued at HK$38/month) during the contract period ("Complimentary Offer"). New customers who subscribe to the designated Mainland China and Hong Kong Service Plan 20GB with a minimum commitment period of 24 months can order the "Unlimited Social and Entertainment Data Package" value-added service at a discounted monthly fee of HK$29 less than the service's regular price during the contract period ("Discount Offer"). The discounted price of HK$9 is calculated based on the regular monthly fee of HK$38 for the "Unlimited Social and Entertainment Data Package" as of July 26, 2024, minus the discount offer, resulting in a monthly fee of HK$9. The Complimentary Offer and Discount Offer are only applicable to the designated service plans and are only valid during the contract period. If the customer switches to another service plan midway through or the designated service plan contract expires, the Complimentary Offer/Discount Offer will be automatically terminated. For details of the "Unlimited Social and Entertainment Data Package" value-added service, please refer to <a href="https://bit.ly/socialdataTC">https://bit.ly/socialdataTC</a>. In addition to these terms and conditions, customers also agree to abide by the terms and conditions related to the "Unlimited Social and Entertainment Data Package" value-added service.
        7.	The 5G handset reward is only applicable to new customers who subscribe to the s/ash 5G Local Service Plan 30GB/50GB/100GB with a minimum commitment period of 24 months. Customers must successfully register for the designated service plan by the specified date and use this offer to purchase designated handsets in-store before December 31, 2024. For details on the designated handset models applicable to the 5G handset reward, please consult in-store staff. When using the offer, a specified amount must be prepaid, and the specified handset price must be paid. The prepaid amount will be averaged and refunded to the customer's bill within 12 months from the next billing month after purchasing the handset. Each service plan can only enjoy this offer once. It cannot be enjoyed simultaneously with other new phone offers. The availability of the handset depends on the supply situation. The 5G handset reward cannot be exchanged for cash, refunds, or any other form of redemption, nor can it be transferred or resold. China Mobile Hong Kong does not guarantee the supply/pickup date of the handset, and obtaining the 5G handset reward will not be considered as reserving any handset. All the above offers and service plans are subject to the relevant terms and conditions.
        8.	New customers who successfully subscribe to the 5G Mainland China and Hong Kong Service Plan 20GB and sign a 24-month contract can enjoy a waiver of the Mainland China “1-Card-Multi-Number” value-added service (valued at HK$18/month) monthly fee or the " CM Numbers Link" value-added service (valued at HK$18/month) monthly fee (choose one). Once the two-in-one offer is selected, the customer cannot change the selection midway. The customer's Mainland China phone number must complete the real-name registration process before use. Both the Mainland China and Hong Kong phone numbers of the "1-Card-Multi-Number " service can receive incoming calls and SMS. However, SMS sent by the customer will display their Hong Kong primary number. SMS services for the Mainland China phone number are subject to additional charges based on usage, and voice services are still subject to additional per-minute call charges. For details, please refer to <a href="https://bit.ly/3WERg6c">https://bit.ly/3WERg6c</a>.
        9.	For details on the Mainland China "1-Card-Multi-Number " value-added service, please refer to <a href="https://bit.ly/3WERg6c">https://bit.ly/3WERg6c</a>. In addition to these terms and conditions, customers also agree to abide by the terms and conditions related to the Mainland China "1-Card-Multi-Number " value-added service.
        10.	For details on the " CM Numbers Link " value-added service, please refer to <a href="https://bit.ly/cmnumberlink">https://bit.ly/cmnumberlink</a>. In addition to these terms and conditions, customers also agree to abide by the terms and conditions related to the " CM Numbers Link" value-added service.
        11.	New customers who port their numbers and commit to a minimum service period of 12 months can enjoy a waiver of the $18 administration fee during the contract period. Customers who port their numbers must successfully port their numbers within 180 days after signing the contract. If the customer upgrades to another service plan or changes the phone number during the minimum service period, this offer will be canceled. This offer is valid until further notice.

        •	All the above offers and service plans are subject to the relevant terms and conditions.
        •	The new customers referred to in all the above offers include new customers who sign up and those who port their numbers.
        •	Unless otherwise specified, the above offers are valid from August 14, 2024, until further notice.
        •	If the customer switches to another service plan or terminates the service plan early during the contract period, the above offers will be automatically canceled, and the total amount of the enjoyed offers may be charged to the customer without prior notice.
        •	The s/ash 5G Local Service Plan is only applicable to customers aged 18-29 or students of designated primary, secondary, and tertiary institutions in Hong Kong at the time of subscription. The student registrant must be at least 18 years old and a student of a designated institution, and must present a valid student ID issued by the designated institution. 
        •	The 5G Mainland China and Hong Kong Service Plan is only applicable to customers aged 18-29 at the time of subscription.
        •	Each valid student ID can only be used to register for the s/ash 5G Local Service Plan once. If the same student ID is used for repeated registration of the s/ash 5G Local Service Plan, the relevant duplicate application will be canceled.
        •	China Mobile Hong Kong reserves the right to require customers to present valid identification documents/designated student IDs to prove that the customer is still between 18-29 years old or still has a valid student status when necessary. If it is found that the identification document/designated student ID is invalid or ineligible at any time, or if the customer refuses to provide valid identification documents/designated student ID, China Mobile Hong Kong reserves the right to refuse, suspend, or cancel the designated service plan and the offers under the designated service plan at any time without prior notice.
        •	If the customer terminates the service plan early during the minimum service period, the customer must pay the compensation amount agreed in the contract to China Mobile Hong Kong.
        •	The local 5G data service is provided through the China Mobile Hong Kong 5G network. If the customer is in an area outside the 5G data service coverage in Hong Kong, China Mobile Hong Kong will still provide service through the 4G/3G/2G network. Unless otherwise specified on the China Mobile Hong Kong website, the Mainland China/Macau data roaming service will be provided using the 5G network. If the customer is in an area outside the 5G data service coverage, the Mainland China/Macau data roaming service will still be provided through the 4G/3G/2G network. The use of 5G data service requires a designated model of mobile device. The actual network service will be affected by service location, network conditions, network coverage, hardware, software, and other factors.
        •	Customers can use the 5G network (if applicable) in designated areas outside Hong Kong. The 5G network must be provided through designated network providers. When customers visit designated areas outside Hong Kong, the mobile phone will automatically search for and lock onto China Mobile Hong Kong's local network provider partners for data roaming services. Charges incurred by using aviation roaming, maritime roaming, and satellite roaming services or data roaming services in non-designated areas will be calculated according to the standard roaming data usage or Data Roaming Freedom Circle (if applicable). The designated countries/regions and network providers will be revised or changed from time to time. For information on 5G data roaming service areas, roaming, and IDD charges, please refer to the China Mobile Hong Kong website.
        •	Data usage during roaming in Mainland China is only applicable to China Mobile's 2G/3G TD-SCDMA/4G TDD-LTE/5G network and is not applicable to FTP, P2P functions (including BitTorrent, Peer Casting applications - such as PP Stream, Internet TV, etc.). China Mobile's 3G TD-SCDMA/4G TDD-LTE/5G network services must be used with suitable devices.
        •	China Mobile Hong Kong reserves the right to change the charges for roaming data usage at any time without prior notice. China Mobile Hong Kong reserves the right to change or terminate the above offers at any time without prior notice.
        •	In case of any disputes, China Mobile Hong Kong reserves the right of final decision.
        •	Subject to terms and conditions.

        The above information is the latest service content and charges announced by China Mobile Hong Kong Company Limited and replaces the information uploaded before August 14, 2024.

        `,
        isAnnex: true,
        size: '378KB',
        link: '/pdf/slash 5G本地服務計劃及5G一咭兩地服務計劃之條款及細則.pdf'
      },
      {
        id: 2,
        title: 'chill mobile games terms and conditions(The activity has ended)',
        content: `
          1.	Eligible Users

          1.1. " Chill Mobile Game" is only applicable to customers of s/ash ("Eligible Customers"), who are eligible to:
          i. receive a 10% rebate on in-game purchases made through designated Android operating systems and using s/ash billing service ("Purchase Rebate"); and
          ii. free data usage for designated game ("Free Data Usage for Designated Game").

          2.	Terms and Conditions for the Purchase Rebate

          2.1 Eligible Customers for the Purchase Rebate must meet the following conditions:
          i. use an Android 2.2 or higher operating system and have installed the Google Play Store application on their smartphone. However, there may be differences in smartphone brand, model, and firmware version. For questions regarding the smartphone's built-in settings, please contact the smartphone manufacturer;
          ii. be customers of s/ash plans and use s/ash billing service.

          2.2 The Purchase Rebate does not apply to accounts for the purchase of Apple services such as applications, games, books, or movies.

          2.3 The Purchase Rebate is available to Eligible Customers who use their s/ash account to pay for in-game purchases through Google Play services. The account will be included in the monthly billing statement provided by s/ash to the customer. Eligible Customers need to pay according to the amount listed on the monthly billing statement. For example: If a customer spends HKD 100 in the game in March, when he/she receive the March monthly billing statement in April, he/she will see two items listed: "Google Play Store purchase HKD 100" and "Google Play Store Purchase Rebate by s/ash HKD 10". The total amount payable is HKD 90.

          2.4 All refunds can only be approved and executed by the application developer or Google. s/ash only provides this service as a payment method. If a customer requests a refund, he/she must contact the developer or Google directly. All refund requests must be processed in accordance with Google's refund policy <a href="https://support.google.com/googleplay/answer/2479637?hl=en">https://support.google.com/googleplay/answer/2479637?hl=en</a>. For example: If a customer requests a refund, the rebate amount generated by the Google Play Store purchase will be credited back to the Google Play Store rebate item on the monthly billing statement. For example, if a customer spends HKD 100 in the game in March, requests a refund a few days later, when he/she receive the March monthly billing statement in April, he/she will see three items listed: "Google Play Store purchase HKD 100," "Google Play Store Purchase Rebate by s/ash HKD 10," and "Google Play Store refund HKD 100." The total amount payable is HKD 0.

          2.5 The Purchase Rebate only applies to designated games, and the specific game list can be found on the activity page game list and is updated periodically.

          3. Terms and Conditions for the free data usage of Designated Game

          3.1 The free data usage is only provided for designated games. When playing these games, the data usage will not be counted towards the customer's data usage under the service plan. The specific game list can be found on the activity page game list and is updated periodically.

          3.2 The designated game data exempt from data usage only applies to local data usage exemption.

          3.3 Data exempt from usage does not include: (i) downloading and updating the application or software; (ii) using location and check-in services of the application; (iii) data usage generated from browsing, linking, or downloading any content from any other webpage or application, and additional prompts; (iv) advertisements played by the application.

          3.4 The maximum data download speed for "using designated game data" will vary according to the designated monthly plan. When the local data usage reaches the upper limit of the "designated data usage" of the designated service plan, the designated service plan and local data service for "using designated game data" can still continue, but the data speed will not exceed the designated speed of the designated service plan thereafter. The data service experience may be affected.

          4. Others
          4.1 If there is any suspicion that a participant in this program (i) is involved in or related to abnormal/suspicious activities; or (ii) provides illegal, dishonest, fraudulent, or false or incorrect information; or (iii) has violated the terms and conditions of any activity of s/ash or any other applicable law or regulation; or (iv) participates in this program by any other improper means, s/ash has the discretion and final decision-making power to cancel the eligibility and/or all rewards of any person participating in this program without any notice and without any liability to any person, and reserves the right to pursue the rewards and all related losses during the activity from that person.

          4.2 When customers participate in this program, it represents that customers accept the relevant terms and accept the promotional messages of this activity. The customer's agreement to receive promotional messages related to this program shall not replace any previous instructions on whether to receive s/ash promotional materials. Please refer to the s/ash Personal Data and Privacy Statement.

          4.3 If this promotional activity or related matters directly or indirectly cause eligible customers or any person to suffer or incur various special, direct, indirect, or consequential losses and damages (including but not limited to loss of income, data loss, loss of reputation), s/ash shall not be liable for any contract, tort, statutory, or other liability (including but not limited to negligence, breach of contract, defamation).

          4.4 If participants engage in any unlawful behavior, breach of these terms and conditions, or actions or inactions that may harm the rights of third parties, participants agree and undertake to indemnify s/ash for any disputes or claims arising from or related to their actions or inactions and to keep s/ash harmless from any damage.

          4.5 These terms and conditions and all matters related to these terms and conditions shall be subject to the exclusive jurisdiction of the laws of the Hong Kong Special Administrative Region and shall be interpreted and enforced accordingly. Participants agree that in case of any disputes, claims, or requests, they shall irrevocably and unconditionally submit to the exclusive jurisdiction of the Hong Kong Special Administrative Region courts.

          4.6 Each clause of these terms and conditions shall have independent enforceability, and its validity shall not be affected by the invalidity of other clauses.

          4.7 Except for eligible participants and s/ash, no person shall have any rights under the Contracts (Rights of Third Parties) Ordinance of Hong Kong to enforce or enjoy any benefits or rights under any provisions of these terms and conditions.

          4.8 s/ash reserves the right to change the content of this activity, terminate this activity, and/or revise these terms and conditions at any time without further notice. Any temporary changes or cancellations related to the activity will be based on the announcement of s/ash.

          4.9 There is no appeal mechanism for this activity. In case of any dispute, s/ash reserves the right of final decision-making. Any dispute shall be based on the system records of s/ash.

          4.10 In case of any discrepancy between the Chinese and English versions of these terms and conditions, the Chinese version shall prevail.
          Google Play Mobile Telecom Bill Payment Service Terms and Conditions ("these Terms and Conditions")


        `,
        isAnnex: true,
        size: '344KB',
        link: '/pdf/打機chill住玩條款及細則.pdf'
      },
      {
        id: 3,
        title: '100GB Local Data eCoupon Campaign Terms and Conditions(The activity has ended)',
        content: `
        1.	This Campaign start from now until further notice(“Campaign Period”).
        2.	All offers available in this Campaign are only applicable to the online subscription through s/ash Rate Plan Purchase Page and shall be bound by all relevant terms and conditions.
        3.	“100GB Local Data eCoupon” Campaign Details*:
        a. New customers who subscribe to s/ash S: 30GB, s/ash M: 50GB and  s/ash L: 100GB (12/24-month contract) Service Plan ("designated service plan") via s/ash Rate Plan Purchase Page can get 100GB Local Data eCoupon. Local data eCoupon will be issued to the user's communication account when the rate plan is successfully activated.
        b. All offers in this Campaign, unless otherwise specified, cannot be used in conjunction with any other offers. The above offers will be forfeited if the customer terminate the contract within the minimum contract period. s/ash has the right to charge the full amount of the offers which already enjoyed without prior notice.
        4.	Methods to obtain “100GB Local Data eCoupon”
        a. Clients who choose the designated service plan can obtain a '100GB local data redemption code' (the 'redemption code') by entering their mobile phone number on the s/ash activity page (the phone number must be verified as belonging to the user through the use of a One Time Passcode). Each phone number is limited to one redemption code, and the code is valid for 30 days. The code is for one-time use only, invalid after use or expiration.
        b. Clients who choose the designated service plan can enter a 'redemption code' on the s/ash Rate Plan Purchase Page to obtain a '100GB local data eCoupon' (the 'eCoupon'). After the service plan is activated, the eCoupon will be sent to the user's communication account and can be viewed and used by logging into the MyLink App account and navigating to 'Avatar' in the upper left corner > 'My e-Cash Coupons’.
        c. The eCoupon is valid for 30 days from the date of issuance, and will be invalid after the expiration date. The date and time will be based on the server time of s/ash. Customers need to activate the eCoupon within the validity period before they can use the local data. Once activated, the 100GB local data will be valid for 30 days.
        d. The redemption code and the eCoupon is non-exchangeable for cash, other service or product, and cannot be refunded, exchanged, transferred, or resold.
        e. The redemption code and the eCoupon is valid for one-time use only and cannot be used repeatedly.
        f. The eCoupon will be invalid after successful use and be found in the following locations:
        MyLink App > “Profile” > “My e-Cash Coupons” > "All Invalid Coupon"
        5.	All offers in this Campaign, unless otherwise specified, cannot be used in conjunction with any other offers.
        6.	s/ash employees (including full-time, part-time, or any contracted staff) are not allowed to participate in this Campaign.
        7.	s/ash shall keep all personal information confidential at all times and shall destroy any personal data it may hold in accordance with its internal policy. Personal data will only be retained for as long as s/ash needs it unless the personal data is also retained to satisfy any applicable statutory or contractual obligations or for the purpose of investigation, detection, and prosecution of crime. All requests for access to or correction of personal information, regarding policies and practices and types of personal information held, should be addressed to China Mobile Hong Kong Company Limited, Level 20, Tower 1, Kowloon Commerce Centre, 51 Kwai Cheong Road, Kwai Chung, New Territories, Hong Kong, Attention to Data Protection Officer, or dial CMHK Un-subscription Commercial Electronic Messages Registration Hotline 6226 4926.
        8.	s/ash reserves the right to modify or terminate this Campaign, amend the above terms and conditions at any time without prior notice.
        9.	If customers terminate the relevant service contract or switch to any non-designated service plan during the Campaign Period, all entitlements and offer received shall be forfeited by s/ash without any obligation or compensation incurred.
        10.	s/ash will not be liable for any matter or loss in relation to submission delay, loss, and any information transmission error due to technical problems including but not limited to any computer or internet network issues.
        11.	Each Eligible Customer(s) agree(s) to indemnify and hold s/ash harmless from all claims or demands in connection with his/her breach of any of these Terms and Conditions, or his/her violation of any laws or rights of a third party.
        12.	Participants of this Campaign are deemed to have read and agreed to these terms and conditions. s/ash has the discretion to disqualify and claim compensation from any Eligible Customer(s) who s/ash, in its sole discretion, suspects that he/she: (i) is undertaking or in connection with any unusual or suspicious activities, (ii) is using inappropriate method or committing fraud to interrupt operation and interfere any part of the Campaign, causing technical problems, OR detects he/she involves in(iii) any dishonest conduct, false or inaccurate information provided or (iv) any breaches of these Terms and Conditions or any other applicable laws or regulations, without notice or reason, and without any liability to any person.
        13.	In case of dispute, s/ash reserves the right to make the final decision.
        14.	For any discrepancies between the Chinese and English versions of these terms and conditions, the Chinese version shall prevail.
        15.	s/ash is the telecommunications service brand of China Mobile Hong Kong Limited ("CMHK") (license number: 002).

        `,
        isAnnex: true,
        size: '297KB',
        link: '/pdf/「100GB本地數據券」優惠活動條款及細則.pdf'
      },
      {
        id: 4,
        title: 'Google Play Direct Carrier Billing Service Terms and Conditions',
        content: `
          1.	The Google Play Direct Carrier Billing Service ("this service") is available to s/ash customers for paying the bills of applications, games, books, or movies purchased in the Google Play Store. The relevant bills will be included in the customer's monthly statement. Customers need to pay the amount listed on the monthly statement.
          2.	This service is only available to designated s/ash customers.
          3.	This service is applicable to smartphones with Android 2.2 or above operating systems and the Google Play Store application installed, but there may be differences in some smartphones due to different brands, models, or firmware versions. For questions about the built-in settings of the smartphone, please contact your smartphone manufacturer.
          4.	The Customer must be aged 18 or above, otherwise the Service shall not be used.
          5.	New s/ash customers can activate this service as early as 2:00 am on the day after the monthly fee plan service takes effect; port-in customers can activate this service as early as 2 hours after successful porting in.
          6.	Customers who fall under the following two situations cannot use the Direct Carrier Billing Service : 1) not using credit card automatic payment and within the first month of new service or within one month before the end of the contract period; 2) having two or more unpaid bills in the bill.
          7.	The Customers can check their grades of the Carrier Billing Service rating (the “Rating”) via MyLink apps ( Personal Information -> Carrier Billing Service Rating)
          8.	The Service is subject to a monthly spending limit in any calendar month for each grade of the Rating as below:
          <table border="1" cellspacing="0" cellpadding="0">
          <tbody>
            <tr>
              <td valign="top" width="264">
                <p><span>Grades of the Rating</span></p>
              </td>
              <td valign="top" width="265">
                <p><span>Monthly spending limit（</span><span>$HK</span><span>）</span></p>
              </td>
            </tr>
            <tr>
              <td valign="top" width="264">
                <p><span>AAAAA</span></p>
              </td>
              <td valign="top" width="265">
                <p><span>3,000</span></p>
              </td>
            </tr>
            <tr>
              <td valign="top" width="264">
                <p><span>AAAA</span></p>
              </td>
              <td valign="top" width="265">
                <p><span>2,000</span></p>
              </td>
            </tr>
            <tr>
              <td valign="top" width="264">
                <p><span>AAA</span></p>
              </td>
              <td valign="top" width="265">
                <p><span>1,500</span></p>
              </td>
            </tr>
            <tr>
              <td valign="top" width="264">
                <p><span>AA</span></p>
              </td>
              <td valign="top" width="265">
                <p><span>500</span></p>
              </td>
            </tr>
            <tr>
              <td valign="top" width="264">
                <p><span>A</span></p>
              </td>
              <td valign="top" width="265">
                <p><span>300</span></p>
              </td>
            </tr>
          </tbody>
        </table>
          Review of the above rating and the monthly spending limit is subject to s/ash’s discretion. The decision of s/ash on the rating and monthly spending limit is final.
          9.  s/ash reserves the right to vary and update the rating and the monthly spending limit from time to time without prior notice to the Customers.

          10.  There is a monthly spending limit for this service, which is from the customer's billing month start date to end date. If the customer's billing month start date is the 6th day of each Gregorian month, the end date is the 5th day of the next Gregorian month. If the customer's spending exceeds the monthly limit, the service cannot be continued.

          11.	Any download of contents from Google Play Store and use of the applications will generate data usage. Local data usage will be deducted from the customer's current service plan or charged at the standard data rate. Standard roaming data fees will be charged when using this service while roaming.
          12.	All purchase items are provided by Google and are subject to the existing Google Play Store terms and conditions.
          13.	By using this service, customers agree to be bound by the Google Service Terms, which can be viewed at <a href="https://play.google.com/intl/zh-HK_hk/about/play-terms.html">https://play.google.com/intl/zh-HK_hk/about/play-terms.html</a>.
          14.	s/ash reserves the right to suspend or terminate this service at any time without prior notice.
          15.	"All refunds can only be approved and executed by the application developer or Google. s/ash only provides this service as a payment method. If customers request a refund, they must directly contact the relevant developer or Google. All refund requests must be processed in accordance with the Google refund policy at <a href="https://support.google.com/googleplay/answer/2479637?hl=en">https://support.google.com/googleplay/answer/2479637?hl=en</a> . For example, if a customer requests a refund, the corresponding amount will be reimbursed through the Google Play Store rebate item on the monthly statement. For instance, if a customer spends HKD 100 in March and requests a refund a few days later, when he/she receive the monthly statement in April, he/she will see a HKD 100 Google Play Store purchase, HKD 10 deducted for the Google Play Store rebate, and the following refund items: HKD 100 refund from Google Play Store and HKD 10 rebate from Google Play Store, totaling HKD 0 in expenses.
          16.	All contents on the Google Play Store are provided by Google. s/ash assumes no responsibility for the quality, nature, accuracy, and usefulness of any content.
          17.	s/ash reserves the right to terminate or modify this service or these terms and conditions at any time without prior notice. In case of any dispute, s/ash reserves the right of final decision.
          18.	Google Play and the Google Play logo are trademarks of Google.
          19.	In case of any discrepancy between the Chinese and English versions of these terms and conditions, the Chinese version shall prevail.
        `,
        isAnnex: true,
        size: '355KB',
        link: '/pdf/Google Play流動電訊商賬單付款服務條款及細則.pdf'
      },
      {
        id: 5,
        title: 'Apple Services Direct Carrier Billing Service Terms and Conditions',
        content: `
          1. The Apple Services Direct Carrier Billing Service (the “Service”) allows s/ash customers to make digital content purchases such as apps, games, books or movie rentals in Apple  services and settle the amount of payment via their s/ash monthly phone bills.
          2. This service is only available to designated s/ash customers.
          3. Apple services including but not limited to Apple Store, Apple Music, Apple TV+ and iCloud, etc. For the details of Apple services launched in Hong Kong, please visit <a href="https://www.apple.com">https://www.apple.com</a>.
          4. The Customer must be aged 18 or above, otherwise the Service shall not be used.
          5. New s/ash customers can activate this service as early as 2:00 am on the day after the monthly fee plan service takes effect; port-in customers can activate this service as early as 2 hours after successful porting in.
          6. Customers who fall under the following two situations cannot use the Direct Carrier Billing Service : 1) not using credit card automatic payment and within the first month of new service or within one month before the end of the contract period; 2) having two or more unpaid bills in the bill.
          7. The Customers can check their grades of the Carrier Billing Service rating (the “Rating”) via MyLink apps ( Personal Information -> Carrier Billing Service Rating)
          8. The Service is subject to a monthly spending limit in any calendar month for each grade of the Rating as below:
          <table border="1" cellspacing="0" cellpadding="0">
          <tbody>
            <tr>
              <td valign="top" width="264">
                <p><span>Grades of the Rating </span></p>
              </td>
              <td valign="top" width="265">
                <p><span>Monthly spending limit（</span><span>$HK</span><span>）</span></p>
              </td>
            </tr>
            <tr>
              <td valign="top" width="264">
                <p><span>AAAAA</span></p>
              </td>
              <td valign="top" width="265">
                <p><span>3,000</span></p>
              </td>
            </tr>
            <tr>
              <td valign="top" width="264">
                <p><span>AAAA</span></p>
              </td>
              <td valign="top" width="265">
                <p><span>2,000</span></p>
              </td>
            </tr>
            <tr>
              <td valign="top" width="264">
                <p><span>AAA</span></p>
              </td>
              <td valign="top" width="265">
                <p><span>1,500</span></p>
              </td>
            </tr>
            <tr>
              <td valign="top" width="264">
                <p><span>AA</span></p>
              </td>
              <td valign="top" width="265">
                <p><span>500</span></p>
              </td>
            </tr>
            <tr>
              <td valign="top" width="264">
                <p><span>A</span></p>
              </td>
              <td valign="top" width="265">
                <p><span>300</span></p>
              </td>
            </tr>
          </tbody>
        </table>
          Review of the above rating and the monthly spending limit is subject to s/ash’s discretion. The decision of s/ash on the rating and monthly spending limit is final.
          9. s/ash reserves the right to vary and update the rating and the monthly spending limit from time to time without prior notice to the Customers.
          10. The period of each monthly spending limit starts from the first day of the customer’s bill month and ends on the last day of the billing month. For the avoidance of doubt, if Customer’s bill date starts from 6th day of each calendar month, then his monthly spending limit will be from 6th day of the month until 5th day of the next month. If the purchase amount of Apple services exceeds the monthly spending limit, payment cannot be settled using the Service.
          11. Any download of contents from Apple services and use of the apps, contents and services incur mobile data usage. The relevant data charges will be charged at or deducted from relevant subscribed service plans, or be charged additionally according to the standard rate. When using this Service and Apple services abroad, standard roaming data charges shall apply.
          12. The terms and conditions of Apple services shall apply to the relevant purchases and services, including refund policy.
          13. The Customer agrees to be bound by the terms and conditions of Apple services. For details, please click <a href="https://www.apple.com/hk/en/legal/internetservices/itunes/hk/terms.html">https://www.apple.com/hk/en/legal/internetservices/itunes/hk/terms.html</a>.
          14. In order to set up this Service, the Customers must use their iPhone to set up their Apple ID. Once the account is successfully set up, they can use this Service to settle the payments by signing in with the same Apple ID on their iPhone, iPad, Apple TV, Mac, or PC. The transactions thereafter will be charged to the s/ash’s mobile service bills of Customers without further notice.
          15. Refunds of all purchases and subscriptions can only be performed by the app developers or Apple. s/ash only provides this Service as a form of payment. For any refunds, the Customer should contact the developers or Apple directly. All refund requests are required to follow Apple’s refund policy which is specified in the email receipt issued by Apple.
          16. For any inquiries and complaints on purchase in the Apple services, please submit your application in accordance with the support instructions specified in email receipt issued by Apple.
          17. All contents under Apple services are provided by Apple, and s/ash is not responsible or liable for the quality, nature, accuracy and usefulness of the contents.
          18. s/ash reserves the right to suspend, change or terminate this Service or amend these Terms and Conditions at any time without notice.
          19. In the event of a dispute, s/ash’s decision shall be final.
          20. If there is any discrepancy between the Chinese and English versions of these Terms and Conditions, the English version shall prevail.
        `,
        isAnnex: true,
        size: '790KB',
        link: '/pdf/Apple服務流動電訊商賬單付款服務條款及細則.pdf'
      },
      {
        id: 6,
        title: 's/ash x Standard Chartered Bank Special Offer Terms and Conditions(The activity has ended)',
        content: `
          1. The Promotion is valid from 1 December 2023 to 31 March 2024 (both dates inclusive) (the "Promotion Period").
          2. During promotion period, New customers who subscribe to designated service plan with designated Promo Code for 12 months contract with designated Standard Chartered Credit Card (“Eligible Customers”) can enjoy enjoy a “One-Month service fee Payment e-Cash Coupon”（“Payment e-Cash Coupon”）.
          3. “Payment e-Cash Coupon” will be sent to MyLink account within 180 working days after mobile serviced activated. “Payment e-Cash Coupon” can be used for payment via MyLink App only.
          4. Port-in customers shall successfully port-in to CMHK within 180 days upon signing the contract. If customer fail to port-in number, changes to another service plan or terminates the service plan early during the contract period, the offer will be automatically cancelled and CMHK reserves the right to charge the total amount of the offer enjoyed by the customer without prior notice.
          5. The Value of the “Payment e-Cash Coupon” for s/ash S/M/L Service Plan are $168/$248/$298 respectively. “Payment e-Cash Coupon” be used to pay the monthly service plan fee, it cannot be exchanged for cash or other products or services or transferred to other persons or entities.
          • s/ash is the telecommunications service brand of China Mobile Hong Kong Limited ("CMHK") (license number: 002).
          • s/ash reserves the right to modify or terminate the above offers or content, amend these terms and conditions at any time without prior notice.
          • In case of any dispute, s/ash reserves the right of final decision.
          To borrow or not to borrow? Borrow only if you can repay!
          `,
        isAnnex: true,
        size: '224KB',
        link: '/pdf/slash x 渣打信用卡推廣計劃條款及細則.pdf'
      },
      {
        id: 7,
        title: 'Terms and Conditions for slasher Ambassador Program(The activity has ended)',
        content: `
        1.	The program will run from the date of commencement until Jul 31, 2024, based on the server time of the organizer (as defined below) ("Program Period").
        2.	Participants must meet the following conditions to qualify as eligible participants ("Qualified Participants") for the program:
          <table border="1" cellspacing="0" cellpadding="0">
            <tbody>
              <tr>
                <th>
                  <p><span>Qualified Participants </span></p>
                </th>
                <th >
                  <p><span>Conditions</span></p>
                </th>
              </tr>
              <tr>
                <td>
                  <p><span>(1) Qualified s/asher Ambassador applicants</span></p>
                </td>
                <td>
                  <p><span>(a) Individuals who can successfully download and register MyLink App; and
                  (b) Individuals who are not included in the program blacklist as specified in Article 15 of these Terms and Conditions.
                  </span></p>
                </td>
              </tr>
              <tr>
                <td>
                  <p><span>(2) Qualified Referees</span></p>
                </td>
                <td>
                  <p><span>(a) Individuals who successfully subscribe for s/ash and enter the s/asher Ambassador promo code;
                  (b) Individuals who can successfully download and register MyLink App;
                  (c) Individuals who have not subscribed to any s/ash service plan ("Designated Service Plan") using the mobile phone number used to participate in the program on the day of participation; and
                  (d) Individuals who are not included in the program blacklist as specified in Article 15 of these Terms and Conditions.
                  </span></p>
                </td>
              </tr>
            </tbody>
          </table>
        *The s/asher Ambassador and the Qualified Referee cannot be the same person.
        Failure to meet the above qualifications will result in the cancellation of participation and eligibility for the program.

        3.	The s/asher Ambassador Program is organized by China Mobile Hong Kong Company Limited ("Organizer").
        4.	Qualified s/asher Ambassador applicants can apply to become a s/asher Ambassador ("s/asher Ambassador") by submit the registration form on the designated program page. After submitting the registration form, the Organizer will review the application and notify the s/asher Ambassador applicant of the registration result within 10 working days via WhatsApp or email. s/asher Ambassador applicants who pass the review can become s/asher Ambassadors.
        5.	s/asher Ambassadors who successfully refer new customers to sign up for the designated service plan will receive corresponding MyLink points.
        6.	MyLink points are operated and managed by the MyLink application ("MyLink"). s/asher Ambassadors need to register and log in to MyLink using the phone number provided in the registration form on the program page to obtain MyLink points. The details of the tasks are as follows:
        7.	Each qualified s/asher Ambassador will be assigned an exclusive referral code, which will be distributed via WhatsApp or email. During the Program Period, any new customer ("Referee") who successfully signs up for the designated s/ash S/M/L service plan using the exclusive promo code at the s/ash eshop and commits to a minimum service period of 12 months will receive a one-month fee e-voucher ("e-voucher"). The e-voucher must be used within the contract period.
        8.	The e-voucher will be issued to the Referee's MyLink account within 180 working days after the Referee successfully activates the service. The Referee must log in to the MyLink mobile application using the registered phone number of the service plan to pay and use the e-voucher.
        9.	The details of the e-vouchers that the Referee can receive after completing the referral steps are as follows:
        <table border="1" cellspacing="0" cellpadding="0">
            <tbody>
              <tr>
                <td >
                  <p><span>Designated Service Plan Selected by Referee</span></p>
                </td>
                <td >
                  <p><span>S/30GB</span></p>
                </td>
                 <td >
                  <p><span>M/50GB</span></p>
                </td>
                 <td >
                  <p><span>L/100GB</span></p>
                </td>
              </tr>
              <tr>
                <td>
                  <p><span>Value of e-voucher</span></p>
                </td>
                <td >
                  <p><span>$168</span></p>
                </td>
                 <td >
                  <p><span>$248</span></p>
                </td>
                 <td >
                  <p><span>$298</span></p>
                </td>
              </tr>
            </tbody>
          </table>
        10.	The e-voucher can only be used to pay for the monthly fee of the service plan and cannot be exchanged for cash or other products or services or transferred to others.
        11.	After successfully referring a Referee (based on clause 5, successful sign-up and continuous use of the service plan for 120 days), the s/asher Ambassador will receive corresponding MyLink points based on the service plan selected by the Referee, as shown in the table below: 
        <table border="1" cellspacing="0" cellpadding="0">
          <tbody>
            <tr>
              <td >
                <p><span>Service Plan Selected by Referee</span></p>
              </td>
              <td >
                <p><span>S/30GB</span></p>
              </td>
              <td >
                <p><span>M/50GB</span></p>
              </td>
              <td >
                <p><span>L/100GB</span></p>
              </td>
            </tr>
            <tr>
              <td>
                <p><span>Age of Referee on the day of applying for the service plan is 18-29</span></p>
              </td>
              <td >
                <p><span>21,000 MyLink points (equivalent to $210 value)</span></p>
              </td>
              <td >
                <p><span>31,000 MyLink points (equivalent to $310 value)</span></p>
              </td>
              <td >
                <p><span>37,000 MyLink points (equivalent to $370 value)</span></p>
              </td>
            </tr>
            <tr>
              <td >
                <p><span>Age of Referee on the day of applying for the service plan is 30 or above</span></p>
              </td>
              <td >
                <p><span>28,000 MyLink points (equivalent to $280 value)</span></p>
              </td>
              <td >
                <p><span>41,000 MyLink points (equivalent to $410 value)</span></p>
              </td>
              <td >
                <p><span>50,000 MyLink points (equivalent to $500 value)</span></p>
              </td>
            </tr>
          </tbody>
        </table>
        12.	The designated service plan is subject to its own terms and conditions. Please visit <a href="https://bit.ly/48m8atp">https://bit.ly/48m8atp</a> for details.
        13.	MyLink points will be credited to the s/asher Ambassador's MyLink account within 180 working days after the designated service plan subscribed by the Referee becomes effective.
        14.	The MyLink points and fee vouchers received are collectively referred to as "Gifts" in this program.
        15.	If the referee is a port-in customer, they must successfully complete the number porting within 180 days after signing the contract. If the number porting fails, or they change to another service plan, or terminate the designated service plan before the contract period ends, the Gifts will be automatically canceled, and the Organizer has the right to charge the referee and the referring s/asher Ambassador for the total amount of benefits enjoyed without prior notice.
        16.	After participants receive MyLink points and/or fee vouchers, they cannot be refunded, exchanged for cash, or replaced. They cannot be changed, refunded, sold, divided, transferred, or transferred to others. MyLink points and/or fee vouchers from different MyLink accounts cannot be used together. When an s/asher Ambassador terminates their MyLink account, the MyLink points and/or fee vouchers in their account will be automatically canceled, and the Organizer will not provide any compensation.

        <b>Disclaimer of Liability for the Event</b>
        17.	If the Organizer suspects any eligible participant of (i) engaging in or being associated with abnormal/suspicious activities, or (ii) using improper methods or engaging in fraudulent behavior that disrupts or interferes with any part of the activity or causes technical issues, or detects or discovers (iii) any dishonest behavior, provision of false, incorrect, or inaccurate information, or (iv) any violation of these terms and conditions or any applicable laws or regulations, or (v) any non-good faith or malicious use of any related services, the Organizer reserves the right to, at its discretion and without prior notice or liability to any party, suspend, cancel their participation in the activity or their eligibility to receive the Gifts and/or participate in future promotional activities, and retains the right to take legal action against such individuals and include them in a blacklist.
        18.	The Organizer shall not be held responsible for any loss or damage (whether direct or indirect) arising from any service failure, interruption, delay, or temporary suspension caused by power or network failures or any other circumstances beyond the Organizer's control, such as any technical difficulties, network failures, interruptions or congestion, mobile application malfunctions, any third-party causes, software incompatibility issues, or any other reasons (including but not limited to any terminal equipment or internet network issues), resulting in any delay, loss, error, or inability to identify responses, or the inability to participate in the activity or use the Gifts, and the Organizer shall not be liable for any reissuance or compensation.
        19.	In the event of any illegal behavior, violation of the law, these terms and conditions, or any actions or inactions that may harm the rights of third parties by any eligible participant, the participant agrees and undertakes to fully indemnify the Organizer and hold them harmless from any disputes and claims arising from or related to their actions or inactions.
        20.	Participation in the activity and/or receipt of the Gifts by eligible participants is voluntary and at their own risk and full responsibility. The Organizer shall not be legally liable or provide compensation for any loss, injury, expense, dispute, claim, or lawsuit (including but not limited to direct, indirect, and consequential losses) incurred by any eligible participant due to their participation in the activity and/or receipt of the Gifts.
        21.	The Organizer shall not be responsible for any loss or damage resulting from the complete or partial non-performance of these terms and conditions due to reasons beyond reasonable control, not caused by their fault or negligence, including but not limited to power failures, war, imminent war threats, riots or other acts of civil disobedience, turmoil, natural disasters, restrictions imposed by government or any other national legal authorities, or any other industrial or sectoral disputes, fires, explosions, hurricanes, floods, lightning, earthquakes, and other natural disasters.
        22.	All goods, services, gifts, or offers obtained, purchased, or redeemed through the activity are subject to the terms and conditions of the respective products or offers.

        <b>MyLink Points Usage and Notes</b>
        23.	During the promotional period, MyLink points earned by eligible participants will be credited to their MyLink account. The MyLink points balance can be viewed on the "Profile - My Points" page.
        24.	The validity period of the Points is subject to the time when the Points are credited into the Users' MyLink account. Notwithstanding any information regarding the validity of Points published on MyLink accounts or through other materials, Users shall take the information shown on their MyLink accounts as accurate. In case of discrepancy, the information contained on MyLink shall prevail. Users shall arrange the redemption accordingly.
        25.	Eligible participants are required to use the points before the Points Validity Period expires for online payment, prepaid card top-up, redemption of rewards, or purchase of designated products/services. Expired points will be deemed void and will not be reissued or have their validity period extended. For details on redeeming MyLink points, please refer to and agree to the terms and conditions of the MyLink points program. For more information, please visit: <a href="https://bit.ly/3sNT9kA">https://bit.ly/3sNT9kA</a>.
        26.	The organizer reserves the right to exercise discretion and make decisions regarding the handling of MyLink points for eligible participants. MyLink may suspend the issuance of MyLink points or deduct MyLink points from any eligible participant's MyLink account at any time without prior notice in the following circumstances (including but not limited to): (a) in the case of fraud or suspected fraud by the eligible participant; (b) when the eligible participant obtains MyLink points through unfair means (including but not limited to dishonest use, fraud, misuse, or abuse); (c) when the user cancels or revokes any eligible service or mobile phone number service for receiving MyLink points; (d) in the event of an error in calculating the MyLink points that the eligible participant should receive by the organizer or any service provider; and/or (e) when the organizer reasonably ensures the legitimate use of MyLink points by the eligible participant.

        <b>Payment Voucher Usage and Notes</b>
        27.	During the promotional period, payment vouchers obtained by eligible participants will be credited to their MyLink account. The payment vouchers can be viewed on the "Profile - My E-Cash Coupons" page.
        28.	All payment vouchers have a validity period ("Voucher Validity Period"). The Voucher Validity Period is determined by the time the payment vouchers are credited to the user's MyLink account. In the event of any discrepancies between the payment vouchers obtained from this promotion and the terms and conditions of any other payment voucher promotion, the validity of the vouchers displayed in the MyLink account shall prevail.
        29.	Eligible participants are required to use the payment vouchers to pay for service plan fees before the Voucher Validity Period expires. Expired vouchers will be deemed void and will not be reissued or have their validity period extended.
        30.	The organizer reserves the right to exercise discretion and make decisions regarding the handling of payment vouchers for eligible participants. The organizer may suspend the issuance of payment vouchers or deduct payment vouchers from any eligible participant's MyLink account at any time without prior notice in the following circumstances (including but not limited to): (a) in the case of fraud or suspected fraud by the eligible participant; (b) when the eligible participant obtains payment vouchers through unfair means (including but not limited to dishonest use, fraud, misuse, or abuse); (c) when the user cancels or revokes any eligible service or mobile phone number service for receiving payment vouchers; (d) in the event of an error in calculating the payment vouchers that the eligible participant should receive by the organizer or any service provider; and/or (e) when the organizer reasonably ensures the legitimate use of payment vouchers by the eligible participant.

        <b>Personal Data Collection Statement</b>
        31.	Qualified participants agree and consent to the collection, use, and disclosure of their personal data by the organizer for administrative and all related purposes of the event, as well as for promotional and marketing purposes as set out in the general personal data collection statement and privacy policy statement. Qualified participants have read, understood, and agreed to be bound by the general personal data collection statement and privacy policy statement of the organizer. The aforementioned statements can be found at: <a href="https://bit.ly/3S4Ba39">https://bit.ly/3S4Ba39</a> and <a href="https://bit.ly/4aIIhWo">https://bit.ly/4aIIhWo</a>.
        32.	Participants may be required to provide personal identification information to the organizer. Such information is necessary for participants to participate in the event and/or receive event gifts. If participants fail to provide the required information, they will not be able to participate in the event and/or receive event gifts.
        33.	The above statement is issued in compliance with the requirements of the Personal Data (Privacy) Ordinance (Chapter 486) to inform participants of the purpose of data collection and the participant's consent to the use of this data by the organizer and its rights. Participants have the right to request access to or correction of their personal data in accordance with the provisions of the Personal Data (Privacy) Ordinance. For any requests for access or correction of data, please refer to the details in the organizer's privacy policy statement.
        34.	Participants who wish to access personal data, correct personal data, or inquire about personal data-related statements should send a letter to: China Mobile Hong Kong Company Limited, Address: 20/F, Tower 1, Kowloon Commerce Centre, 51 Kwai Cheong Road, Kwai Chung, New Territories, Hong Kong, Attn: Data Protection Officer; or call the organizer's Do-Not-Call Register Hotline at 6226 4926 or Customer Service Hotline at 12580.

        <b>Other Notes</b>
        35.	Each Hong Kong mobile phone number is only eligible for participation in the event once. If users are found to participate in the event through plug-ins or other unauthorized means, including but not limited to disconnected numbers or fake accounts, or in any way that affects fairness, the organizer reserves the right to cancel the participation eligibility of such qualified participants without further notice.
        36.	Any individuals who have engaged in dishonest or fraudulent behavior, non-benevolent or non-normal use during the organizer's MyLink and online store activities, or have violated the terms and conditions of any of the organizer's activities, will be automatically included in the activity blacklist and will lose their eligibility to participate.
        37.	If the personal data submitted by qualified participants contains errors, inaccuracies, or falsehoods, or if it results in the organizer's inability to contact or verify the participant's identity due to factors beyond the control of the organizer, it will be deemed as a waiver of participation in the event and eligibility to receive event gifts. Qualified participants shall not request reissue or any form of compensation, and the organizer shall not be liable for this.
        38.	Employees of the organizer (including full-time and part-time employees or individuals employed on a contract basis) are not eligible to participate in the event for fairness.
        39.	These terms and conditions, including establishment, validity, interpretation, performance, signature, amendment, termination, dispute resolution, and all matters related to these terms and conditions, shall be governed and enforced exclusively by Hong Kong law. All participants irrevocably and unreservedly agree that any disputes, controversies, lawsuits, or claims arising from these terms and conditions shall be subject to the exclusive jurisdiction of the Hong Kong courts.
        40.	Each provision of these terms and conditions shall be enforceable independently, and the validity of one provision shall not affect the validity of other provisions.
        41.	Except for qualified participants and the organizer, no person shall have any rights under the Contracts (Rights of Third Parties) Ordinance of Hong Kong to enforce or enjoy any benefit or right under any provision of these terms and conditions.
        42.	The organizer reserves the right to change the event content, terminate the event, and/or revise these terms and conditions at any time without prior notice. Any temporary changes or cancellations related to the event shall be based on the organizer's announcement.
        43.	There is no appeals mechanism for the event. In the event of any dispute, the organizer reserves the right of final decision. Any dispute shall be based on the system records of the organizer as evidence.
        44.	s/ash ("s/ash") is the telecommunications service brand of the organizer (Unified Carrier License Number: 002).
        `,
        isAnnex: true,
        size: '489KB',
        link: '/pdf/slasher大使活動條款及細則.pdf'
      },
      {
        id: 8,
        title: 's/ash Fee Free for all - Terms and Conditions(The activity has ended)',
        content: `
          Free 5-month offer for designated customers ("Free 5-month Offer") is only applicable to new customers ("Eligible Customers") who have subscribed s/ash service plan on 12-month contract. "Free 5-month Offer" is only applicable to customers who are 18-29 years old during service application. Eligible customers will receive a waiver of the monthly fee for 12 months during the service commitment period. The waived monthly fee will be reflected on the 2nd, 4th, 6th, 8th and 10th bill. If the customer changes to another service plan or terminates the service plan early during the contract period, the offer will be automatically cancelled and the total amount of the offer enjoyed by the customer will be charged without prior notice. This offer is valid until further notice. Terms and Conditions apply.
          Free 3-month Offer (Applicable to 30 year-old or above customers)
          Free 3-month offer for designated customers ("Free 3-month Offer") is only applicable to new customers ("Eligible Customers") who have subscribed s/ash service plan on 12-month contract. Eligible customers will receive a waiver of the monthly fee for 12 months during the service commitment period. The waived monthly fee will be reflected on the 4th, 6th, 10th bill. If the customer changes to another service plan or terminates the service plan early during the contract period, the offer will be automatically cancelled and the total amount of the offer enjoyed by the customer will be charged without prior notice. This offer is valid until further notice. Terms and Conditions apply.
        `,
        isAnnex: true,
        size: '508KB',
        link: '/pdf/全民免月費 活動細則.pdf'
      }
      // {
      //   id: 8,
      //   title: 's/ash x JOOX Special Offer Terms and Conditions',
      //   content: `
      //   1. The Promotion is valid from now until further notice (the "Promotional Period").
      //   2. During the promotional period, new customers who subscribe to designated s/ash S/M/L service plan and commit to a minimum service period of 12 months (“Eligible Customers”) can enjoy monthly fee waiver for the “JOOX Music VIP” value-added service. New customers who subscribe to designated s/ash S service plan and commit to a service period of 12 months can enjoy 5 months of free “JOOX Music VIP” value-added service during the contract period; new customers who subscribe to designated s/ash M service plan and commit to a service period of 12 months can enjoy 8 months of free “JOOX Music VIP” value-added service during the contract period; new customers who subscribe to designated s/ash L service plan and commit to a service period of 12 months can enjoy 10 months of free “JOOX Music VIP” value-added service during the contract period. The “JOOX Music VIP” service will start to be given from the first bill, and it will be automatically cancelled when the validity period ends. Terms and conditions apply. For more details, please visit: <a href="https://bit.ly/3LgCaNy">https://bit.ly/3LgCaNy</a>。
      //   3. During the promotional period, new customers ("Eligible Customers") who subscribe to the designated s/ash S/M/L service plan and commit to a minimum service period of 12 months can enjoy monthly fee waivers for the "Video Connecting Tone" value-added service. The free "Video Connecting Tone" service will start to be given from the first bill, and is valid for 12 months. It will be automatically cancelled after the validity period ends. Users acknowledge and agree that the Video Connecting Tone Service is also subject to the terms and conditions of the China Mobile Hong Kong Video Connecting Tone Business Service Agreement, Automatic Renewal Service Agreement, and China Mobile Video Color Ring Business Service Agreement. For more details, please visit: <a href="https://bit.ly/3WhqVKU">https://bit.ly/3WhqVKU</a>,<a href="https://bit.ly/3XXyBTT">https://bit.ly/3XXyBTT</a> and <a href="https://bit.ly/4cUr8ZY">https://bit.ly/4cUr8ZY</a>
      //   4.  Port-in customers shall successfully port-in to CMHK within 180 days upon signing the contract. If customer fail to port-in number, changes to another service plan or terminates the service plan early during the contract period, the offer will be automatically cancelled and CMHK reserves the right to charge the total amount of the offer enjoyed by the customer without prior notice.
      //   • s/ash is the telecommunications service brand of China Mobile Hong Kong Limited ("CMHK") (license number: 002).
      //   • s/ash reserves the right to modify or terminate the above offers or content, amend these terms and conditions at any time without prior notice.
      //   • In case of any dispute, s/ash reserves the right of final decision.
      //   `,
      //   isAnnex: true,
      //   size: '233KB',
      //   link: '/pdf/slash x JOOX 推廣計劃之條款及細則.pdf'
      // }
    ]
  },
  sc_jp: {
    seoH1: '5G monthly plan offer exclusive for Standard Chartered credit cardholders',
    seoH2:
      '"Here comes the exclusive offers for Standard Chartered credit cardholders! Extra 1-month service fee waived + Up to 5% CashBack with Smart Card"',
    seoH3: '12-month short contract/ Free $18/month admin fee',
    渣打信用卡客户专享: 'Exclusive for Standard \nChartered credit cardholders',
    额外免1个月月费: 'Extra 1-month service fee waived + \n',
    兼享高达: 'Up to 5%',
    '5%现金回赠': ' CashBack with Smart Card',
    个月短约: '-month short contract',
    免: 'Free ',
    行政费: '/month admin fee',
    渣打信用卡客户有福啦: 'Here comes the exclusive offers for Standard Chartered credit cardholders!',
    '输入优惠码、并用渣打信用卡缴纳月费和设立自动转账，以下优惠即到手':
      'Enter the promo code and pay your monthly fee with a Standard Chartered credit card! Seize the following benefits right away!',
    复制: 'Copy',
    优惠: 'Offer ',
    渣打信用卡客户上台专享额外免1个月月费: 'Extra 1-month service fee waived',
    '「Smart卡5%丰厚现金回赠」': 'Up to 5% CashBack with Smart Card',
    '有关渣打Smart卡详情，请': 'For details regarding Standand Chartered Smart Card, ',
    按此: 'please click here',
    活动介绍详情:
      'Paying your s/ash monthly fee with a Standard Chartered Smart Card entitles you to receive up to 5% CashBack! No registration required, no minimum spending, and no specific date. Enjoy generous CashBack at selected everyday merchants!',
    即刻扫码登记: 'Scan to Register',
    条款及细则: 'Terms and Conditions',
    渣打银行卡推广计划之条款及细则: 's/ash x Standard Chartered Bank Special Offer Terms and Conditions',
    '借定唔借？还得到先好借！': 'To borrow or not to borrow? Borrow only if you can repay!',
    立即开台: 'Register NOW with Promo Code',
    复制成功: 'Copy successfully',
    复制失败: 'Copy failed',
    套餐: imgs['sc_jp/plan_en.png'],
    code: imgs['sc_jp/code_en.png']
  },
  ambassador_recruitment: {
    seoH1: 's/asher ambassador Recruitment is Now Open!',
    seoH3:
      'Iinviting friends to register earns you up to $500 worth of points each time/ exclusive gifts / first-hand information on internal promotions.',
    item1: imgs['ambassador_recruitment/en/item1.png'],
    item2_1: imgs['ambassador_recruitment/en/item2_1.png'],
    item2_2: imgs['ambassador_recruitment/en/item2_2.png'],
    item2_3: imgs['ambassador_recruitment/en/item2_3.png'],
    item2_4: imgs['ambassador_recruitment/en/item2_4.png'],
    item3: imgs['ambassador_recruitment/en/item3.png'],
    item1_phone: imgs['ambassador_recruitment/en/item1_phone.png'],
    item2_1_phone: imgs['ambassador_recruitment/en/item2_1_phone.png'],
    item2_2_phone: imgs['ambassador_recruitment/en/item2_2_phone.png'],
    item2_3_phone: imgs['ambassador_recruitment/en/item2_3_phone.png'],
    item2_4_phone: imgs['ambassador_recruitment/en/item2_4_phone.png'],
    item3_phone: imgs['ambassador_recruitment/en/item3_phone.png'],
    主办方有针对福利发放的最终决定权: '*The organizer has final discretion over benefit distribution',
    '立即成为s/asher大使': 'Become a s/asher ambassador NOW.',
    '赚积分* 享福利 换礼品': 'Complete missions to earn points, and enjoy exclusive gifts and offers.',
    立即报名: 'Sign Up Now',
    什麼是slasher大使:
      'What is a s/asher ambassador? S/asher ambassador  is a KOL activitiy for young generation and college students, offering s series of exclusive benefits and exciting events to support young generation in Hong Kong.',
    邀请朋友上台:
      'Invite friends to register with s/ash or port from SmarTone、csl、3HK and other telecommunication companies enjoy MyLink Points. MyLink Points can be redeemed for flight tickets offers, supermarket coupons, electronic products, beauty products, and more.',
    slasher大使:
      's/asher ambassador receives corresponding MyLink points. Referee enjoys one month service fee wavier. Both s/asher ambassador and referee can use MyLink to redeem MyLink points for mobile bill payments, flight ticket offers, supermarket coupons, electronic products, beauty products, and more.',
    就算你是:
      's/asher ambassador is open for application even if you are customers from SmarTone, csl, Birdie, CMHK, 3HK, HKBN, 1010, China Unicom, and other telecommunication companies.',
    如果你报名成功:
      'If your application is successful, we will send you an exclusive referral code via email or WhatsApp within five working days.',
    姓名: 'Name',
    出生年月: 'Date of Birth',
    联络电话: 'Contact Number',
    邮箱: 'E-mail',
    在哪里认识我们: 'Where did you know us?',
    '同意/不同意接受我们的资讯': `I agree that s/ash can send promotional messages about s/ash to me through email, SMS, or other instant messaging applications. I am aware and consent to the content of China Mobile Hong Kong's <a target="_blank" href="https://www.hk.chinamobile.com/sc/pics.html">general personal information collection statement</a> and <a target="_blank" href="https://www.hk.chinamobile.com/sc/privacy.html">privacy policy statement</a> regarding the personal information provided above, and accept their binding terms.`,
    搜索引擎: 'Search engine',
    社交媒体: 'Social media',
    网络广告: 'Online advertisment',
    亲友推荐: 'Referral by friends or family',
    其他: 'others',
    请输入姓名: 'Please enter your name.',
    姓名请输入正确格式: 'Please enter the correct format; special characters are not allowed. You can input up to 20 text characters.',
    '如2000/01': 'For example, 2000/01.',
    请选择出生年月: 'Please select your date of birth.',
    请输入正确格式: 'Please enter the correct format.',
    请输入正确手机号码: 'Please enter a valid phone number.',
    请输入正确电子邮箱: "Please enter a valid email address, such as username{'@'}domain.",
    如亲友推荐: 'For example, referral by friends or family.',
    请选择推荐渠道: 'Please select the source of information.',
    请勾选所需资料: 'Please check the required information.',
    提交: 'Submit',
    未开始: 'Activity has not started',
    已结束: 'Activity has ended',
    提交成功: 'Thank you for your submission. We will contact you within 7 working days via email or WhatsApp to follow up with you.',
    提交失败: 'Form submited failed,please try again later',
    条款及细则: 'Terms and Conditions',
    活动条款内容: `
    For the terms and conditions of slasher ambassador please refer to <a target="_blank" href='/pdf/slasher大使活動條款及細則.pdf'>slasher ambassador Terms and Condition</a>  
    `
  },
  business_collaboration: {
    商业合作: 'Business Collaboration',
    填写简单表单:
      'We welcome any form of cooperation,\nto provide more services and benefits to young people. \nPlease fill in the following form to contact us, \nand we will get back to you as soon as possible.and we will get back to you as soon as possible.',
    slash介绍:
      's/ash is a 5G communication lifestyle brand that supports young people. If you are interested in partnering with us to organize joint events, launch exclusive offers, and provide special privileges, ',
    '欢迎即刻通过下列方式联络我们！': 'please feel free to contact us through the following ways!',
    成为我们的合作伙伴: 'Become our Partner',
    联络人姓名: `Contact Person's Name`,
    电邮: 'Business Email',
    电话: 'Phone Number',
    '公司/机构名称': 'Company/Organization Name',
    讯息: 'Message',
    提交申请: 'submit',
    或者透过email联系我们: 'contact us via email',
    点击联系: 'Click here to email us',
    请输入联络人姓名: 'Please enter your name.',
    请输入邮箱地址: 'Please enter your business Email.',
    请输入正确电子邮箱: `Please enter a valid email address, such as username{'@'}domain.`,
    请输入联络电话: 'Please enter your phone number.',
    请输入正确手机号码: 'Please enter a valid phone number.',
    请输入公司名称: 'Please enter the company/organization name.',
    请输入讯息: 'Please enter your message for us.',
    请输入正确的格式: 'Please enter the correct format;',
    最多可输入100个字符: 'You can input up to 100 text characters.',
    最多可输入50个字符: 'You can input up to 50 text characters.',
    提交成功: 'Submission successful, we will get back to you as soon as possible.',
    提交失败: 'Form submited failed,please try again later'
  },
  joox_jp: {
    alt: 'The new 5G telecommunications lifestyle brand s/ash, in collaboration with JOOX, is launching a special offer for music lovers. New subscribers to s/ash 5G service plan can enjoy up to 10 months of JOOX Music VIP service and 12 months of free Video Connecting Tone service.',
    h2Seo:
      'Register to enjoy up to 10 months of free JOOX VIP (value: $68/month) + 12 months of free Video Connecting Tone service (value: $15/month)',
    h3Seo: '12-month short contract/ Free $18/month admin fee',
    banner_mobile: imgs['joox_jp/en/banner_mobile.png'],
    song: imgs['joox_jp/en/song.png'],
    table_mobile: imgs['joox_jp/en/table-mobile.png'],
    table_pc: imgs['joox_jp/en/table-pc.png'],
    title02: imgs['joox_jp/en/title02.png'],
    's/ash X JOOX 乐迷专享优惠': 's/ash x JOOX special offer \nfor music fans',
    上台即送: 'Register to enjoy:',
    长达10个月: 'Up to 10 months',
    'JOOX VIP': ' of free JOOX VIP \n(value: $68/month)',
    '12个月免费视频接驳铃声服务': '+ 12 months of free Video Connecting Tone service \n(value: $15/month)',
    个月短合约: '-month short contract',
    免: ' Free ',
    行政费: '/month admin fee',
    '透过本页面登记s/ash服务计划，': 'Register for the s/ash service plan through this page to ',
    '透过本页面登记s/ash服务计划后': 'exclusive offers for JOOX music fans',
    JOOX乐迷专属丰厚优惠: '',
    配合极速5G网络: 'Enjoy an unrestricted ultimate music experience anytime, anywhere with ultra-fast 5G network',
    送最多10个月: 'Up to 10 months of ',
    权益: '!',
    '9,000万首歌库': '90 million song library',
    超过九千万歌库新歌经典一应俱全: 'Over 90 million songs, including both new releases and classics, updated daily',
    离线听歌: 'Offline listening',
    随意下载并离线收听珍藏喜欢的歌曲: 'Download and listen to your favorite songs offline at your leisure, for enjoyment anytime',
    'Hi-Fi无损音质': 'Hi-Fi lossless audio quality',
    '畅享Hi-Fi无损音质歌曲，纯净通透': 'Enjoy Hi-Fi lossless quality songs, pure and clear',
    听歌无阻: 'Uninterrupted music experience',
    随心点播自由切歌: 'Play on demand, freely switch songs, ad-free for an undisturbed music experience',
    轻松激活: 'Activate ',
    权益四步曲: ' privileges in four simple steps',
    JOOX音乐VIP服务生效后:
      'After JOOX Music VIP service is confirmed, customers will receive a confirmation SMS. Customers need to copy the redemption code from the message to activate the service ✉️',
    下载并开启: 'Download and launch the JOOX mobile application 📱',
    '注册/登入JOOX账户': 'Register/Login to your JOOX account 🎶',
    进入应用程式后:
      'After opening the app, go to Account > VIP Center > Redemption Code > Enter Redemption Code > Redeem Now. Once confirmed, your account will be able to enjoy JOOX VIP benefit 🎉',
    送12个月免费视频接驳铃声服务: 'Free 12 months of free Video Connecting Tone service',
    用户开通视频接驳铃声服务后:
      'After activating and setting up your video connecting tones, you can show callers your personalized and interesting short videos when they are waiting for you to answer the call. No app required. No data consumption. To know more about the service, 👉',
    请按此: 'please click.',
    条款及细则: 'Terms and Conditions',
    's/ash x JOOX 推广计划之条款及细则': '"s/ash x JOOX Special Offer Terms and Conditions"',
    即扫登记: 'Scan the QR Code to register NOW',
    立即开台: 'Register NOW'
  },
  error: {
    呢個頁面目前有啲問題: '///Oh dear, \nit seems that there are some issues with this page...',
    '你可以：': 'You can:',
    返首页: 'Go back to the homepage',
    認識下我地: 'Learn more about us',
    Whatsapp我哋銷售專員: 'WhatsApp our sales representative'
  }
}
export default en
